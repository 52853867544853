import Navigation from '../utils/Navigation';
import findAncestor from '../utils/findAncestor';
import glWipe from './webgl/GLWipe';
import app from '../global';
import sfx from '../utils/sfx';

const navigation = Navigation.getInstance();
const wipe = glWipe.getInstance();

function Menu() {
  this.verbose = false;
}

function init() {
  console.log(`Main Menu | ${this.menuID} | init`);

  const wrapper = document.getElementById('mainHeader');
  this.elements = {
    wrapper,
    close_button: wrapper.querySelector('.close_button')
  };

  wrapper.addEventListener('click', (e) => {
    if ((e.target.tagName === 'A' && e.target.getAttribute('target') === '_blank') || findAncestor(e.target, 'address')) return;

    e.preventDefault();

    /**
     * Logo was clicked
     */
    if (e.target.tagName === 'H1') {
      console.log('CLICKED THE LOGO');
      navigation.changeSection('/');

      if (this.elements.wrapper.classList.contains('open')) {
        close.call(this);
      }

      return;
    }

    /**
     * Open button was clicked
     */
    const open_button = findAncestor(e.target, '.open_button');
    if (open_button) {
      open.call(this);
      sfx.playFx('sx_burger_click');

      return;
    }

    /**
     * Close button was clicked
     *
     * if the close button was clicked because the section is
     * a "modal", close the modal, otherwise, close the menu
     */
    const close_button = findAncestor(e.target, '.close_button');
    if (close_button) {
      if (document.body.dataset.modal && document.body.dataset.modal !== 'false') {
        this.elements.wrapper.classList.remove('open', 'close');
        navigation.changeSection('/');
        sfx.playFx('sx_X_Wipe_Latest');
      } else if (document.body.classList.contains('modal')) {
        document.body.classList.remove('modal');
        this.elements.wrapper.classList.remove('open', 'close');
        navigation.changeSection(`/${document.body.dataset.section}/`);
        // sfx.playFx('sx_X_Wipe_Latest');
      } else {
        close.call(this);

        app.sections[navigation.currentSection].show(null, true);

        wipe.hide('up').then(() => {
          console.log('MENU SHOWN');
          if (document.body.dataset.section === 'home') {
            this.showPlayReel();
          }
        });
      }

      return;
    }

    /**
     * If a link was clicked, change the section
     *
     * If a link was not clicked, call wipe.hide() to hide the menu background
     */
    const url = e.target.tagName === 'A' ? e.target.getAttribute('href') : false;

    if (url) {
      navigation.changeSection(url);
    } else {
      app.sections[navigation.currentSection].show(null, true);

      wipe.hide('up').then(() => {
        console.log('Wipe is hidden');
      });
    }

    /*
     * Hide menu text
     */
    if (this.elements.wrapper.classList.contains('open')) {
      close.call(this);
    }
  });
}

function selectMenuItem(section_name) {
  console.log('section_name', section_name);
  const item = document.body.querySelector(`[href^="${window.location.pathname}"]`);
  const selecteds = document.querySelectorAll('.selected');
  for (let i = 0; i < selecteds.length; i++) {
    const element = selecteds[i];
    element.classList.remove('selected');
  }
  if (item) item.classList.add('selected');
  console.log(item, `Main Menu | ${this.menuID} | selectMenuItem: ${section_name}`);
}

function resize() {
  console.log('resize menu');
}

function open() {
  document.body.classList.add('menuOpen');
  this.elements.close_button.classList.remove('show');
  this.elements.wrapper.classList.remove('close', 'open');
  window.requestAnimationFrame(() => {
    this.elements.wrapper.classList.add('open');
  });

  this.hideLogo();

  const footer = document.querySelector('#footer');
  footer.classList.remove('show');

  this.hidePlayReel();

  wipe.show('down').then(() => {
    console.log('MENU SHOWN');
    this.elements.close_button.classList.add('show');
    this.showLogo(app.colors.white);
  });

  app.sections[navigation.currentSection].hide(null, -1);
}

function close() {
  document.body.classList.remove('menuOpen');
  this.elements.wrapper.classList.add('close');
  this.elements.close_button.classList.remove('show');
  this.hideLogo();
}

function show() {
  if (this.verbose) console.log(`Main Menu | ${this.menuID} | show`);
  this.elements.wrapper.classList.add('show');
}

function hide() {
  if (this.verbose) console.log(`Main Menu | ${this.menuID} | hide`);
  this.elements.wrapper.classList.remove('show');
  this.hideLogo();
}

function showPlayReel() {
  const header = document.querySelector('#mainHeader');
  header.classList.add('showPlayReel');
}

function hidePlayReel() {
  const header = document.querySelector('#mainHeader');
  header.classList.remove('showPlayReel');
}

function showLogo(color = false) {
  const header_color = color || this.currentLogoColor || app.colors.white;
  this.currentLogoColor = header_color;

  const root = document.documentElement;
  root.style.setProperty('--header_color', header_color);

  const header = document.querySelector('#mainHeader');
  header.classList.add('showLogo');
  header.classList.add('showBurger');
}

function hideLogo() {
  const header = document.querySelector('#mainHeader');
  header.classList.remove('showLogo');
}

Menu.prototype.init = init;
Menu.prototype.hide = hide;
Menu.prototype.show = show;
Menu.prototype.open = open;
Menu.prototype.close = close;
Menu.prototype.resize = resize;
Menu.prototype.selectMenuItem = selectMenuItem;
Menu.prototype.showLogo = showLogo;
Menu.prototype.hideLogo = hideLogo;
Menu.prototype.showPlayReel = showPlayReel;
Menu.prototype.hidePlayReel = hidePlayReel;

export default {
  getNew: (data) => new Menu(data)
};
