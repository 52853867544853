varying vec3 vPosition;

void main() {
	vPosition = vec3( position );

	vec3 transformed = vec3( position );
	vec4 mvPosition = vec4( transformed, 1.0 );
	mvPosition = modelViewMatrix * mvPosition;
	gl_Position = projectionMatrix * mvPosition;
}
