/* eslint-disable */
import {
  Scene,
  LoadingManager,
  MeshBasicMaterial,
  Mesh,
  PlaneGeometry,
  PerspectiveCamera,
  Color,
  DirectionalLight,
  OrthographicCamera,
  Vector3,
  ShaderMaterial,
  WebGLRenderTarget,
  Vector2,
  SphereGeometry,
  MeshPhongMaterial,
  TextureLoader,
  Raycaster,
  MeshMatcapMaterial,
  PointLight,
  MeshStandardMaterial,
  RectAreaLight,
  UniformsUtils,
  ShaderLib,
  ShaderChunk,
  Matrix3,
  Group,
  Object3D
} from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import DynamicMatCap from './DynamicMatCap';
import GLMountainMatCap from './GLMountainMatCap';

import {
  DoubleSide
} from 'three/src/constants';

import { gsap } from 'gsap/all';

import getNamedObjects from '../../utils/getNamedObjects';

import Navigation from '../../utils/Navigation';
import GLScene from './GLScene';
import GLCamera from './GLCamera';
import pubsub from '../../utils/pubsub';
import app from '../../global';

import { plainText as vertHeightShader } from './shaders/mountainHeight-vertex.glsl';
import { plainText as fragHeightShader } from './shaders/mountainHeight-fragment.glsl';
import { plainText as meshMatCapVertexShader } from './shaders/meshmatcap_vert.glsl';
import { plainText as meshMatCapFragmentShader } from './shaders/meshmatcap_frag.glsl';
import { plainText as skyVert }  from './shaders/sky-vert.glsl';
import { plainText as skyFrag } from './shaders/sky-frag.glsl';

import GLMountainPaths from './GLMountainPaths';
import { Quaternion } from 'three';
import { Plane } from 'three';
import { DefaultLoadingManager } from 'three';

const navigation = Navigation.getInstance();

let SCREEN_WIDTH = window.innerWidth;
let SCREEN_HEIGHT = window.innerHeight;

let instance;
let mountainXPos = 0;
let mountainChunks;
let currState = 0;
let currPlane = -1;
let XDistanceFromProjectPlane = 0.75;

const planeLocations = [];

let pathDestinationZ = 0;

const motion = {
  position: new Vector3(),
  focalPtMode: 0, //0 for Terrain Space, 1 for World Space, 2 for Camera Space
  focalPt: new Vector3(100, 1.5, 0),
  blurAmt: 0,
  blurOrigin: new Vector3(),
  blurOffset: 0,
  blurOriginOffset: new Vector3(),
  destination: new Vector3(),
  direction: new Vector3(),
  arrived:false,
  accel:0,
  speed:0,
  maxBrake: 0.00004,
  maxAccel: 0.000025,
  basic: false,
  velocity: new Vector3(),
  acceleration: new Vector3(),
  desiredVelocity: new Vector3(),
  seekSteering: new Vector3(),
  frameMovement: new Vector3(),
  coastSpeed:0,
  dragVel:0,
  dragVelDelta:0,
  pathVel:0
}

function lerp(valA, valB, mix){
  return valA + (valB-valA)*mix;
}

function limitVector3(vec, mag){
  var magOut = Math.min(mag, vec.length());
  vec = vec.normalize().multiplyScalar(magOut);
}

// replicate the p5js funciton for ease of following Shiffman
function map(val, inA, inB, outA, outB){
  return lerp(outA, outB, Math.max(0, Math.min(1, (val-inA)/(inB-inA)))); 
}

// RAYCASTER LOADER 
const mouse = {
  raycaster: new Raycaster(),
  dragPlane: new Plane(new Vector3(0, 1, 0)),
  rayIntersect: new Vector3(),
  screenPos: new Vector2(),
  currDragX: 0,
  dragging: false,
  dragApplied: false
}
mouse.dragPlane.translate(new Vector3(0, 1.5, 0));
// const raycaster = new Raycaster();
// const dragPlane = new Plane();
// dragPlane.setFromNormalAndCoplanarPoint(new Vector3(0, 1, 0), new Vector3(0, 0, 0))
// const rayIntersect = new Vector3();
// const mousePos = new Vector2();

class GLMountain {
  constructor() {
    this.initialized = false;
    this.scene = new Scene();
    // this.scene.background = new Color(0x000000);
    this.events = pubsub.getInstance();
    this.active = false;
    this.heightTargetSize = new Vector2(512, 128);

    this.maskY = 0.5;

    this.motion = motion;
  }
  init(callback) {
    this.transitioning = true;
    const loadManager = new LoadingManager();
    const glScene = GLScene.getInstance();
    
    this.glCamera = GLCamera.getInstance();
    this.glCamera.destPosition.set(0, 2.5, 0);
    this.glCamera.focalPt.set(100, 1.5, 0);
    this.scene.add(this.glCamera.camera);

    
    motion.destination.set(0,2.5,0);
    motion.position.set(0,2.5,0);
    motion.focalPt.set(35,1.5,0);

    // this.glCamera.camera.add(this.backgroundMesh);

    this.rightLight = new DirectionalLight(0xFFFFFF, .2);
    this.rightLight.position.set(0, 5, 5);
    this.scene.add(this.rightLight);

    this.matcapTex = new TextureLoader(loadManager).load( `${app.site_path}assets/models/matcap.jpg`, tex => {tex.flipY = true} );
    this.UIPosition = new Group();
    
    this.UIPosition.rotation.y = -0.5 * Math.PI;
    this.UIPosition.position.y = -0.145;
    this.UIPosition.position.x = -0.175;
    this.UIPosition.scale.set(0.00075, 0.00075, 0.00075);

    this.mountainMatCapA = new GLMountainMatCap();
    this.mountainMatCapA.update();
    this.mountainMatCapB = new GLMountainMatCap();
    this.mountainMatCapB.update();

    this.maskOrigin = new Vector2(1.1, 0.);
    this.maskVec = new Vector2(1, -1);
    this.resolution = new Vector2(SCREEN_WIDTH, SCREEN_HEIGHT);

    this.backgroundMesh = new Mesh(new SphereGeometry(40, 16, 16), new ShaderMaterial({
      uniforms:{
        azimuthA: {value:this.mountainMatCapA.azimuthColor},
        zenithA: {value:this.mountainMatCapA.zenithColor},
        azimuthB: {value:this.mountainMatCapB.azimuthColor},
        zenithB: {value:this.mountainMatCapB.zenithColor},
        maskOrigin: {value:this.maskOrigin},
        maskVec: {value:this.maskVec},
        resolution: {value:this.resolution}
      },
      vertexShader: skyVert,
      fragmentShader: skyFrag, 
      side: DoubleSide
    }));
    this.backgroundMesh.name = "backgroundMesh";
    this.scene.add(this.backgroundMesh);

    this.customMountainMat = new ShaderMaterial({
      uniforms: UniformsUtils.merge([
          ShaderLib.phong.uniforms,
          {
            mapA: {},
            mapB: {},
            azimuthA: {},
            azimuthB: {},
            maskOrigin: {},
            maskVec: {},
            resolution: {},
            heightMap: {},
            mountainPosition: {value:0}
          }
        ]),
        vertexShader: meshMatCapVertexShader,
        fragmentShader: meshMatCapFragmentShader,
        side: DoubleSide
    });
    // this.customMountainMat.uniforms.map.value = this.matcapTex;
    this.customMountainMat.uniforms.mapA.value = this.mountainMatCapA.tex.texture;
    this.customMountainMat.uniforms.mapB.value = this.mountainMatCapB.tex.texture;
    this.customMountainMat.uniforms.azimuthA.value = this.mountainMatCapA.azimuthColor;
    this.customMountainMat.uniforms.azimuthB.value = this.mountainMatCapB.azimuthColor;
    this.customMountainMat.uniforms.maskOrigin.value = this.maskOrigin;
    this.customMountainMat.uniforms.maskVec.value = this.maskVec;
    this.customMountainMat.uniforms.resolution.value = this.resolution;

    this.customMountainMat.lights = true;
    this.customMountainMat.map = this.matcapTex;

    // this.customMountainMat.onBeforeRender = (shader)=>{
    //   console.log(shader);
    // }
    new GLTFLoader(loadManager).load(
      `${app.site_path}assets/models/mountain_v2.glb`, 
      (gltf) => {
        console.log('GLB LOADED!', gltf);
        // this.swapMaterials.call(this, gltf.scene);
        this.objects = getNamedObjects(gltf.scene, {}, true);


        mountainChunks = [];    
        for(let i=0; i<8; i++){
          mountainChunks[i] = this.objects["Mountain"+i];
          mountainChunks[i].material = this.customMountainMat;

          var leftMesh = new Mesh(mountainChunks[i].geometry, mountainChunks[i].material);
          mountainChunks[i].add(leftMesh);
          leftMesh.position.z -= 8;
          leftMesh.rotation.x = Math.PI/20;
          var rightMesh = new Mesh(mountainChunks[i].geometry, mountainChunks[i].material);
          mountainChunks[i].add(rightMesh);
          rightMesh.position.z += 8;
          leftMesh.scale.y = rightMesh.scale.y = 1.5;
          rightMesh.rotation.x = -Math.PI/20;
        }
        
        for(let i=0; i<6; i++){
          planeLocations[i] = this.objects["project"+i].position.clone().add(this.objects["project"+i].parent.position);
          this.objects["project"+i].parent.remove(this.objects["project"+i]);
          // this.objects["project"+i].visible = false;
        }

        gltf.scene.name = "mountain scene";
        this.scene.add(gltf.scene);
        
        
      }
    );
    
    
    var ringMatCapSettings = {
      pointLights: [
        {
          color: 0xf5b331,
          intensity: 0.85,
          decay: 0,
          decayDistance: 0,
          distance: 7,
          lat: Math.PI,
          long: 0.2
        },
      ],
      metalness: 1,
      roughness:0.5,
      innerColor: 0x333333,
      outerColor: 0x1c1c1c,
      azimuthColor:0x333333,
      zenithColor: 0x1c1c1c,
      overlayAmt:1
    }
    this.ringsMatCap = new DynamicMatCap();
    this.ringsMatCap.transition(ringMatCapSettings, 0);
    this.ringsMatCap.update();
    this.ringsMat = new MeshMatcapMaterial({matcap:this.ringsMatCap.tex.texture, depthTest:false})
    
    new GLTFLoader(loadManager).load(
      `${app.site_path}assets/models/logo.glb`, 
      (gltf) => {
        const logoParts = getNamedObjects(gltf.scene, {}, true);
        console.log(logoParts);
        this.logo = new Group();
        this.logo.name = "logo";
        this.logo.position.x = 5;
        this.logo.position.y = 2.5;
        this.logo.rotation.y = Math.PI/2;
        this.logo.visible = false;
        this.logo.scale.setScalar(0.5);

        this.scene.add(this.logo);
        this.logoRings = [];
        for(let i=0; i<8; i++){
          const ring = logoParts["logo_"+(i+1)];
          this.logoRings.push(ring);
          this.logo.add(ring);
          ring.material = this.ringsMat;
        }
      }
    );

    loadManager.onLoad = () => {
      this.initialized = true;
      this.events.publish('initialized');
      this.mountainMatCapA.lightsOut(0);
      this.mountainMatCapB.dayTime(0);
      // this.mountainMatCapA.nightTime(0);
    
      const heightCam = new OrthographicCamera(-20, 20, 5, -5, 1, 200);
      heightCam.position.set(20, 100, 0);
      this.scene.add(heightCam);
      heightCam.lookAt(new Vector3(20, 0, 0));
      this.scene.remove(heightCam);
      
      const heightMat = new ShaderMaterial({
        vertexShader: vertHeightShader,
        fragmentShader: fragHeightShader
      })
    
      this.heightTarget = new WebGLRenderTarget(this.heightTargetSize.x, this.heightTargetSize.y);

      this.scene.overrideMaterial = heightMat;
        
      // mountainChunks[7].position.x = 0;
      mountainChunks[7].position.x = 35;
      this.backgroundMesh.visible = false;
      glScene.renderer.setRenderTarget(this.heightTarget);
      glScene.renderer.render(this.scene, heightCam);

      this.backgroundMesh.visible = true;
      glScene.renderer.setRenderTarget(null);
      glScene.renderer.render(this.scene, heightCam);
      // mountainChunks[7].position.x = 35;

      this.scene.overrideMaterial = null;



      // this.projectsPath = new GLMountainPaths("#project_billboards", "#f5b331", this.heightTarget.texture);// f5b331
      // this.capabilitiesPath = new GLMountainPaths("#capabilities_billboards", "#f5b331", this.heightTarget.texture);
      this.customMountainMat.uniforms.heightMap.value = this.heightTarget.texture;

      this.currPath = null;
      // this.currPath = this.capabilitiesPath;
      // this.scene.add(this.capabilitiesPath.group);
      // this.scene.add(this.projectsPath.group);
      this.updatePaths();

      if (callback) callback(); 
    };

    
    if (app.debug) {
    }
  }

  updatePaths() {
    if (this.capabilitiesPath && this.capabilitiesPath.group) {
      this.scene.remove(this.capabilitiesPath.group);
      this.scene.remove(this.projectsPath.group);
    }
    this.projectsPath = new GLMountainPaths("#project_billboards", "#f5b331", this.heightTarget.texture);// f5b331
    this.capabilitiesPath = new GLMountainPaths("#capabilities_billboards", "#f5b331", this.heightTarget.texture);
    this.scene.add(this.capabilitiesPath.group);
    this.scene.add(this.projectsPath.group);
  }

  intro(){
    return new Promise((resolve) => {
      this.setState(0);
      motion.destination.set(0,2.5,0);
      motion.position.set(0,2.5,0);
      motion.focalPt.set(35,1.5,0);
      motion.velocity.set(0,0,0);
      this.mountainMatCapA.lightsOut(0);
      this.maskOrigin.set(1.1, 0.);
      this.transitioning = true;

      mouse.currDragX = 0;
      mouse.dragging = false;
      mouse.dragApplied = false;
      

      this.logo.visible = true;
      const tl = gsap.timeline({onComplete:()=>{
        this.logo.visible = false;
      }});
      const startDelay = 0.5;
      const inOffset = 0.075;
      const holdDelay = 0.5;
      const outOffset = 0.1;
      for(let i=0; i<8; i++){
        this.logoRings[i].position.z = 0;
        this.logoRings[i].position.y = -15;
        this.logoRings[i].scale.setScalar(0.001);
        const pos = (8-i);
        this.logoRings[i].rotation.set((1-Math.random()*2)*Math.PI*2, (1-Math.random()*2)*Math.PI*2, (1-Math.random()*2)*Math.PI*2);
        tl.to(this.logoRings[i].rotation, {duration:1.5, ease:'power4.out', x:Math.PI/2, y:0, z:Math.PI, delay:startDelay+(inOffset*pos)}, 0);
        tl.to(this.logoRings[i].scale, {duration:1, ease:'power4.out', x:1, y:1, z:1, delay:startDelay+(inOffset*pos)}, 0);
        tl.to(this.logoRings[i].position, {duration:1, ease:'power4.out', y:0, delay:startDelay+(inOffset*pos)}, 0);
        tl.to(this.logoRings[i].position, {duration:1, ease:'power4.in', z:-15, delay:startDelay+holdDelay+(inOffset*8)+(outOffset*(8-i))}, 0);
        tl.to(this.logoRings[i].scale, {duration:1, ease:'power4.in', y:30, delay:startDelay+holdDelay+(inOffset*8)+(outOffset*(8-i))}, 0);
      }
      
      tl.call(()=>{
        var ringMatCapSettings = {
          pointLights: [
            {
              color: 0xf5b331,
              intensity: 0.85,
              decay: 0,
              decayDistance: 0,
              distance: 7,
              lat: -Math.PI/2,
              long: 0.2
            },
          ],
          metalness: 1,
          roughness:0.5,
          innerColor: 0x333333,
          outerColor: 0x1c1c1c,
          azimuthColor:0x333333,
          zenithColor: 0x1c1c1c,
          overlayAmt:1
        }
        this.ringsMatCap.transition(ringMatCapSettings, 3);
      }, {}, startDelay+(inOffset*4));

      tl.call(()=>{
        console.log('move dat scene');
        this.setState(0);
        currState = 0;
        this.motion = motion;
        this.mouse = mouse;
        motion.arrived = false;
        motion.destination.set(30,2.5,0);
        // this.focalPointTween({x:35, y:1.5, z:0});
      }, {}, startDelay+holdDelay+(inOffset*8)+0.2);

      tl.call(()=>{
        this.nightTime(2.5);
      }, {}, startDelay+holdDelay+(inOffset*8)+0.8);

      tl.call(()=>{
        // currState = 1;
        this.setState(1);
        motion.coastSpeed = 0;
        resolve();

        this.transitioning = true;
        gsap.to(this.maskOrigin, {x:0.5, y: this.maskY, duration:1, ease:"power4.out", onComplete: () => {
          this.transitioning = false;
        }});
      }, {}, startDelay+holdDelay+(inOffset*8)+0.8+2);

      // tl.to(this.maskOrigin, {x:0.5, y:0.5, duration:0.5, ease:"power4.out"}, startDelay+holdDelay+(inOffset*8)+0.8+2);

    });
  }

  // this moves the terrain and the camera at the same time
  // it's really a wrapper so that we can set the view position
  // without having to worry about the terrain wrap around
  setViewPosition(x, y, z){
    
    while(x < 0)x += 35;
    while(x >= 35)x -= 35;
    let xMove = x-mountainXPos;
    while(xMove < -18)xMove += 35;
    while(xMove >= 18)xMove -= 35;
    
    mountainXPos = x;
    this.glCamera.destPosition.z = z;

    while(mountainXPos < 0){
      mountainXPos += 35;
    }
    while(mountainXPos >= 35){
      mountainXPos -= 35;
      xMove += 35;
    }
    // mountainXPos = mountainXPos % 35;
    
    if(this.currPath && this.currPath.shown) this.currPath.move(xMove, mountainXPos);
    this.customMountainMat.uniforms.mountainPosition.value = mountainXPos;
    
    for(let i=0; i<8; i++){
      var origXPos = (i%7)*5;
      mountainChunks[i].position.x = origXPos-mountainXPos;
      if(mountainChunks[i].position.x < -10) mountainChunks[i].position.x += 35;
    } 
    let heightBuff = new Uint8Array(4);
 
    const glScene = GLScene.getInstance();
    const heightLookAhead = 0.8;
    const heightOffset = 0.25;
    glScene.renderer.readRenderTargetPixels(this.heightTarget, this.heightTargetSize.x*(((heightLookAhead + mountainXPos)% 35)/40), this.heightTargetSize.y*(-z+5)/10, 1, 1, heightBuff);

    const destHeight = Math.max(y, heightOffset+(heightBuff[0]/255)*2.25);
    const heightOut = Math.max((heightBuff[0]/255)*2.25, lerp(destHeight, this.glCamera.destPosition.y, 0.9));
    
    this.glCamera.destPosition.y = heightOut;

  }

  scroll(amt){
    motion.destination.x += amt;// * 10;
  }
 
  nextProject(){
    // this.transitionToPlane((currPlane + 1) % 6);
    motion.destination.x += 10;
  }
 
  prevProject(){
    // this.transitionToPlane((currPlane + 1) % 6);
    motion.destination.x -= 10;
  }

  transitionToPlane(index){
    if(currPlane == index)return;
    currPlane = index;

    motion.arrived = false;
    motion.destination.copy(planeLocations[currPlane]);
    motion.destination.x -= XDistanceFromProjectPlane;
    while(motion.destination.x < motion.position.x) motion.position.x -= 35;

    this.focalPointTween(planeLocations[currPlane]);
  }

  transitionToAir(){
    this.setState(1);
    gsap.to(motion.focalPt, {x: 5, y:0, z:0, duration:1, ease:"power2.inOut"});
    motion.coastSpeed = 0;
    this.currPath.hide();
    this.transitioning = true;
    gsap.to(this.maskOrigin, {x:0.5, y: this.maskY, duration:1, ease:"power3.inOut", onComplete: () => {
      this.transitioning = false;
    }});
  }

  transitionIntoScene(linksType, instant){
  
    return new Promise((resolve) => {
      let destX = 0;
      let destY = 0;
      if(linksType == 1){
        this.currPath = this.capabilitiesPath;
        destX = 1;
        destY = 0;
      } else {
        this.currPath = this.projectsPath;
        destX = 0;
        destY = 1;
      }

      motion.velocity.set(0,0,0);
      mouse.currDragX = 0;
      mouse.dragging = false;
      mouse.dragApplied = false;

      let maskDuration = 1;
      if (instant) {
        motion.destination.set(0,5,pathDestinationZ);
        motion.position.set(0,5,pathDestinationZ);
        motion.focalPt.set(4,2.5,0);
        maskDuration = 0;
      }
      
      this.transitioning = true;

      const tl = gsap.timeline({
        onUpdate: () => {
          // console.log('updating');
        },
        onComplete: () => {
          // resolve();
          // console.log('complete');
        }
      })

      tl.to(motion.destination, {y:5, z:pathDestinationZ, duration:1, ease:"power3.inOut"}, 0);
      motion.destination.x  = motion.position.x;
      // motion.destination.y = 5;
      // motion.destination.z = pathDestinationZ;
      tl.to(motion.focalPt, {x: 4, y:2.5, z:0, duration:1, ease:"power2.inOut"}, 0);
      this.setState(2);
      tl.to(this.maskOrigin, {x:destX, y:destY, duration: maskDuration, ease:"expo.out", onComplete: () => {
        this.transitioning = false;
      }}, 0);
      console.log('THIS IS THE BLUR OFFSET', motion.blurOriginOffset.x, motion.blurOriginOffset.y);
      // tl.to(glScene.postMaterial.uniforms.blurAmt, {value:0, duration:1, onComplete:()=>{
      //   // this.currPath.show();
      //   // this.setState(2);
      //   // motion.destination.copy(motion.position);
      //   // motion.destination.y = 5;
      //   // gsap.to(motion.focalPt, {x: 4, y:2.5, z:0, duration:1});
      //   // resolve();
      // }}, 0);
      tl.to(motion, { blurOffset: 0, duration: 0.25 }, 0);
      tl.to(motion.blurOriginOffset, { x: 0, y: 0, z: 0, duration: 0.25 }, 0);

      tl.call(() => this.currPath.show(), null, 0.5);
      tl.call(() => resolve(), null, '+=0.5');
    });
  }

  lightsOut(duration){
    this.mountainMatCapA.lightsOut(duration);
  }
  nightTime(duration){
    this.mountainMatCapA.nightTime(duration);
  }
  dayTime(duration){
    this.mountainMatCapA.dayTime(duration);
  }

  focalPointTween(_destPt){
    // copy current rotation
    const focalPtStart = new Vector3(motion.focalPt.x, motion.focalPt.y, motion.focalPt.z);
    const focalPtStartAdj = new Vector3(focalPtStart.x-motion.position.x, focalPtStart.y, focalPtStart.z);
    const startQuat = new Quaternion().copy(this.glCamera.camera.quaternion);
    const tempQuat = new Quaternion();
    const forwardVec = new Vector3();
    const diffVec = new Vector3();
    const destPt = new Vector3().copy(_destPt);
    const destPtMod = new Vector3();

    var tweenVar = {progress:0};
    this.tween = gsap.to(tweenVar, {duration:1, progress:1, ease:"power4.inout",
      onUpdate:()=>{
        this.glCamera.camera.position.copy(this.glCamera.destPosition);
        if(tweenVar.progress < 0.2){
          focalPtStartAdj.set(focalPtStart.x-motion.position.x, focalPtStart.y, focalPtStart.z);
          this.glCamera.camera.lookAt(focalPtStartAdj);
          startQuat.copy(this.glCamera.camera.quaternion);
        }
        destPtMod.set(destPt.x-motion.position.x, destPt.y, destPt.z);
        this.glCamera.camera.lookAt(destPtMod);
        tempQuat.slerpQuaternions(startQuat, this.glCamera.camera.quaternion, tweenVar.progress);
        forwardVec.set(0,0,-1).applyQuaternion(tempQuat);
        diffVec.copy(destPt).sub(motion.position);
        forwardVec.multiplyScalar(diffVec.length());
        motion.focalPt.set(motion.position.x, this.glCamera.destPosition.y, this.glCamera.destPosition.z).add(forwardVec);
      },
      onComplete:()=>{
        motion.focalPt.copy(destPt);
      }
    })
  }

  setFocalPointMode(modeID) {
    //0 for Terrain Space, 1 for World Space, 2 for Camera Space
    // exit early if we are already in the correct mode
    if(motion.focalPtMode == modeID)return;
    // based on current we will convert the focalPt
    // to world space if we aren't already
    const worldPt = new Vector3();
    worldPt.copy(motion.focalPt);
    switch(motion.focalPtMode){
      case 0:
        worldPt.x -= motion.position.x;
        break;
      case 2:
        worldPt.add(this.glCamera.camera.position);
        // this.glCamera.camera.localToWorld(worldPt);
        break;
    }

    // set focalPtMode to dest mode
    motion.focalPtMode = modeID;
    // copy world point
    motion.focalPt.copy(worldPt);
    // convert from world space to dest space
    
    switch(motion.focalPtMode){
      case 0:
        motion.focalPt.x += motion.position.x;
        break;
      case 2:
        motion.focalPt.sub(this.glCamera.camera.position);
        // this.glCamera.camera.worldToLocal(worldPt);
        break;
    }
  }

  setState(state) {
    currState = state;
    this.currState = state;
    
    switch(currState){
      case 0: // fly to destination
        this.setFocalPointMode(0);
        break;
      case 1: // coast
        this.setFocalPointMode(2);
        break;
      case 2: // high elevation scroll
        this.setFocalPointMode(1);
        break;
    }
  }

  update(currTime, deltaTime, manualTime) {
    if (this.paused) return;

    if (this.manualUpdate) {
      if (typeof manualTime === 'undefined') {
        return;
      } else {
        deltaTime = manualTime;
      }
    }

    if(this.currPath)this.currPath.update(deltaTime);
    if(currState == 0 || currState == 2){
      // SEEK AND ARRIVE BEHAVIOR

      // lets see if we can get 3d motion
      motion.direction.copy(motion.destination).sub(motion.position);
      var dist = motion.direction.length();
      var speed = motion.velocity.length()
      var frameMovement = speed*deltaTime;
      var minStoppingDistance = (speed*100) + Math.pow(speed,2)/(2*motion.maxBrake);

      motion.acceleration.set(0,0,0);
      if(!dist){
      }else if(dist < minStoppingDistance + frameMovement+ 0.01){
        dist *= 0.85;
        motion.accel = -Math.pow(speed,2)/(2*dist);

        motion.desiredVelocity.copy(motion.direction).normalize().multiplyScalar(speed+(motion.accel * deltaTime));
        motion.seekSteering.copy(motion.desiredVelocity).sub(motion.velocity);
        motion.acceleration.copy(motion.seekSteering).normalize().multiplyScalar(Math.abs(motion.accel));

      } else {
        motion.desiredVelocity.copy(motion.direction).normalize().multiplyScalar(speed+(motion.maxAccel * deltaTime));
        motion.seekSteering.copy(motion.desiredVelocity).sub(motion.velocity);
        motion.acceleration.copy(motion.seekSteering).normalize().multiplyScalar(motion.maxAccel);
      }

      motion.velocity.add(motion.acceleration.multiplyScalar(deltaTime));
      motion.frameMovement.copy(motion.velocity).multiplyScalar(deltaTime);
      if(currState == 2){
        if(!mouse.dragApplied){
          motion.pathVel = motion.dragVel+(motion.pathVel-motion.dragVel)/2;
          motion.dragVelDelta += motion.dragVel;
          motion.dragVel = 0;
          mouse.dragApplied = true;
        }
        if(motion.dragVelDelta != 0){
          let dir = Math.sign(motion.dragVelDelta);
          let absVel = Math.abs(motion.dragVelDelta);
          // let step = Math.min(absVel, (deltaTime/100)*3);
          let step = absVel/3;
          let frameMv = step*dir;
          motion.dragVelDelta -= frameMv;

          motion.frameMovement.x -= frameMv;
          motion.destination.x -= frameMv;
        }
        if(!mouse.dragging){
          motion.frameMovement.x -= motion.pathVel;
          // motion.position.x -= motion.dragVel;
          motion.destination.x -= motion.pathVel;
          // motion.pathVel = Math.sign(motion.pathVel)*Math.max(0, (Math.abs(motion.pathVel)-0.5*(deltaTime/1000)));
          motion.pathVel -= (motion.pathVel * 5)/(1000/deltaTime);
        }
      } else {
        motion.dragVel = 0;
        motion.pathVel = 0;
      }

      motion.position.add(motion.frameMovement);

      motion.blurOrigin.copy(motion.frameMovement).normalize().multiplyScalar(1);
      motion.blurOrigin.x += 0.01;
      
      motion.blurOrigin.add(this.glCamera.camera.position);
      motion.blurOrigin.project(this.glCamera.camera);
      motion.blurOrigin.x = Math.max(-2, Math.min(2, motion.blurOrigin.x));
      motion.blurOrigin.y = Math.max(-2, Math.min(2, motion.blurOrigin.y));

      
      // glScene.postMaterial.uniforms.blurOrigin.value.set((0.5+motion.blurOrigin.x*0.5)*SCREEN_WIDTH, (0.5+motion.blurOrigin.y*0.5)*SCREEN_HEIGHT);
      // glScene.postMaterial.uniforms.blurAmt.value = Math.min(0.2, speed*5);
      motion.blurAmt = Math.min(0.2, (motion.frameMovement.length()/deltaTime)*5);
      // glScene.postMaterial.uniforms.blurAmt.value = motion.blurAmt;
      // console.log('testermountain', this.currState, this.transitioning, glScene.postQuad.material.uniforms.blurAmt.value, glScene.postQuad.material.uniforms.blurOrigin.value);
      this.setViewPosition(motion.position.x, motion.position.y, motion.position.z);

    } else if(currState == 1){
      // SLOW COASTING ANIMATION
      
      motion.direction.set(1, 2.5-motion.position.y, 0);
      var dist = motion.direction.length();
      var speed = motion.velocity.length()
      var frameMovement = speed*deltaTime;
      
      motion.desiredVelocity.copy(motion.direction).normalize().multiplyScalar(0.0005);
      motion.seekSteering.copy(motion.desiredVelocity).sub(motion.velocity);
      motion.acceleration.copy(motion.seekSteering).normalize().multiplyScalar(motion.maxAccel);
      
      motion.velocity.add(motion.acceleration.multiplyScalar(deltaTime));
      
      motion.coastSpeed = Math.min(1, motion.coastSpeed+deltaTime/5000);

      motion.velocity.normalize().multiplyScalar(motion.coastSpeed*0.001);
      motion.velocity.x = Math.abs(motion.velocity.x);
      motion.frameMovement.copy(motion.velocity).multiplyScalar(deltaTime);
      motion.position.add(motion.frameMovement);

      this.setViewPosition(motion.position.x, motion.position.y, motion.position.z);

      // this.glCamera.focalPt.add(motion.frameMovement);
      // motion.focalPt.x = motion.focalPt.x+((motion.position.x+5)-motion.focalPt.x)*motion.coastSpeed;
      // motion.focalPt.y = motion.focalPt.y+(this.glCamera.camera.position.y-motion.focalPt.y)*motion.coastSpeed;


    } else if(currState == 2){
      // HIGH ELEVATION MAP VIEW

    } else if(currState == 3){
      // SECRET GAME MODE

    }

    const glScene = GLScene.getInstance();
    const blurOriginX = motion.blurOrigin.x + motion.blurOriginOffset.x;
    const blurOriginY = motion.blurOrigin.y + motion.blurOriginOffset.y;
    const blurAmt = motion.blurAmt + motion.blurOffset;
    glScene.postMaterial.uniforms.blurOrigin.value.set((0.5+blurOriginX*0.5)*SCREEN_WIDTH, (0.5+blurOriginY*0.5)*SCREEN_HEIGHT);
    glScene.postMaterial.uniforms.blurAmt.value = blurAmt;

    this.glCamera.focalPt.copy(motion.focalPt);
    switch(motion.focalPtMode){
      case 0:
        this.glCamera.focalPt.x -= motion.position.x;
        break;
      case 2:
        this.glCamera.focalPt.add(this.glCamera.camera.position);
        // this.glCamera.camera.localToWorld(this.glCamera.focalPt);
        break;
    }

    this.glCamera.rollAngle = 0;
    
  }

  getMouseX(x, y){
    mouse.screenPos.set((x/SCREEN_WIDTH)*2-1,-(y/SCREEN_HEIGHT)*2+1);
    mouse.raycaster.setFromCamera( mouse.screenPos, this.glCamera.camera );
    if(mouse.raycaster.ray.intersectPlane(mouse.dragPlane, mouse.rayIntersect)){
      // console.log(mouse.rayIntersect.x, mouse.rayIntersect.y, mouse.rayIntersect.z);
      return mouse.rayIntersect.x;
    } else {
      return null;
    };
  }

  onDown(x, y){
    if(!this.active)return;
    var xPos = this.getMouseX(x, y);
    if(xPos !== null && xPos < 40){
      mouse.currDragX = xPos;
      mouse.dragging = true;
    }
    // this.glCamera.onDown(x,y);
  }

  onUp(x, y, dragInfo){
    if(!this.active)return;
    // this.glCamera.onUp(x,y);
    mouse.dragging = false;
  }

  onDrag(x, y, dX, dY){
    if(!this.active)return;
    if(mouse.dragging){
      var xPos = this.getMouseX(x, y);
      if(xPos !== null && xPos < 40){
        const diffX = xPos-mouse.currDragX;
        mouse.currDragX = xPos;
        mouse.dragApplied = false;
        motion.dragVel += diffX;
      }
    }
    // this.glCamera.onDrag(x, y, dX, dY);
  }
  

  render(renderer, camera, currTime, deltaTime) {
    if(!this.initialized || this.paused) return;
    renderer.render(this.scene, camera);
    
    // this.mountainMatCapA.sphereMesh.rotation.y += deltaTime/1000;
    // this.mountainMatCapA.update();
    // this.mountainMatCapA.renderTexToScreen();

    // if(this.currPath){
    //   for(var i=0; i<this.currPath.wayPoints.length; i++){

    //   }

    this.events.publish('UIPosition', {objMatrix:this.UIPosition.matrixWorld, cameraProjectionMatrix: camera.projectionMatrix, cameraMatrixWorldInverse:camera.matrixWorldInverse});

    // }
    
  }

  pause() {
    this.paused = true;
  }

  play() {
    this.paused = false;
  }

  hide() {
    this.active = false;
  }

  show() {
    this.active = true;
  }

  resize(x, y) {
    SCREEN_WIDTH = app.winWidth; // w || window.innerWidth;
    SCREEN_HEIGHT = app.winHeight; // h || window.innerHeight;
    
    this.resolution.set(x, y);

    if(SCREEN_WIDTH/SCREEN_HEIGHT < 4/5){
      this.maskVec.set(.2, -1);
    } else {
      this.maskVec.set(1, -.2);
    }

    pathDestinationZ = map(x/y, 1, 1.5, 0, -2.0);
    if(currState == 2){
      motion.destination.z = pathDestinationZ;
      motion.position.z = pathDestinationZ;
    }
  }
}

export default {
  getInstance() {
    instance = instance || new GLMountain();
    return instance;
  },
};