/* eslint-disable no-param-reassign */
import Section from './Section';
import SectionLoader from '../utils/SectionLoader';
import Navigation from '../utils/Navigation';
import VideoManager from '../widgets/VideoPlayer/VideoManager';
import GLHeader from '../widgets/webgl/GLHeader';
import GLScene from '../widgets/webgl/GLScene';
import app from '../global';
import sfx from '../utils/sfx';

const myName = 'Work';
const sectionLoader = SectionLoader.getInstance();
const navigation = Navigation.getInstance();

let instance;

class Work extends Section {
  constructor() {
    super(myName);
    this.background_color = app.colors.white;
    this.logo_color = app.colors.orange;
  }

  // eslint-disable-next-line class-methods-use-this
  prepareLoad() {
    const files = [];

    if (files.length > 0) {
      sectionLoader.addFiles(myName, files);
    }
  }

  startup(callback) {
    this.videoManager = new VideoManager(this.elements.sectionWrapper);
    this.videoManager.init();

    this.glScene = GLScene.getInstance();

    this.headerScene = new GLHeader({
      name: 'work',
      element: this.elements.sectionWrapper.querySelector('h1'),
      color: app.colors.orange,
      background_color: this.background_color
    });

    this.headerScene.events.subscribe('initialized', () => {
      console.log('SECTION SCENE INITIALIZED');
    });

    // this.glScene.initScene(this.headerScene, () => {
    //   if (callback) callback();
    // });
    this.glScene.initOverlay(this.headerScene, () => {
      if (callback) callback();
    });
  }

  getTitle() {
    return this.elements.sectionWrapper.querySelector('h1').textContent;
  }

  show(callback) {
    if (window.location.pathname.split('/work/').filter((str) => str.length > 0).length > 0 && window.history.state && window.history.state.previousURL) {
      document.body.classList.add('modal');
    }

    if (this.headerScene) {
      const subscription = this.headerScene.events.subscribe('isIn', () => {
        console.log('SECTION SCENE IS IN');
        subscription.remove();

        super.show();
        if (callback) callback();
      });

      // this.glScene.show(this.headerScene);
      this.glScene.showOverlays([this.headerScene.name]);
    } else {
      window.setTimeout(() => {
        super.show(callback);
      }, 500);
    }
  }

  hide(callback, dir) {
    super.hide(callback, dir);
    document.body.classList.remove('modal');
  }

  insert() {
    return new Promise((resolve) => {
      console.log('INSERT WORK SECTION!!', this);
      this.getHTML.then((html) => {
        this.elements = {};
        this.elements.sectionWrapper = html;
        document.querySelector('main').appendChild(html);
        if (window.location.pathname === '/work/') {
          this.elements.sectionWrapper.querySelector('.project_list').addEventListener('click', (e) => {
            e.preventDefault();

            if (e.target.tagName === 'A') {
              navigation.changeSection(e.target.getAttribute('href'));
            }
          });
        }
        resolve();
      });
    });
  }

  destroy() {
    super.destroy();
    // if (this.headerScene) {
    //   this.headerScene.destroy();
    //   this.headerScene = null;
    // }
    if (this.videoManager) {
      this.videoManager.destroy();
      this.videoManager = null;
    }
  }
}

export default {
  getInstance() {
    instance = instance || new Work();
    return instance;
  },
};
