import {
  WebGLRenderTarget,
  TextureLoader,
  PlaneGeometry,
  MeshBasicMaterial,
  Mesh,
  Scene,
  LoadingManager,
  Color,
  OrthographicCamera,
  SphereGeometry,
  MeshPhysicalMaterial,
  AdditiveBlending,
  PointLight,
  DoubleSide,
  ShaderMaterial
} from "three";
import GLScene from "./GLScene";
import app from '../../global';

import { gsap } from 'gsap/all';

import { plainText as sphereFresnelFrag } from './shaders/sphere-fresnel-frag.glsl';
import { plainText as sphereFresnelVert } from './shaders/sphere-fresnel-vert.glsl';

class DynamicMatCap {
  constructor() {
    this.tex = new WebGLRenderTarget(1024, 1024);
    // this.tex.texture.minFilter = LinearMipmapLinearFilter;
    // this.tex.texture.magFilter = LinearMipmapLinearFilter;
    // this.tex.texture.generateMipmaps = true;
    this.state = 0;
    this.scene = new Scene();

    this.camera = new OrthographicCamera(- 1, 1, 1, - 1, 0, 20);
    this.camera.position.set(0, 0, 10);
    this.renderer = GLScene.getInstance().renderer;

    this.sphereMat = new MeshPhysicalMaterial({
      roughness: 0.35,
      metalness: 1
    });
    this.sphereMesh = new Mesh(new SphereGeometry(1, 32, 32), this.sphereMat);
    this.scene.add(this.sphereMesh);
    this.zenithColor = new Color(0x666666);
    this.azimuthColor = new Color(0xFFFFFF);
    this.scene.background = this.azimuthColor;
    this.outerColor = new Color(0x666666);
    this.innerColor = new Color(0x000000);
    this.sphereHighlightMesh = new Mesh(new SphereGeometry(1, 32, 32), new ShaderMaterial({
      uniforms:{
        uInnerColor:{value:this.innerColor},
        uOuterColor:{value:this.outerColor}
      },
      vertexShader: sphereFresnelVert,
      fragmentShader: sphereFresnelFrag,
      transparent: true,
      blending: AdditiveBlending
    }));
    this.scene.add(this.sphereHighlightMesh);
    this.sphereHighlightMesh.position.z = 2;
    this.pointLights = [];
    this.overlayAmt = 1;

    this.debugScene = new Scene();
    this.debugMesh = new Mesh(new PlaneGeometry(1, 1, 1, 1), new MeshBasicMaterial({ map: this.tex.texture }));
    this.debugMesh.scale.setScalar(0.1);
    this.debugMesh.position.set(0.5, 0.5, 0);
    this.debugScene.add(this.debugMesh);
    
    this.highLightMat = new MeshBasicMaterial({
      color: new Color(),
      depthTest: false,
      depthWrite: false,
      transparent: true,
      side: DoubleSide,
      blending: AdditiveBlending
    });
  }
  getLatLong(lat, long, dist) {
    let latX = Math.cos(lat);
    let latY = Math.sin(lat);
    let longX = Math.cos(long);
    let longY = Math.sin(long);
    return { x: latY * longX * dist, y: longY * dist, z: latX * longX * dist };
  }
  addPointLight(parameters) {
    const lightObj = {
      light: new PointLight(),
      r: 1,
      g: 1,
      break: 1,
      intensity: 1,
      decayDistance: 2,
      distance: 2,
      decay: 2,
      lat: 0,
      long: 0
    };
    this.updatePointLight(lightObj, parameters);
    this.pointLights.push(lightObj);
    this.sphereMesh.add(lightObj.light);
  }
  updatePointLight(pointLightObj, parameters) {
    if (parameters.r != undefined) pointLightObj.r = parameters.r;
    if (parameters.g != undefined) pointLightObj.g = parameters.g;
    if (parameters.b != undefined) pointLightObj.b = parameters.b;
    if (parameters.intensity != undefined) pointLightObj.intensity = parameters.intensity;
    if (parameters.decayDistance != undefined) pointLightObj.decayDistance = parameters.decayDistance;
    if (parameters.decay != undefined) pointLightObj.decay = parameters.decay;
    if (parameters.distance != undefined) pointLightObj.distance = parameters.distance;
    if (parameters.lat != undefined) pointLightObj.lat = parameters.lat;
    if (parameters.long != undefined) pointLightObj.long = parameters.long;

    pointLightObj.light.color.setRGB(pointLightObj.r, pointLightObj.g, pointLightObj.b);
    pointLightObj.light.intensity = pointLightObj.intensity;
    pointLightObj.light.decay = pointLightObj.decay;
    pointLightObj.light.distance = pointLightObj.decayDistance;
    pointLightObj.light.position.copy(this.getLatLong(pointLightObj.lat, pointLightObj.long, pointLightObj.distance));
  }
  init() {
    this.state = 1;
    const loadManager = new LoadingManager();

    const highlightTex = new TextureLoader(loadManager).load(`${app.site_path}assets/models/matcap_highlight.jpg`, tex => { tex.flipY = true });
    this.highLightMat.map = highlightTex;
    this.highLightMesh = new Mesh(new PlaneGeometry(2, 2, 1, 1), this.highLightMat);
    this.highLightMesh.position.z = 9;
    this.scene.add(this.highLightMesh);

    loadManager.onLoad = () => {
      this.state = 2;
      this.update();
    }
  }
  transition(settings, transitionDuration){
    console.log('TRANSITIONING', settings);
    if(!settings.pointLights)settings.pointLights = [];
    transitionDuration = (transitionDuration == undefined)?1:transitionDuration;
    for(var i=0; i<this.pointLights.length; i++){
      if(!settings.pointLights[i])settings.pointLights[i] = this.pointLights[i];
      // this.pointLights[i].intensity = 0;
    }
    for(var i=0; i<settings.pointLights.length; i++){
      // update or create lights
      if(settings.pointLights[i].color)this.splitColor(settings.pointLights[i].color, settings.pointLights[i]);
      if(this.pointLights[i]){
        this.transitionLight(this.pointLights[i], settings.pointLights[i], transitionDuration);
        // var lightSettings = this.pointLights[i];
        // console.log('transition light', i);
        // gsap.to(lightSettings, this.changedProps(lightSettings, settings.pointLights[i], {
        //   duration:transitionDuration, 
        //   onUpdate:()=>{
        //     this.updatePointLight(lightSettings, lightSettings);
        //   }
        // }));
      } else {
        this.addPointLight(settings.pointLights[i]);
      }
    }

    const tl = gsap.timeline({
      onComplete: () => {
        this.update();
        console.log('END');
      },
      onUpdate:()=>{
        this.update();
      }
    })
    tl.to(this.innerColor, this.splitColor(settings.innerColor, {duration:transitionDuration}), 0);
    tl.to(this.outerColor, this.splitColor(settings.outerColor, {duration:transitionDuration}), 0);
    tl.to(this.azimuthColor, this.splitColor(settings.azimuthColor, {duration:transitionDuration}), 0);
    tl.to(this.zenithColor, this.splitColor(settings.zenithColor, {duration:transitionDuration}), 0);
    tl.to(this.sphereMat, {metalness:settings.metalness, roughness:settings.roughness, duration:transitionDuration}, 0);
    tl.to(this, {overlayAmt:settings.overlayAmt, duration:transitionDuration, onUpdate:()=>{
      this.highLightMat.color.setScalar(this.overlayAmt);
    }}, 0);

    
    // this.innerColor.set(settings.innerColor);
    // this.outerColor.set(settings.outerColor);
  }
  transitionLight(lightSettings, destSettings, transitionDuration){
    gsap.to(lightSettings, this.changedProps(lightSettings, destSettings, {
      duration:transitionDuration, 
      onUpdate:()=>{
        this.updatePointLight(lightSettings, lightSettings);
      }
    }));
  }
  changedProps(current, destination, inObj){
    if(current.r != destination.color)inObj.r = destination.r;
    if(current.g != destination.color)inObj.g = destination.g;
    if(current.b != destination.color)inObj.b = destination.b;
    if(current.lat != destination.lat)inObj.lat = destination.lat;
    if(current.long != destination.lat)inObj.long = destination.long;
    if(current.intensity != destination.intensity)inObj.intensity = destination.intensity;
    if(current.decay != destination.decay)inObj.decay = destination.decay;
    if(current.decayDistance != destination.decayDistance)inObj.decayDistance = destination.decayDistance;
    if(current.distance != destination.distance)inObj.distance = destination.distance;
    // console.log(inObj);
    return inObj;
  }
  splitColor(hex, obj){
    var col = new Color(hex);
    obj.r = col.r;
    obj.g = col.g;
    obj.b = col.b;
    return obj;
  }
  update() {
    if (this.state == 0) {
      // need to initialize, will automatically call update again when complete
      // console.log('not initialized');
      this.init();
    } else if (this.state == 2) {
      // all is ready to update
      // console.log('update matcap', this.pointLights[0].light.intensity);
      const prevTarget = this.renderer.getRenderTarget();
      this.renderer.setRenderTarget(this.tex);
      this.renderer.render(this.scene, this.camera);
      this.renderer.setRenderTarget(prevTarget);
    }
  }
  renderTexToScreen() {
    this.renderer.render(this.debugScene, this.camera);
  }
}

export default DynamicMatCap;