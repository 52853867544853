import {
  Euler,
  Matrix4,
  PerspectiveCamera,
  Quaternion,
  Vector2,
  Vector3,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry
} from 'three';

import FlyControls from './FlyControls';
import pubsub from '../../utils/pubsub';
import app from '../../global';
import { DoubleSide } from 'three';

let instance;
let SCREENHEIGHT = 1000;
let SCREENWIDTH = 1000;
const PI2 = 6.283185307179586;
const HALFPI = 1.5707963267948966;

function GLCamera() {
  window.glcamera = this;

  this.idealViewingArea = {
    width:0.6, 
    height:0.6,
    distance:0.7
  };
  this.idealViewingMesh = new Mesh(new PlaneGeometry(this.idealViewingArea.width, this.idealViewingArea.height, 2, 2), new MeshBasicMaterial({side:DoubleSide, wireframe:true}));
  this.idealViewingMesh.position.z = -this.idealViewingArea.distance;
  this.idealViewingMesh.visible = false;

  this.camera = new PerspectiveCamera(60, 1, 0.25, 50);
  this.camera.rotation.order = 'YXZ';
  this.camera.lookAt(new Vector3(1, 0, 0));
  this.camera.add(this.idealViewingMesh);

  this.flyMode = false;
  this.flyControls = new FlyControls();

  this.followCameras = [];
  this.followCameraIndex = 0;
  this.followCameraInfluence = 0;

  this.YRot = 0;
  this.rotationQuat = new Quaternion();
  this.rotationEuler = new Euler(0, 0, 0, 'YXZ');

  this.followIndex = 0;
  this.cameraCorrectQuaternion = new Quaternion(-0.7071067690849304, 0, 0, 0.7071067690849304);

  // position is going to be based on a dest position
  // this can be animated directly or can be set from a followCamera
  this.destPosition = new Vector3();
  this.currPos = new Vector3();
  this.prevPos = new Vector3();
  this.accel = new Vector3();
  this.currVel = new Vector3();
  this.prevVel = new Vector3();
  this.rollAngle = 0;


  // this is how far we have dragged the camera from it's original position
  this.cameraAdjustPosition = new Vector3();

  // we have our camera position

  // we have a focal point
  this.focalPt = new Vector3();

  this.mouseDown = false;
  this.events = pubsub.getInstance();

  console.log('GL CAMERA', app.debug);
  if (app.debug) {
    // var _keydown = bind( this, this.keydown );
    // var _keyup = bind( this, this.keyup );

    // window.addEventListener( 'keydown', _keydown, false );
    // window.addEventListener( 'keyup', _keyup, false );

    this.toggleButton = document.createElement('button');
    document.body.appendChild(this.toggleButton);
    this.toggleButton.innerHTML = "TOGGLE FLYMODE ON/<span style='color:#ff0000'>OFF</span>";
    this.toggleButton.style.top = '25px';
    this.toggleButton.style.right = '300px';
    this.toggleButton.style.fontSize = '1em';
    this.toggleButton.style.position = 'absolute';
    this.toggleButton.style.zIndex = 1000;
    this.toggleButton.addEventListener('click', () => {
      this.flyMode = !this.flyMode;
      if (this.flyMode) {
        this.toggleButton.innerHTML = "TOGGLE FLYMODE <span style='color:#ff0000'>ON</span>/OFF";
        this.flyControls.object.position.copy(this.camera.position);
        this.flyControls.object.quaternion.copy(this.camera.quaternion);
        this.flyControls.connect();
      } else {
        this.toggleButton.innerHTML = "TOGGLE FLYMODE ON/<span style='color:#ff0000'>OFF</span>";
        this.flyControls.disconnect();
      }
    });

  }
}


function setFOV(fov, updateMatrix = true) {
  this.camera.fov = fov;
  if (updateMatrix) this.camera.updateProjectionMatrix();
}

function setFollowCamera(camera, index) {
  this.followCameras[index] = camera;
}

function onDown(x, y, dX, dY) {
  if (x > SCREENWIDTH - 20) return;
  this.mouseDown = true;
  
  if (this.flyMode) {
    this.flyControls.mousedown(x, y);
  }
}

function onUp(x, y, dX, dY) {
  this.mouseDown = false;
  this.events.publish('click');
  
  if (this.flyMode) {
    this.flyControls.mouseup(x, y);
  }
}

// this will give you a 0-1 value, 0 if on the side of edgeA,
// 1 if on the side of edgeB, and a decimal if in between edges
function linearStep(edgeA, edgeB, val) {
  return Math.min(1, Math.max(0, (val - edgeA) / (edgeB - edgeA)));
}

function onDrag(x, y, dX, dY) {
  // this.cameraAdjustPosition.z -= dX / (SCREENHEIGHT * 2);
  // this.cameraAdjustPosition.y += dY / (SCREENHEIGHT * 2);
  
  if (this.flyMode) {
    this.flyControls.mousemove(x, y, dX, dY);
  }
}

function update(currTime, deltaTime) {

  this.camera.position.copy(this.destPosition);
  
  this.camera.up.set(0, Math.cos(this.rollAngle), Math.sin(this.rollAngle));
  this.camera.lookAt(this.focalPt);

  this.camera.position.add(this.cameraAdjustPosition);

  if(this.mouseDown){

  } else {
    this.cameraAdjustPosition.multiplyScalar(1-deltaTime/300);
  }

  this.prevPos.copy(this.currPos);
  this.currPos.copy(this.camera.position);
  this.prevVel.copy(this.currVel);
  this.currVel.z = (this.currPos.z - this.prevPos.z)*deltaTime/1000;
  this.currVel.y = (this.currPos.y - this.prevPos.y)*deltaTime/1000;
  this.accel.z = (this.currVel.z - this.prevVel.z)*deltaTime/1000;
  this.accel.y = (this.currVel.y - this.prevVel.y)*deltaTime/1000;

  this.rollAngle += this.currVel.z*deltaTime*1.0;
  this.rollAngle *= (1-deltaTime/200);

  if (this.flyMode) {
    this.flyControls.update(currTime, deltaTime);
    this.camera.position.copy(this.flyControls.object.position);
    this.camera.quaternion.copy(this.flyControls.object.quaternion);
  }
}

function resize() {
  SCREENHEIGHT = app.winHeight;
  SCREENWIDTH = app.winWidth;

  this.camera.aspect = SCREENWIDTH / SCREENHEIGHT;

  // lets do math to figure out the FOV
  // var widthFov = Math.atan(((this.idealViewingArea.width/this.camera.aspect)/2)/this.idealViewingArea.distance);
  // var heightFov = Math.atan((this.idealViewingArea.height/2)/this.idealViewingArea.distance);
  // var rad2Deg = 180/Math.PI;
  // this.camera.fov = Math.max(widthFov, heightFov)*rad2Deg*2;

  this.camera.updateProjectionMatrix();
  // this.flyControls.resize(w,h);
}

GLCamera.prototype.setFOV = setFOV;
GLCamera.prototype.onUp = onUp;
GLCamera.prototype.onDown = onDown;
GLCamera.prototype.onDrag = onDrag;
GLCamera.prototype.update = update;
GLCamera.prototype.resize = resize;
GLCamera.prototype.setFollowCamera = setFollowCamera;

export default {
  getInstance() {
    instance = instance || new GLCamera();
    return instance;
  },
};
