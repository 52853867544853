export default {
  "src": [
    "/assets/audio/siteFX.ogg",
    "/assets/audio/siteFX.m4a",
    "/assets/audio/siteFX.mp3",
    "/assets/audio/siteFX.ac3"
  ],
  "sprite": {
    "sx_Back_toMain": [
      0,
      2775.419501133787
    ],
    "sx_Site_Load": [
      4000,
      5339.659863945578
    ],
    "sx_WhatWeDo_LatestProjects_Click": [
      11000,
      3796.2358276643986
    ],
    "sx_Wipe_toSubPage": [
      16000,
      2897.2562358276655
    ],
    "sx_X_Wipe_Latest": [
      20000,
      5047.256235827664
    ],
    "sx_burger_click": [
      27000,
      2899.7505668934236
    ],
    "sx_generic_click": [
      31000,
      412.76643990929784
    ],
    "sx_toSubPage_noWipe": [
      33000,
      1641.6780045351445
    ]
  }
}