uniform float u_time;
uniform vec2 u_resolution;
uniform vec2 offset;
uniform vec2 dir;
uniform float x_offset;
uniform float front;

varying vec2 vUv;
varying vec3 vColor;
varying vec3 vPosition;
varying vec3 pos;
varying vec3 normalInterp;
varying float zPos;

#ifdef USE_UV2
	attribute vec2 uv2;
	varying vec2 vUv2;
#endif

attribute vec3 midpoint;
attribute float rotation_strength;
attribute vec3 randoms;

// Simplex 2D noise
//
vec3 permute(vec3 x) { return mod(((x*34.0)+1.0)*x, 289.0); }

#define PI 3.1415926538

float snoise(vec2 v){
  const vec4 C = vec4(0.211324865405187, 0.366025403784439, -0.577350269189626, 0.024390243902439);
  vec2 i  = floor(v + dot(v, C.yy) );
  vec2 x0 = v -   i + dot(i, C.xx);
  vec2 i1;
  i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
  vec4 x12 = x0.xyxy + C.xxzz;
  x12.xy -= i1;
  i = mod(i, 289.0);
  vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
  + i.x + vec3(0.0, i1.x, 1.0 ));
  vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy),
    dot(x12.zw,x12.zw)), 0.0);
  m = m*m ;
  m = m*m ;
  vec3 x = 2.0 * fract(p * C.www) - 1.0;
  vec3 h = abs(x) - 0.5;
  vec3 ox = floor(x + 0.5);
  vec3 a0 = x - ox;
  m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
  vec3 g;
  g.x  = a0.x  * x0.x  + h.x  * x0.y;
  g.yz = a0.yz * x12.xz + h.yz * x12.yw;
  return 130.0 * dot(m, g);
}

mat4 rotationMatrix(vec3 axis, float angle) {
    axis = normalize(axis);
    float s = sin(angle);
    float c = cos(angle);
    float oc = 1.0 - c;
    
    return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                0.0,                                0.0,                                0.0,                                1.0);
}

vec3 rotate3d(vec3 v, vec3 axis, float angle) {
  float a = radians(angle);

	mat4 m = rotationMatrix(axis, a);
	return (m * vec4(v, 1.0)).xyz;
}

vec4 scale(vec3 point, vec3 s) {
  mat4 scalemat = mat4 (s.x, 0, 0, 0,
                        0, s.y, 0, 0,
                        0, 0, s.z, 0,
                        0, 0, 0, 1);

  return vec4(point.xyz, 1.) * scalemat;
}

vec2 rotate(vec2 point, float degree, vec2 pivot) {
  vec2 translated = point - pivot;

  float a = -radians(degree);
	float s = sin(a);
	float c = cos(a);

	mat2 m = mat2(c, -s, s, c);

	return m * translated + pivot;
}

void main() {
  // position.y += sin(u_time);

	vUv = uv;
	vColor = vec3( 1.0 );
	#ifdef USE_UV2
		vUv2 = uv2;
	#endif
	#ifdef USE_COLOR
		vColor.xyz *= color.xyz;
	#endif

  vec2 fr = dir;//normalize(dir);
  vec3 stren = abs(midpoint - vec3(fr, 0.));//distance(midpoint, fr);

  float angle = (length(stren) * length(offset)) * rotation_strength * 45.;

  vec3 p = vec3(0.5 + position);
  vec3 m = vec3(0.5 + midpoint);
  // p.x -= randoms.z * 1.5 * offset.x * stren.x;
  // p.y -= randoms.z * 1.5 * offset.y * stren.y;
  // m.x -= randoms.z * 1.5 * offset.x * stren.x;
  // m.y -= randoms.z * 1.5 * offset.y * stren.y;
  p -= randoms.z * 1.5 * vec3(offset, 0.) * stren;
  m -= randoms.z * 1.5 * vec3(offset, 0.) * stren;

  vec3 scaled = rotate3d(p - m, vec3(0., 0., randoms.y - 0.5), angle * (randoms.x - 0.5)).xyz;
  scaled = scale(scaled, vec3(max(0.2, 1. - min(1., length(offset) * 0.5 * length(stren))))).xyz + m;
  scaled -= vec3(0.5);

  float num2 = (length(stren) * length(offset)) * 0.15 * snoise(scaled.xy * vec2(0.75, 0.75) + (u_time + 30. * randoms.x));
  float num1 = (length(stren) * length(offset)) * 0.15 * snoise(scaled.xy * vec2(0.75, 0.75) + (u_time + 10. * randoms.z));

  vec3 transformed = vec3( scaled.x + num1 * 0.5, scaled.y + num2, scaled.z + num1 * 10. );
  vec4 mvPosition = modelViewMatrix * vec4( transformed, 1.0 );
  pos = transformed;

  mvPosition.x += x_offset;

  // mvPosition = modelViewMatrix * vec4(position, 1.);
  gl_Position = projectionMatrix * mvPosition;

}
