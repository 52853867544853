/* eslint-disable prefer-destructuring */
import {
  Scene,
  Mesh,
  OrthographicCamera,
  Color,
  Texture,
  ShaderMaterial,
  BufferAttribute,
  BufferGeometry,
  Vector3,
  Triangle,
  Object3D,
  TextureLoader,
  LoadingManager,
  Euler,
  PlaneGeometry,
  MeshBasicMaterial,
  Vector2,
  CanvasTexture
} from 'three';
import { clamp } from 'three/src/math/MathUtils';
import * as dat from 'dat.gui';

import {
  gsap
} from 'gsap/all';

import mobileCheck from '../../utils/mobileCheck';
import pubsub from '../../utils/pubsub';
import map from '../../utils/maprange';
import headers from '../getheader';

import { plainText as headerVertShader } from './shaders/header-vertex.glsl';
import { plainText as headerFragShader } from './shaders/header-frag.glsl';

import app from '../../global';
import { LinearFilter } from 'three';
import { NoBlending } from 'three';
import { Vector4 } from 'three';
import sfx from '../../utils/sfx';

const dat_gui_settings = {
  rotationAmount: 0,
  scaleAmount: 1,
  y: 100,
  height: 500,
  front: 1
};

const colwidth = 75;
const MAX_POINTS = 2000;

/**
 * Represents a book.
 * @constructor
 * @param {element} h1 - The header <h1> element that we are webgl-ifying.
 * @param {string} name - The name of the section the header is in.
 * @param {string} color - Stroke color -- if you don't provide a color, you must provide highlight_color and shadow_color
 * @param {string} highlight_color - For duotone images, will default to color if not provided
 * @param {string} shadow_color - For duotone images, will default to color if not provided
 * @param {number} stroke_width - Stroke width -- 3 is default because that's the stroke width for all of the internal headers (home page is currently the only section with a different stroke_width of 6)
 * @param {string} background_color - Background color is false for the home page, so stroke overlays the mountain scene, for capabilities and projects send bg color
 * @param {boolean} scrolling - if true, position header relative to the header element's container, (for the inner sections), if false, position header relative to the window (for home page)
 */
class GLHeader {
  static scene = new Scene();
  static camera = new OrthographicCamera(app.winWidth / -2, app.winWidth / 2, app.winHeight / 2, app.winHeight / -2, 1, 2000);
  static render(renderer, camera, currTime, elapsed) {
    renderer.render(GLHeader.scene, GLHeader.camera);
  }

  constructor({ element, name, color, stroke_width = 3, highlight_color, shadow_color, background_color, scrolling = true, text_align = 'left' }) {
    this.top_y = 0;
    this.bottom_y = 0;
    this.text_align = text_align;
    this.scroll_section = true;
    this.offsetX = 0;
    this.offsetY = 0;

    this.stroke_width = stroke_width;
    this.scrolling = scrolling;

    this.highlight_color = highlight_color || color;
    this.shadow_color = shadow_color || color;
    this.background_color = background_color;
    this.name = name;

    this.initialized = false;

    if (element.classList.contains('circle_image')) {
      this.loadManager = new LoadingManager();
      // this.canTex = new Texture();
      const img = element.querySelector('img');
      this.canTex = new TextureLoader(this.loadManager).load(img.src, (tex) => { tex.flipY = true; });
    } else {
      this.canvas = document.createElement('canvas');
      this.canvas.width = 100;
      this.canvas.height = 100;
      this.canTex = new CanvasTexture(this.canvas);
      this.canTex.minFilter = LinearFilter;
      this.canTex.magFilter = LinearFilter;
      this.canTex.generateMipmaps = false;
    }

    this.scrollwrapper = document.documentElement;

    this.vertices = new Float32Array(MAX_POINTS * 3);
    this.midpoints = new Float32Array(MAX_POINTS * 3);
    this.rotation_strengths = new Float32Array(MAX_POINTS);
    this.randoms = new Float32Array(MAX_POINTS * 3);
    this.uvs = new Float32Array(MAX_POINTS * 2);

    this.element = element;

    // this.scene = new Scene();
    if (this.background_color) GLHeader.scene.background = new Color(this.background_color);
    this.events = pubsub.getInstance();

    // const testgeo = new PlaneGeometry();
    // this.testmesh = new Mesh(testgeo, new MeshBasicMaterial({ color: 0x00FF00 }));
    // this.testmesh.scale.set(10, 10, 10);
    // this.scene.add(this.testmesh);
  }

  initDatGui() {
    const gui = new dat.GUI();

    const planeMat = this.buffermesh.material;

    /**
     * This is just to see the difference
     * between the normal the plane vector
     * when the plane vec is on x, y, or both axes
     *
     * Can just hardcode this in the shader later
     */
    let i = 0;
    const options = [
      'x',
      'y',
      'xy'
    ];
    const obj = {
      switch: () => {
        switch (options[i++ % 3]) {
          case 'x':
            planeMat.uniforms.plane.value.set(1, 0, 0);
            controller.name('x');
            break;
          case 'y':
            planeMat.uniforms.plane.value.set(0, 1, 0);
            controller.name('y');
            break;
          case 'xy':
            planeMat.uniforms.plane.value.set(1, 1, 0);
            controller.name('xy');
            break;
          default:
        }
      }
    };
    const controller = gui.add(obj, 'switch').name('cycle plane');

    gui.add(dat_gui_settings, 'rotationAmount', 0, 1, 0.0001);
    gui.add(dat_gui_settings, 'scaleAmount', 0, 2, 0.0001);
    gui.add(dat_gui_settings, 'height', -1000, 1000, 0.0001).onChange(() => {
      dat_gui_settings.f = map(dat_gui_settings.front, 0, 1, this.top_y, this.bottom_y);

      if (dat_gui_settings.height < 0) {
        dat_gui_settings.f = this.top_y;
      } else {
        dat_gui_settings.f = this.bottom_y;
      }
    });
    gui.add(dat_gui_settings, 'y', -1000, 1000, 0.0001);
  }

  updateGeo() {
    this.numcols = Math.ceil(this.ratio.rect.width / colwidth);
    this.numrows = Math.ceil(this.ratio.rect.height / (this.ratio.rect.width / this.numcols));

    if (this.numrows <= 2) {
      const add = 3 - this.numrows;
      this.numrows += add;
      this.numcols += add + 1;
    }

    const geometry = this.buffermesh.geometry;
    const faces = [];
    const midpoints = [];
    const uvs = [];
    const rotation_strengths = [];
    const randoms = [];

    const quads = [];
    for (let row = 0; row < this.numrows; row++) {
      for (let col = 0; col < this.numcols; col++) {
        const up = row === 0 ? false : quads[(row - 1) * (this.numcols) + col];
        const left = col === 0 ? false : quads[row * (this.numcols) + (col - 1)];

        const tl = {
          x: 0,
          y: 0
        };

        const tr = {
          x: (col === (this.numcols - 1)) ? col + 1 : Math.max(0, (col + 1) + (Math.random() * 0.5 - 0.25)),
          y: 0
        };

        const bl = {
          x: 0,
          y: (row === (this.numrows - 1)) ? row + 1 : Math.max(0, (row + 1) + (Math.random() * 0.5 - 0.25))
        };

        const br = {
          x: (col === (this.numcols - 1)) ? col + 1 : Math.max(0, (col + 1) + (Math.random() * 0.5 - 0.25)),
          y: (row === (this.numrows - 1)) ? row + 1 : Math.max(0, (row + 1) + (Math.random() * 0.5 - 0.25))
        };

        if (up) {
          tl.x = up.bl.x;
          tl.y = up.bl.y;

          tr.x = up.br.x;
          tr.y = up.br.y;
        }

        if (left) {
          tl.x = left.tr.x;
          tl.y = left.tr.y;

          bl.x = left.br.x;
          bl.y = left.br.y;
        }

        quads.push({
          tl,
          tr,
          bl,
          br
        });
      }
    }

    for (let i = 0; i < quads.length; i++) {
      const quad = quads[i];

      const flip = Math.round(Math.random());

      let x;
      let y;
      const z = 0;
      let u;
      let v;

      const {
        tl,
        tr,
        bl,
        br
      } = quad;

      const tri1_points = flip ? [tl, tr, bl] : [tl, tr, br];
      const tri = new Triangle();
      for (let j = 0; j < tri1_points.length; j++) {
        const pt = tri1_points[j];
        x = pt.x / this.numcols - 0.5;
        y = pt.y / this.numrows - 0.5;
        u = pt.x / this.numcols;
        v = pt.y / this.numrows;

        faces.push(x, y, z);
        uvs.push(u, v);

        switch (j) {
          case 0:
            tri.a.set(x, y, z);
            break;
          case 1:
            tri.b.set(x, y, z);
            break;
          case 2:
            tri.c.set(x, y, z);
            break;
          default:
            console.log('there should be only 3');
        }
      }

      const mp1 = tri.getMidpoint(new Vector3());

      midpoints.push(mp1.x, mp1.y, mp1.z, mp1.x, mp1.y, mp1.z, mp1.x, mp1.y, mp1.z);
      let rs = Math.random() * 2 - 1;
      rotation_strengths.push(rs, rs, rs);

      let r1 = Math.random();
      let r2 = Math.random();
      let r3 = Math.random();
      randoms.push(r1, r2, r3, r1, r2, r3, r1, r2, r3);

      const tri2_points = flip ? [tr, br, bl] : [tl, br, bl];
      for (let j = 0; j < tri2_points.length; j++) {
        const pt = tri2_points[j];
        x = pt.x / this.numcols - 0.5;
        y = pt.y / this.numrows - 0.5;
        u = pt.x / this.numcols;
        v = pt.y / this.numrows;

        faces.push(x, y, z);
        uvs.push(u, v);

        switch (j) {
          case 0:
            tri.a.set(x, y, z);
            break;
          case 1:
            tri.b.set(x, y, z);
            break;
          case 2:
            tri.c.set(x, y, z);
            break;
          default:
            console.log('there should be only 3');
        }
      }

      const mp2 = tri.getMidpoint(new Vector3());

      midpoints.push(mp2.x, mp2.y, mp2.z, mp2.x, mp2.y, mp2.z, mp2.x, mp2.y, mp2.z);
      rs = Math.random() * 2 - 1;
      rotation_strengths.push(rs, rs, rs);

      r1 = Math.random();
      r2 = Math.random();
      r3 = Math.random();
      randoms.push(r1, r2, r3, r1, r2, r3, r1, r2, r3);
    }

    this.vertices.set(faces, 0);
    this.midpoints.set(midpoints, 0);
    this.rotation_strengths.set(rotation_strengths, 0);
    this.randoms.set(randoms, 0);
    this.uvs.set(uvs, 0);

    geometry.setDrawRange(0, faces.length / 3);

    this.buffermesh.position.x = this.ratio.rect.left + this.ratio.rect.width * 0.5 - app.winWidth * 0.5;
    this.buffermesh.position.y = -app.winHeight;
    this.buffermesh.scale.set(this.ratio.rect.width, this.ratio.rect.height);
    this.scroll_state.originalHeight = this.ratio.rect.height;

    geometry.attributes.position.needsUpdate = true;
    geometry.attributes.midpoint.needsUpdate = true; // ('midpoint', new BufferAttribute(this.midpoints, 3));
    geometry.attributes.rotation_strength.needsUpdate = true; // ('rotation_strength', new BufferAttribute(this.rotation_strengths, 1));
    geometry.attributes.randoms.needsUpdate = true; // ('randoms', new BufferAttribute(this.randoms, 3));
    geometry.attributes.uv.needsUpdate = true; // ('uv', new BufferAttribute(this.uvs, 2));

    this.scroll_state.bounds.x = midpoints[0]; // left
    this.scroll_state.bounds.y = midpoints[1]; // top
    this.scroll_state.bounds.z = midpoints[midpoints.length - 3]; // right
    this.scroll_state.bounds.w = midpoints[midpoints.length - 2]; // bottom

    this.top_x = midpoints[0];
    this.top_y = midpoints[1];
    this.bottom_x = midpoints[midpoints.length - 3];
    this.bottom_y = midpoints[midpoints.length - 2];
  }

  async getHeader() {
    if (this.element.classList.contains('circle_image')) {
      return headers.getCircleHeader(this.element, this.canvas, this.stroke_width);
    }

    switch (this.name) {
      // case 'about':
      //   return headers.getLogoHeader(this.element, this.canvas, this.background_color);
      default:
        await headers.loadFonts(this.element);
        return headers.getTextHeader(this.element, this.canvas, this.stroke_width, this.text_align);
    }
  }

  async init(callback) {
    const rect = await this.getHeader();
    this.dorender = true;

    this.ratio = {
      rect
    };

    if (this.canvas) {
      this.ratio.w = 1;
      this.ratio.h = this.canvas.height / this.canvas.width;
    } else {
      this.ratio.w = 1;
      this.ratio.h = 1;
    }

    this.firstrender = true;
    this.initialized = true;

    const width = app.winWidth; // = window.innerWidth;
    const height = app.winHeight; // = window.innerHeight;
    // this.camera = new OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 2000);
    GLHeader.camera.position.z = 100;

    this.scroll_state = {
      dir: new Vector2(1, 1),
      from: new Vector2(),
      bounds: new Vector4(),
      // lasty: -height,
      // y: -height,
      // lastx: 0,
      // x: 0,
      lastPosition: new Vector2(0, 0),
      position: new Vector2(0, 0),
      size: new Vector2(),
      // scrolltop: 0,
      t: 0,
      // currvel: 0,
      // lastvel: 0,
      vel: new Vector2(),
      // height: 0
    };

    const planeMat = new ShaderMaterial({
      uniforms: {
        map: { value: this.canTex },
        plane: { value: new Vector3(1, 0, 0) },
        u_time: { value: 0 },
        u_resolution: { value: new Vector2(this.ratio.rect.width, this.ratio.rect.height) },
        u_imageRatio: { value: 1 },
        u_circle: { value: 0 }, // if 1, the plane will be masked to a circle shape
        u_transparent: { value: 1 }, // if 1, r channel of the texture will be used for alpha
        x_offset: { value: 0 },
        opacity: { value: 1 },
        dir: { value: new Vector2() },
        offset: { value: new Vector2() },
        front: { value: new Vector2() },
        highlight_color: { value: new Color(this.highlight_color) },
        shadow_color: { value: new Color(this.shadow_color) }
      },
      depthTest: false,
      depthWrite: false,
      transparent: true,
      vertexShader: headerVertShader,
      fragmentShader: headerFragShader,
      // blending: NoBlending
    });

    const testMat = new MeshBasicMaterial({color: new Color(Math.random(), Math.random(), Math.random()), opacity: 0.5, depthWrite: false, depthTest: false});

    planeMat.extensions.derivatives = true;

    if (this.loadManager) {
      this.loadManager.onLoad = () => {
        planeMat.uniforms.u_circle.value = 1;
        testMat.color = new Color(1, 0, 0);
        planeMat.uniforms.shadow_color.value.set(this.shadow_color);
        planeMat.uniforms.u_transparent.value = 0;
        planeMat.uniforms.u_imageRatio.value = this.canTex.image.width / this.canTex.image.height;
      };
    }

    const geometry = new BufferGeometry();
    geometry.setDrawRange(0, 0);

    geometry.setAttribute('position', new BufferAttribute(this.vertices, 3));
    geometry.setAttribute('midpoint', new BufferAttribute(this.midpoints, 3));
    geometry.setAttribute('rotation_strength', new BufferAttribute(this.rotation_strengths, 1));
    geometry.setAttribute('randoms', new BufferAttribute(this.randoms, 3));
    geometry.setAttribute('uv', new BufferAttribute(this.uvs, 2));

    this.buffermesh = new Mesh(geometry, planeMat);
    // this.buffermesh = new Mesh(geometry, testMat);
    this.buffermesh.position.z = -100;

    const testgeo = new PlaneGeometry(1, 1);
    this.testmesh = new Mesh(testgeo, testMat);
    this.buffermesh.renderOrder = 1;
    this.testmesh.renderOrder = 0;
    this.testmesh.scale.set(100, 100);
    this.testmesh.position.z = -90;
    this.testmesh.position.x = Math.random() * 100 - 50;
    this.testmesh.position.y = Math.random() * 100 - 50;

    GLHeader.scene.add(this.buffermesh);
    // this.scene.add(this.testmesh);

    this.canTex.needsUpdate = true;

    this.updateGeo();

    this.events.publish('initialized');

    if (app.debug) {
      this.initDatGui();
      this.canvas.style.position = 'fixed';
      this.canvas.style.top = '0px';
      this.canvas.style.left = '0px';
      this.canvas.style.zIndex = 1000;
      this.canvas.style.width = '600px';

      document.body.appendChild(this.canvas);
    }

    if (callback) callback();
  }

  update(renderer, camera, currTime, elapsed) {
    if (!this.dorender) return;

    const dt = elapsed / 16;

    if (this.buffermesh && this.showit) {

      if (!this.hiding && !this.showing) this.scroll_state.position.y = Math.max(0, window.scrollY) + this.offsetY;

      this.scroll_state.vel.subVectors(this.scroll_state.position, this.scroll_state.lastPosition);
      this.scroll_state.lastPosition.copy(this.scroll_state.position);

      const minSpeed = 0.1;

      this.scroll_state.vel.x = Math.abs(this.scroll_state.vel.x) < minSpeed ? 0 : this.scroll_state.vel.x;
      this.scroll_state.vel.y = Math.abs(this.scroll_state.vel.y) < minSpeed ? 0 : this.scroll_state.vel.y;

      const height_multiplier = new Vector2(0.95, 0.95);
      if (!(this.showing || this.hiding)) {
        height_multiplier.x = map(Math.min(5, Math.abs(this.scroll_state.vel.x)), 0, 5, 0.9, 0.99);
        height_multiplier.y = map(Math.min(5, Math.abs(this.scroll_state.vel.y)), 0, 5, 0.9, 0.99);
      }
  
      this.scroll_state.size.multiply(height_multiplier); // *= height_multiplier;
      this.scroll_state.size.add(this.scroll_state.vel.multiplyScalar(dt));

      // const strength = new Vector2(Math.abs(0.5 * (this.scroll_state.vel.x / this.ratio.rect.width)), Math.abs(0.5 * (this.scroll_state.vel.y / this.ratio.rect.height)));

      // if (strength.x < 0.01) strength.x = 0;
      // if (strength.y < 0.01) strength.y = 0;

      this.buffermesh.material.uniforms.u_time.value = this.scroll_state.t;
      // this.buffermesh.material.uniforms.front.value = this.scroll_state.size.y < 0 ? this.top_y : this.bottom_y;
      const front_y = this.scroll_state.size.y < 0 ? this.scroll_state.bounds.y : this.scroll_state.bounds.w;
      const front_x = this.scroll_state.size.x < 0 ? this.scroll_state.bounds.x : this.scroll_state.bounds.z;
      this.buffermesh.material.uniforms.dir.value.set(front_x, front_y);
      this.buffermesh.material.uniforms.offset.value.x = this.scroll_state.size.x / this.ratio.rect.width;
      this.buffermesh.material.uniforms.offset.value.y = this.scroll_state.size.y / this.ratio.rect.height;

      // console.log(this.scroll_state.position.x);
      this.buffermesh.position.y = -(this.ratio.offsetTop - this.scroll_state.position.y) - (this.ratio.rect.height + this.scroll_state.size.height) * 0.5 + app.winHeight * 0.5;
      this.buffermesh.position.x = -app.winWidth * 0.5 + (this.ratio.rect.left + this.ratio.rect.width * 0.5 + this.scroll_state.position.x);
    }

    this.scroll_state.t += (0.005) * dt;
  }

  hide(dir = 1, instant) {
    return new Promise((resolve) => {
      // console.log(this.winHeight);
      const duration = instant ? 0 : 0.5;

      this.hiding = true;
      gsap.killTweensOf(this.scroll_state.position);
      gsap.to(this.scroll_state.position, {
        y: dir * app.winHeight,
        ease: 'power2.in',
        duration,
        onComplete: () => {
          // this.showit = false;
          resolve();
        }
      });
      // this.scroll_state.target = -this.winHeight;
    });
  }

  reset() {
    if (this.from) {
      this.scroll_state.from.copy(this.from);
    } else {
      this.scroll_state.from.set(0, -app.winHeight);
    }
    this.scroll_state.lastPosition.copy(this.scroll_state.from);
    this.scroll_state.position.copy(this.scroll_state.from);
    this.scroll_state.vel.set(0, 0);
  }

  show() {
    return new Promise((resolve) => {
      // this.dorender = false;
      this.hiding = false;
      this.showing = true;
  
      gsap.killTweensOf(this.scroll_state.position);
      this.reset();
      this.showit = false;
      this.buffermesh.visible = true;

      if (this.scroll_state.from.x === 0) {
        window.setTimeout(() => {
          sfx.playFx('sx_toSubPage_noWipe');
        }, 650);
      }

      gsap.fromTo(
        this.scroll_state.position,
        {
          x: this.scroll_state.from.x,
          y: this.scroll_state.from.y
        },
        {
          x: 0,
          y: 0,
          ease: 'power2.inOut',
          duration: 0.75,
          delay: 0.35,
          onComplete: () => {
            this.showing = false;
            this.events.publish('isIn');
            resolve();
          },
          onStart: () => {
            this.showit = true;
          }
        }
      );
    });
  }

  async resize() {
    const camFactor = 2;
    GLHeader.camera.left = -app.winWidth / camFactor;
    GLHeader.camera.right = app.winWidth / camFactor;
    GLHeader.camera.top = app.winHeight / camFactor;
    GLHeader.camera.bottom = -app.winHeight / camFactor;
    GLHeader.camera.updateProjectionMatrix();

    await this.getHeader(); // this.element.getBoundingClientRect();

    if (this.canvas) {
      this.canTex.dispose();

      this.canTex = new CanvasTexture(this.canvas);
      this.canTex.minFilter = LinearFilter;
      this.canTex.magFilter = LinearFilter;
      this.canTex.generateMipmaps = false;
      this.buffermesh.material.uniforms.map.value = this.canTex;
    }

    if (this.canvas) this.ratio.h = this.canvas.height / this.canvas.width;

    const rect = this.element.getBoundingClientRect();
    rect.width = Math.min(rect.width, rect.height * (this.ratio.w / this.ratio.h));
    rect.height = rect.width * (this.ratio.h / this.ratio.w);

    this.ratio.rect = rect;

    // save offsetTop because boundingClientRect.top is relative to window and we want relative to container
    // add the difference between the actual element height and the rect height in case the title needs to be
    // scaled down a bit because the text is too long
    if (this.scrolling) {
      this.ratio.offsetTop = this.element.offsetTop + (this.element.offsetHeight - this.ratio.rect.height);
    } else {
      this.ratio.offsetTop = this.ratio.rect.top;
    }

    this.buffermesh.material.uniforms.u_resolution.value.set(this.ratio.rect.width, this.ratio.rect.height);

    this.updateGeo();
  }

  // clearScene(obj) {
  //   if (obj.parent) obj.parent.remove(obj);

  //   if (typeof obj.children !== 'undefined') {
  //     while (obj.children.length > 0) {
  //       this.clearScene(obj.children[0]);
  //     }
  //   }

  //   if (obj instanceof Scene) return;

  //   if (obj instanceof Object3D) {
  //     obj.geometry.dispose();
  //     obj.material.dispose();
  //   }
  // }

  destroy() {
    console.log('destroy gl header');

    GLHeader.scene.remove(this.buffermesh);
    // this.clearScene(this.scene);
    this.events.destroy();
    Object.keys(this).forEach((key) => delete this[key]);

    // this.buffermesh.parent.remove(this.buffermesh);
    // GLHeader.scene.remove(this.buffermesh);
    console.log('children', GLHeader.scene.children);
  }
}

export default GLHeader;
