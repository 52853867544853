

import { SphereGeometry } from "three";
import {
  Vector3,
  Group
} from "three";
import Alea from 'aleaprng';
import { Mesh } from "three";
import { MeshBasicMaterial } from "three";
import { BoxGeometry } from "three";
import { Object3D } from "three";
import { Color } from "three";
import { PlaneGeometry } from "three";
import { DoubleSide } from "three";
import { ShaderMaterial } from "three";

import { plainText as ribbonVert }  from './shaders/ribbon-vertex.glsl';
import { plainText as ribbonFrag } from './shaders/ribbon-frag.glsl';
import GLMountain from "./GLMountain";
import GLScene from "./GLScene";
import { gsap } from 'gsap/all';

function epsilon(value) {
  return Math.abs(value) < 1e-10 ? 0 : value;
}

function getObjectCSSMatrix(matrix, side) {
  const elements = matrix.elements;
  const matrix3d = 'matrix3d(' + epsilon( elements[ 0 ] )*0.0075 + ',' + epsilon( elements[ 1 ] )*0.0075 + ',' + epsilon( elements[ 2 ] )*0.0075 + ',' + epsilon( elements[ 3 ] ) + ',' + epsilon( - elements[ 4 ] )*0.0075 + ',' + epsilon( - elements[ 5 ] )*0.0075 + ',' + epsilon( - elements[ 6 ] )*0.0075 + ',' + epsilon( - elements[ 7 ] ) + ',' + epsilon( elements[ 8 ] )*0.0075 + ',' + epsilon( elements[ 9 ] )*0.0075 + ',' + epsilon( elements[ 10 ] )*0.0075 + ',' + epsilon( elements[ 11 ] ) + ',' + epsilon( elements[ 12 ] ) + ',' + epsilon( elements[ 13 ] ) + ',' + epsilon( elements[ 14 ] ) + ',' + epsilon( elements[ 15 ] ) + ')';
  return 'translate(-50%,-50%)' + matrix3d;
  return (side?'translate(-100%,-50%)':'translate(0%,-50%)') + matrix3d;
}

function lerp(valA, valB, mix){
  return valA + (valB-valA)*mix;
}
function map(val, inA, inB, outA, outB){
  return lerp(outA, outB, Math.max(0, Math.min(1, (val-inA)/(inB-inA)))); 
}

// A path needs to be 
class GLMountainPath {
  constructor(domID, hexColor, heightMap) {
    var aspect = Math.min(1, window.innerWidth/window.innerHeight);
    var zRange = map(aspect, 1., 0.5, 4.01, 1.0);

    this.mountain = GLMountain.getInstance();
    this.glScene = GLScene.getInstance();
    this.pos = new Vector3();
    this.pathLinks = [].slice.call(document.body.querySelectorAll(domID + ' a')).map((el) => {
      return {
        el,
        wrapper: el.parentElement,
        image: el.querySelector('.size_wrapper')
      }
    });
    this.hexColor = hexColor;
    this.xOffset = 0;

    // we'll use the passed domID as our random seed
    const alea = new Alea(domID+'durp');

    this.group = new Group();
    this.group.visible = false;
    const sphereGeo = new SphereGeometry(0.1, 8, 8);
    this.pathLength = 0;
    this.wayPoints = [];
    var prevZ = 0;
    for (var l = 0; l < this.pathLinks.length; l++) {
      const ptGroup = new Group();
      this.group.add(ptGroup);
      var newZ = alea.range(-zRange, zRange);
      while(Math.abs(newZ-prevZ)<(zRange/4)){newZ = alea.range(-zRange, zRange)};
      prevZ = newZ;
      ptGroup.position.z = newZ;
      ptGroup.position.x = this.pathLength;
      // ptGroup.position.y = 1.5;

      const sphereMat = new MeshBasicMaterial({transparent:true, color:new Color(this.hexColor)});
      const labelMesh = new Mesh(sphereGeo, sphereMat);
      ptGroup.add(labelMesh);
      labelMesh.rotation.order = "XZY";
      labelMesh.visible = false;

      this.pathLinks[l].wrapper.classList.add(ptGroup.position.z<0?'rightLabel':'leftLabel')
      // sphereMesh.scale.set(0.0075, 0.0075, 0.0075);
      this.pathLength += alea.range(4, 7.5);
      // this.pathLinks[l].wrapper.classList.add('show');
      this.wayPoints.push({
        link: this.pathLinks[l],
        group: ptGroup,
        labelMesh: labelMesh,
        x: ptGroup.position.x,
        z: ptGroup.position.z
      })
    }
    // const lineGeo = new BoxGeometry(0.05, 1, 0.05, 1, 10, 1);
    const lineGeo = new PlaneGeometry(5, 1, 2, 10);
    this.lineMat = new ShaderMaterial({
      uniforms: {
        color: {value:new Color(this.hexColor)},
        opacity: {value:1},
        time: {value:0},
        heightMap: {value:heightMap},
        mountainPosition: {value:0},
        fadeDist:{value:Math.min(20,this.pathLength-6.5)},
        fadeDistOffset:{value:0}
      },
      transparent: true,
      side: DoubleSide,
      vertexShader:ribbonVert,
      fragmentShader:ribbonFrag
    });
    lineGeo.translate(2.5, 0.5, 0);
    for (var l = 0; l < this.pathLinks.length; l++) {
      let fromPt = this.wayPoints[(l+this.pathLinks.length-1)%this.pathLinks.length];
      let destPt = this.wayPoints[l];
      // const lineMat = new MeshBasicMaterial({transparent:true, color:new Color(this.hexColor), side:DoubleSide});
      const lineMesh = new Mesh(lineGeo, this.lineMat);
      let deltX = destPt.x-fromPt.x;
      if(l == 0)deltX=this.pathLength-fromPt.x;
      const deltZ = destPt.z-fromPt.z;
      lineMesh.rotation.x = -Math.PI/2;
      lineMesh.rotation.z = Math.atan2(deltX, deltZ);
      lineMesh.rotation.y = -Math.PI/2;
      lineMesh.rotation.order = "XZY";
      lineMesh.position.y = -destPt.group.position.y;
      lineMesh.scale.y = Math.sqrt((deltX*deltX)+(deltZ*deltZ));
      destPt.group.add(lineMesh);
      this.wayPoints[l].lineMesh = lineMesh;
    }
    
  }
  show() {
    return new Promise((resolve) => {
      this.shown = true;
      this.group.visible = true;

      for (var l = 0; l < this.pathLinks.length; l++) {
        const {
          group
        } = this.wayPoints[l];

        const {
          wrapper
        } = this.pathLinks[l];

        wrapper.classList.remove('notransition');
        wrapper.classList.add('show');
        // force the wrap around function to call
        while(group.position.x < this.pathLength) group.position.x += this.pathLength;
      }
      gsap.killTweensOf(this.lineMat.uniforms.fadeDistOffset);
      gsap.fromTo(this.lineMat.uniforms.fadeDistOffset, {value:-this.pathLength}, {value:0, duration:1, onComplete: resolve});
    });
  }
  hide() {
    this.shown = false;
    for (var l = 0; l < this.pathLinks.length; l++) {
      this.pathLinks[l].wrapper.classList.remove('show');
    }
    gsap.killTweensOf(this.lineMat.uniforms.fadeDistOffset);
    gsap.fromTo(this.lineMat.uniforms.fadeDistOffset, {value:0}, {value:-this.pathLength, duration:2, onComplete:()=>{
      this.group.visible = false;
    }});
  }
  // move(distance, mountainXPos) {
  //   this.xOffset -= distance;
  //   let opacity = 0;
  //   for (var l = 0; l < this.wayPoints.length; l++) {
  //     this.wayPoints[l].group.position.x -= distance;
  //     while(this.wayPoints[l].group.position.x < 0)this.wayPoints[l].group.position.x += this.pathLength;
  //     while(this.wayPoints[l].group.position.x > this.pathLength)this.wayPoints[l].group.position.x -= this.pathLength;
  //     opacity = 1-(Math.max(0, 5-(this.pathLength-0.5-this.wayPoints[l].group.position.x))/5);
  //     opacity *= Math.max(0, Math.min(3, this.wayPoints[l].group.position.x)/3);
  //     this.wayPoints[l].lineMesh.material.uniforms.mountainPosition.value = mountainXPos;
  //     var screenDist = (Math.max(0, this.pathLength-this.wayPoints[l].group.position.x)/this.pathLength);
      
  //     this.wayPoints[l].labelMesh.material.opacity = opacity;
  //     this.pathLinks[l].wrapper.style.opacity = opacity;
  //     this.wayPoints[l].labelMesh.quaternion.copy(this.mountain.glCamera.camera.quaternion);

  //     this.pathLinks[l].wrapper.style.transform = getObjectCSSMatrix(this.wayPoints[l].labelMesh.matrixWorld, this.wayPoints[l].group.position.z<0?0:1);
      
  //     if (screenDist > 0.6) {
  //       this.pathLinks[l].wrapper.classList.add('open');
  //     } else {
  //       this.pathLinks[l].wrapper.classList.remove('open');
  //     }
  //   }
  // }
  move(distance, mountainXPos) {
    this.xOffset -= distance;
    let opacity = 0;
    for (let l = 0; l < this.wayPoints.length; l++) {
      const {
        group,
        labelMesh,
        lineMesh
      } = this.wayPoints[l];

      const {
        wrapper
      } = this.pathLinks[l];

      group.position.x -= distance;
      while (group.position.x < 0) {
        group.position.x += this.pathLength;
        labelMesh.position.y = this.getHeight(group.position.x, group.position.z, mountainXPos);
      }

      while (group.position.x > this.pathLength) {
        group.position.x -= this.pathLength;
        labelMesh.position.y = this.getHeight(group.position.x, group.position.z, mountainXPos);
      }

      opacity = 1 - Math.min(1, Math.max(0, 5 - (this.pathLength - 0.5 - group.position.x)) / 5);
      opacity *= Math.max(0, Math.min(3, group.position.x) / 3);
      const screenDist = (Math.max(0, this.pathLength - group.position.x) / this.pathLength);
      lineMesh.material.uniforms.opacity.value = opacity * 0.5;
      lineMesh.material.uniforms.mountainPosition.value = mountainXPos;

      labelMesh.material.opacity = opacity;
      this.pathLinks[l].wrapper.style.opacity = opacity;
      labelMesh.quaternion.copy(this.mountain.glCamera.camera.quaternion);
      this.pathLinks[l].wrapper.style.transform = getObjectCSSMatrix(labelMesh.matrixWorld, group.position.z < 0 ? 0 : 1);

      if (screenDist > 0.6 && screenDist < 0.95) {
        wrapper.classList.add('open');
      } else {
        wrapper.classList.remove('open');
        // if (screenDist < 0.5 || screenDist > 0.96) {
        //   wrapper.classList.add('notransition');
        // } else {
        //   wrapper.classList.remove('notransition');
        // }
      }
      // if (l === 0) console.log(screenDist);
    }
  }
  getHeight(x, z, mountainXPos){
    
    let heightBuff = new Uint8Array(4);
    this.glScene.renderer.readRenderTargetPixels(this.mountain.heightTarget, this.mountain.heightTargetSize.x*(((x+mountainXPos)% 35)/40), this.mountain.heightTargetSize.y*(-z+5)/10, 1, 1, heightBuff);
    return (heightBuff[0]/255)*2.25;
  }
  update(deltaTime){
    this.lineMat.uniforms.time.value += deltaTime/1000;
  }
}

export default GLMountainPath;