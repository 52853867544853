import Section from './Section';
import SectionLoader from '../utils/SectionLoader';
import GLHeader from '../widgets/webgl/GLHeader';
import GLScene from '../widgets/webgl/GLScene';
import app from '../global';

const myName = 'Contact';
const sectionLoader = SectionLoader.getInstance();

let instance;

class Contact extends Section {
  constructor() {
    super(myName);
  }

  startup(callback) {
    this.glScene = GLScene.getInstance();

    this.sectionScene = new GLHeader({
      name: 'contact',
      element: this.elements.sectionWrapper.querySelector('h1'),
      background_color: app.colors.dark_gray
    });

    this.sectionScene.events.subscribe('initialized', () => {
      console.log('SECTION SCENE INITIALIZED');
    });

    this.glScene.initScene(this.sectionScene, () => {
      if (callback) callback();
    });
  }

  show(callback) {
    const subscription = this.sectionScene.events.subscribe('isIn', (e) => {
      console.log('SECTION SCENE IS IN');
      subscription.remove();
      super.show();
      if (callback) callback();
    });

    this.glScene.show(this.sectionScene);
  }

  hide(callback, dir) {
    super.hide(callback, dir);
    document.body.classList.remove('modal');
  }

  destroy() {
    super.destroy();
    if (this.sectionScene) {
      this.sectionScene.destroy();
      this.sectionScene = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  prepareLoad() {
    const files = [];

    if (files.length > 0) {
      sectionLoader.addFiles(myName, files);
    }
  }
}

export default {
  getInstance() {
    instance = instance || new Contact();
    return instance;
  },
};
