import app from '../global';
import Navigation from '../utils/Navigation';
import sfx from '../utils/sfx';

const navigation = Navigation.getInstance();

class Section {
  constructor(myName) {
    console.log('Section constructor', myName);
    this.name = myName;
    this.background_color = app.colors.white;
    this.logo_color = app.colors.black;
  }

  init(callback) {
    console.log('Section init', this.name);

    this.initialized = true;

    // this.elements = {};
    // this.elements.sectionWrapper = document.getElementById(this.name.toLowerCase());
    const prevNext = document.querySelector('#prev_next');
    if (prevNext) {
      prevNext.addEventListener('click', (e) => {
        if (e.target.tagName === 'A') {
          e.preventDefault();
          app.wipe = false;
          navigation.changeSection(e.target.getAttribute('href'));
          sfx.playFx('sx_generic_click');
        }
      });
    }

    if (callback) callback();
  }

  getTitle() {
    return this.name.toUpperCase();
  }

  show(callback) {
    console.log(this, `show name ${this.name}`);
    this.showtimeout = window.setTimeout(() => {
      this.elements.sectionWrapper.classList.remove('changesection');
      this.elements.sectionWrapper.classList.remove('hide');
      this.elements.sectionWrapper.classList.remove('up');
      this.elements.sectionWrapper.classList.add('show');
      app.mainMenu.showLogo(this.logo_color);

      window.setTimeout(() => {
        const footer = document.querySelector('#footer');
        footer.classList.add('show');
      }, 500);

      if (document.body.classList.contains('modal')) document.querySelector('#mainHeader .close_button').classList.add('show');
    }, 250);
    // this.elements.sectionWrapper.classList.remove('hide');
    // this.elements.sectionWrapper.classList.add('show');

    // document.body.dataset.section = this.name.toLowerCase();
    // document.body.setAttribute('data-section', this.name.toLowerCase());

    if (callback) callback();
  }

  hide(callback, dir = 1) {
    if (this.showtimeout) window.clearTimeout(this.showtimeout);

    console.log(`hide ${this.name}`);
    const hide_classes = dir < 0 ? ['hide'] : ['hide', 'up'];
    this.elements.sectionWrapper.classList.add(...hide_classes);
    document.querySelector('#mainHeader .close_button').classList.remove('show');
    if (this.headerScene) {
      this.headerScene.hide(dir).then(() => {
        console.log('SECTION IS HIDDEN');
        if (!app.wipe) callback();
      });
    }

    if (callback && app.wipe) callback();
  }

  startup(callback) {
    console.log(`startup ${this.name}`);
    if (callback) callback();
  }

  shutdown(callback) {
    console.log(`shutdown ${this.name}`);
    this.elements.sectionWrapper.classList.remove('show', 'hide');
    document.querySelector('#footer').style.visibility = 'hidden';
    if (document.activeElement) document.activeElement.blur();

    if (callback) callback();
  }

  loadHTML(url) {
    /*
    * if <html> tag has the class 'first', this is the first page
    * the user visited, so we don't need to load the html
    */
    if (document.documentElement.classList.contains('first')) {
      // if (window.scrollY) {
      //   window.scroll(0, 0);  // reset the scroll position to the top left of the document.
      // }

      this.getHTML = new Promise((resolve, reject) => {
        document.documentElement.classList.remove('first');
        const section_html = document.querySelector(`#${this.name.toLowerCase()}`);
        window.scroll(0, 0);
        resolve(section_html);
      });

      return;
    }

    /*
    * load the new section, use DOMParser to convert to html,
    * select the section element from the new html and pass it
    * to the resolve() function
    */
    this.getHTML = new Promise((resolve, reject) => {
      const parser = new DOMParser();
      fetch(url).then((resp) => resp.text()).then((html) => {
        const section_html = parser.parseFromString(html, 'text/html').querySelector('section');
        resolve(section_html);
      });
    });
  }

  insert() {
    return new Promise((resolve) => {
      console.log('INSERT!!', this);
      this.getHTML.then((html) => {
        console.log('MY HTML', html);
        this.elements = {};
        this.elements.sectionWrapper = html;
        document.querySelector('main').appendChild(html);
        resolve();
      });
    });
  }

  destroy() {
    console.log('DESTROY', this.name);
    this.added = false;
    this.initialized = false;

    if (this.headerScene) {
      app.glScene.destroyOverlays([this.headerScene.name]);
    }

    if (this.elements.sectionWrapper.parentElement) this.elements.sectionWrapper.parentElement.removeChild(this.elements.sectionWrapper);
    if (this.elements) Object.keys(this.elements).forEach((key) => delete this.elements[key]);
    if (this.headerScene) delete this.headerScene;
    if (this.elements) delete this.elements;
    // Object.keys(this).forEach((key) => delete this[key]);
  }

  resize() {
    console.log('THIS RESIZE', this.headerScene);
    if (this.headerScene) this.headerScene.resize();
  }
}

export default Section;
