/* eslint-disable class-methods-use-this */
import {
  Scene,
  Mesh,
  OrthographicCamera,
  PlaneGeometry,
  MeshBasicMaterial,
  Vector2,
  Vector3,
  BufferGeometry,
  BufferAttribute,
  Triangle,
  ShaderMaterial,
  Color,
  Euler,
  Matrix4
} from 'three';

import * as dat from 'dat.gui';
import { gsap } from 'gsap/all';

import GLHeader from '../widgets/webgl/GLHeader';
import GLScene from '../widgets/webgl/GLScene';
import GLMountain from '../widgets/webgl/GLMountain';
import Section from './Section';
import SectionLoader from '../utils/SectionLoader';
import Navigation from '../utils/Navigation';
import app from '../global';
import Interaction from '../utils/Interaction';
import sfx from '../utils/sfx';

const myName = 'Home';
const sectionLoader = SectionLoader.getInstance();
const navigation = Navigation.getInstance();
const mountain = GLMountain.getInstance();

let instance;
let _heightHalf;
let _widthHalf;
// let lastMouseWheelTime = Date.now();

const CAPABILITIES = 1;
const PROJECTS = 0;
let linksType = CAPABILITIES;

const dat_gui_settings = {
  scale: 1,
  x: 0,
  y: 0,
  z: 0,
  rx: 0,
  ry: 0,
  rz: 0,
  zpos: 0,
  mat: [0.583333, 0.186887, 0.79044, 0, -0.52022, 0.833333, 0.186887, 0, -0.623773, -0.52022, 0.583333, 0, 0, 0, 0, 1]
};

function epsilon(value) {
  return Math.abs(value) < 1e-10 ? 0 : value;
}

function getCameraCSSMatrix({ elements }) {
  return `matrix3d(${[
    epsilon(elements[0]),
    epsilon(-elements[1]),
    epsilon(elements[2]),
    epsilon(elements[3]),
    epsilon(elements[4]),
    epsilon(-elements[5]),
    epsilon(elements[6]),
    epsilon(elements[7]),
    epsilon(elements[8]),
    epsilon(-elements[9]),
    epsilon(elements[10]),
    epsilon(elements[11]),
    epsilon(elements[12]),
    epsilon(-elements[13]),
    epsilon(elements[14]),
    epsilon(elements[15])
  ].join(',')})`;
}

function updateBillboardLabel(data) {
  const {
    cameraProjectionMatrix,
    cameraMatrixWorldInverse
  } = data;

  const fov = cameraProjectionMatrix.elements[5] * (_heightHalf);

  if (this.camera.fov !== fov) {
    this.elements.sectionWrapper.style.perspective = `${fov}px`;
    this.camera.fov = fov;
  }

  const cameraCSSMatrix = `translateZ(${fov}px) ${getCameraCSSMatrix(cameraMatrixWorldInverse)}`;
  const style = `${cameraCSSMatrix} translate(${_widthHalf}px, ${_heightHalf}px)`;

  if (this.camera.style !== style) {
    this.camera.element.style.transform = style;
    this.camera.style = style;
  }
}

class Home extends Section {
  constructor() {
    super(myName);
    this.acc = 0;
    this.overlays = {};
    this.dragState = {
      prevX: 0,
      prevY: 0,
      velocityX: 0,
      velocityY: 0
    };

    this.logo_color = app.colors.orange;
    this.maskY = 0.5;

    window.homesection = this;
  }

  onDown(x, y, e) {
    console.log('onDown', x);
    this.dragState.prevX = this.interaction.dragState.x;
    this.dragState.prevY = this.interaction.dragState.y;
    this.dragState.velocityX = 0;
    this.dragState.velocityY = 0;
    this.dragState.velocityX = 0;
    this.dragState.velocityY = 0;
  }

  onDrag(x, y, dx, dy) {
    // console.log('onDrag', x, dx);
  }

  onUp(x, y, { distance, duration }) {
    const changeX = Math.abs(this.dragState.velocityX);
    const changeY = Math.abs(this.dragState.velocityY);

    if (changeX < 40) {
      this.dragState.velocityX = 0;
      this.dragState.velocityY = 0;
      gsap.to(this, { offsetX: 0, offsetY: 0, ease: 'power3.out' });
    }
  }

  onSwipeLeft() {
    console.log('onSwipeLeft');
  }

  onSwipeRight() {
    console.log('onSwipeRight');
  }

  initDatGui() {
    const gui = new dat.GUI();
    const square = document.querySelector('#testsquare');

    gui.domElement.style.position = 'absolute';
    gui.domElement.style.top = '500px';
    gui.domElement.style.left = '0px';

    const update = () => {
      const r = new Euler(dat_gui_settings.rx, dat_gui_settings.ry, dat_gui_settings.rz, 'XYZ');
      const mat = new Matrix4().makeRotationFromEuler(r);

      square.style.transform = `translateZ(800px) matrix3d(${mat.elements.toString()}) translate`;
    };

    gui.add(dat_gui_settings, 'scale', 0, 2, 0.0001).onChange(() => {
      update();
    });

    gui.add(dat_gui_settings, 'x', 0, 1000, 0.0001).onChange(() => {
      update();
    });

    gui.add(dat_gui_settings, 'y', 0, 1000, 0.0001).onChange(() => {
      update();
    });

    gui.add(dat_gui_settings, 'z', 0, 1000, 0.0001).onChange(() => {
      update();
    });

    gui.add(dat_gui_settings, 'rx', 0, 1, 0.0001).onChange(() => {
      update();
    });

    gui.add(dat_gui_settings, 'ry', 0, 1, 0.0001).onChange(() => {
      update();
    });

    gui.add(dat_gui_settings, 'rz', 0, 1, 0.0001).onChange(() => {
      update();
    });

    gui.add(dat_gui_settings, 'zpos', -1, 1, 0.0001).onChange(() => {
      this.glScene.mountain.UIPosition.position.x = dat_gui_settings.zpos;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  prepareLoad() {
    const files = [];

    if (files.length > 0) {
      sectionLoader.addFiles(myName, files);
    }
  }

  initHeader({
    name,
    element,
    text_color,
    text_align
  }) {
    return new Promise((resolve) => {
      const headerScene = new GLHeader({
        name: `${name}_header`,
        element: element.querySelector('h1'),
        color: text_color,
        stroke_width: 3,
        background_color: false,
        scrolling: false,
        text_align
      });

      headerScene.events.subscribe('initialized', () => {
        console.log('HOME HEADER SCENE INITIALIZED', name);
      });

      const headerPromise = new Promise((headerResolve) => {
        this.glScene.initOverlay(headerScene, () => {
          headerResolve();
        });
      });

      Promise.all([
        headerPromise
      ]).then(() => {
        resolve([
          headerScene
        ]);
      });
    });
  }

  startup(callback) {
    console.log('HELLO START UP HOME');
    this.glScene = GLScene.getInstance();

    Promise.all([
      this.initHeader({
        name: 'what_we_do',
        element: this.elements.sectionWrapper.querySelector('#what_we_do'),
        text_color: app.colors.white,
        highlight_color: '#e1a52e',
        shadow_color: '#2a2f34',
        text_align: 'center'
      }),
      this.initHeader({
        name: 'what_weve_done',
        element: this.elements.sectionWrapper.querySelector('#what_weve_done'),
        text_color: app.colors.black,
        highlight_color: '#acacac',
        shadow_color: '#4d555c',
        text_align: 'center'
      })
    ]).then((data) => {
      console.log('NAMES', data);
      const [
        what_we_do,
        what_weve_done
      ] = data;
      this.overlays.what_we_do = what_we_do;
      this.overlays.what_weve_done = what_weve_done;

      if (callback) callback();
    });
  }

  // onMouseWheel(e) {
  //   // console.log('wheel', this.acc)
  //   const now = Date.now();
  //   const deltaTime = Math.min(16, now - lastMouseWheelTime);
  //   lastMouseWheelTime = now;
  //   this.acc += Math.sign(e.deltaY) * (deltaTime / 16);
  // }

  init(callback) {
    console.log('INITialized?', this.initialized);
    if (this.initialized) {
      if (callback) callback();
      return;
    }

    window.HOME = this;

    super.init();

    this.elements.options = this.elements.sectionWrapper.querySelector('.options');

    this.interaction = new Interaction({
      element: this.elements.sectionWrapper,
      onDown: this.onDown.bind(this),
      onDrag: this.onDrag.bind(this),
      onUp: this.onUp.bind(this),
      // onMouseWheel: this.onMouseWheel.bind(this)
    });

    // this.interaction.addListeners();

    this.resetDragState();
    this.dragState.prevX = this.interaction.dragState.x;

    this.glScene = GLScene.getInstance();

    const cameraElement = document.createElement('div');

    this.camera = {
      element: cameraElement
    };

    this.camera.element.style.transformStyle = 'preserve-3d';
    this.camera.element.style.pointerEvents = 'none';

    this.elements.sectionWrapper.appendChild(this.camera.element);
    this.elements.projects_billboard_label = document.querySelector('#project_billboards');
    this.elements.capabilities_billboard_label = document.querySelector('#capabilities_billboards');
    this.camera.element.appendChild(this.elements.projects_billboard_label);
    this.camera.element.appendChild(this.elements.capabilities_billboard_label);

    this.glScene.initScene('mountain', () => {
      if (callback) callback();
      this.wheel = 0;
      this.acc = 0;
      this.vel = 0;
      this.friction = 0.5;
    });

    const handleClick = (e) => {
      e.preventDefault();

      if (e.target.tagName === 'A') {
        const href = e.target.getAttribute('href');
        if (href.match('capabilities')) {
          document.body.dataset.modal = 'what_we_do';
        } else {
          document.body.dataset.modal = 'what_weve_done';
        }
        navigation.changeSection(href);
        sfx.playFx('sx_Wipe_toSubPage');
      }
    };

    const exit_button = this.elements.sectionWrapper.querySelector('.exit_button');
    exit_button.addEventListener('click', () => this.exitScene());

    const what_weve_done_enter_button = this.elements.sectionWrapper.querySelector('#what_weve_done button');
    what_weve_done_enter_button.addEventListener('click', () => this.transitionToWhatWeveDone());

    const what_we_do_enter_button = this.elements.sectionWrapper.querySelector('#what_we_do button');
    what_we_do_enter_button.addEventListener('click', () => this.transitionToWhatWeDo());

    this.elements.projects_billboard_label.addEventListener('click', handleClick);
    this.elements.capabilities_billboard_label.addEventListener('click', handleClick);

    this.resize();
  }

  enableOverlays(overlay) {
    for (let i = 0; i < this.overlays[overlay].length; i++) {
      this.overlays[overlay][i].buffermesh.visible = true;
    }
  }

  disableOverlays(overlay) {
    for (let i = 0; i < this.overlays[overlay].length; i++) {
      this.overlays[overlay][i].buffermesh.visible = false;
    }
  }

  hideOverlays(dir = 1) {
    const overlay_names = Object.keys(this.overlays);
    for (let i = 0; i < overlay_names.length; i++) {
      const overlay_name = overlay_names[i];
      for (let j = 0; j < this.overlays[overlay_name].length; j++) {
        const overlay = this.overlays[overlay_name][j];
        overlay.hide(dir).then(() => {
          console.log('OVERLAYS ARE HIDDEN');
        });
      }
    }
  }

  enterScene(whichscene, instant) {
    this.currScene = whichscene;

    this.interaction.removeListeners();

    mountain.transitionIntoScene(whichscene === 'what_we_do' ? 1 : 0, instant).then(() => {
      if (app.lazyloader) app.lazyloader.loadAll();

      this.elements.sectionWrapper.classList.add('in_scene');
      let billboards_element;
      switch (whichscene) {
        case 'what_we_do':
          billboards_element = this.elements.sectionWrapper.querySelector('#capabilities_billboards');
          break;
        case 'what_weve_done':
          billboards_element = this.elements.sectionWrapper.querySelector('#project_billboards');
          break;
        default:
      }

      [].slice.call(this.elements.sectionWrapper.querySelectorAll('.billboards.show')).forEach((el) => {
        el.classList.remove('show', 'delay');
      });

      billboards_element.classList.add('show');
      document.querySelector('#icon-oblio').classList.remove('showme');
    });

    // this.elements.sectionWrapper.classList.add('in_scene');
    this.glScene.hideOverlays();

    switch (whichscene) {
      case 'what_we_do':
        document.body.classList.add('what_we_do');
        document.body.classList.remove('what_weve_done');
        break;
      case 'what_weve_done':
        document.body.classList.remove('what_we_do');
        document.body.classList.add('what_weve_done');
        break;
      default:
    }

    this.disableOverlays('what_we_do');
    this.disableOverlays('what_weve_done');
  }

  exitScene() {
    sfx.playFx('sx_Back_toMain');

    this.resetDragState();

    this.currScene = null;
    this.interaction.dragging = false;
    // this.interaction.addListeners();

    mountain.transitionToAir();
    // this.elements.sectionWrapper.classList.remove('in_scene');

    document.body.classList.remove('what_weve_done');
    document.body.classList.remove('what_we_do');

    [].slice.call(this.elements.sectionWrapper.querySelectorAll('.billboards.show')).forEach((el) => {
      el.classList.remove('show', 'delay');
    });

    this.elements.sectionWrapper.classList.remove('show', 'in_scene');
    document.querySelector('#icon-oblio').classList.add('showme');
    this.showOverlays().then(() => {
      this.dragState.transitioning = false;
      super.show();
    });
  }

  resetDragState() {
    this.offsetX = 0;
    this.dragState.velocityX = 0;
    this.dragState.prevX = 0;
    this.offsetY = 0;
    this.dragState.velocityY = 0;
    this.dragState.prevY = 0;
  }

  showWhatWeDo(instant) {
    return new Promise((resolve) => {
      console.log('hello WHAT WE DO');
      this.enterScene('what_we_do', instant);
      this.currScene = 'what_we_do';
      resolve();
    });
  }

  /**
   * Animate the drag velocity in the direction of what we do, the update
   * function will handle the transition once the x offset passes the threshold
   */
  transitionToWhatWeDo() {
    if (this.offsetX === 0) {
      app.clicked = true;
      sfx.playFx('sx_WhatWeDo_LatestProjects_Click');
      gsap.to(this.dragState, { velocityX: 40, duration: 0.01, ease: 'power3.in' });
    }
  }

  /**
   * Animate the drag velocity in the direction of what weve done, the update
   * function will handle the transition once the x offset passes the threshold
   */
  transitionToWhatWeveDone() {
    if (this.offsetX === 0) {
      app.clicked = true;
      sfx.playFx('sx_WhatWeDo_LatestProjects_Click');
      gsap.to(this.dragState, { velocityX: -40, duration: 0.01, ease: 'power3.in' });
    }
  }

  showWhatWeveDone(instant) {
    return new Promise((resolve) => {
      console.log('hello WHAT WEVE DONE');
      // app.lazyloader.update();
      this.enterScene('what_weve_done', instant);
      this.currScene = 'what_weve_done';
      resolve();
    });
  }

  showOverlays() {
    return new Promise((resolve) => {
      const [
        doHeaderScene
      ] = this.overlays.what_we_do;
      const [
        doneHeaderScene
      ] = this.overlays.what_weve_done;

      doHeaderScene.from = new Vector2(-app.winWidth, 0);
      doneHeaderScene.from = new Vector2(app.winWidth, 0);

      this.glScene.showOverlays([
        doHeaderScene.name,
        doneHeaderScene.name
      ]).then(() => {
        resolve();
        this.interaction.addListeners();
      });
    });
  }

  showFromModal(scene, callback) {
    this.glScene.mountain.updatePaths();
    this.updateSubscription = this.glScene.events.subscribe('update', ({ deltaTime }) => this.update(deltaTime));
    this.onSceneUpdate = this.glScene.mountain.events.subscribe('UIPosition', (data) => updateBillboardLabel.call(this, data));

    this.glScene.show('mountain');

    // document.querySelector('#icon-oblio').classList.add('showme');
    // super.show();

    app.mainMenu.showPlayReel();

    this.currScene = scene;
    if (this.currScene) {
      // let billboards_element;
      switch (this.currScene) {
        case 'what_we_do':
          // billboards_element = this.elements.sectionWrapper.querySelector('#capabilities_billboards');
          this.showWhatWeDo(true);
          this.offsetX = app.winWidth;
          // this.transitionToWhatWeDo();
          break;
        case 'what_weve_done':
          // billboards_element = this.elements.sectionWrapper.querySelector('#project_billboards');
          this.showWhatWeveDone(true);
          this.offsetX = -app.winWidth;
          // this.transitionToWhatWeveDone();
          break;
        default:
      }
      mountain.nightTime(0);
      // billboards_element.classList.add('show');
    }

    if (callback) callback();
  }

  show(callback, fromMenu) {
    GLHeader.scene.background = null;
    this.dragState.transitioning = false;
    this.glScene.mountain.play();
    this.elements.sectionWrapper.classList.remove('hide');

    if (fromMenu) {
      if (this.currScene) {
        super.show();
        app.mainMenu.showPlayReel();

        let billboards_element;
        switch (this.currScene) {
          case 'what_we_do':
            billboards_element = this.elements.sectionWrapper.querySelector('#capabilities_billboards');
            break;
          case 'what_weve_done':
            billboards_element = this.elements.sectionWrapper.querySelector('#project_billboards');
            break;
          default:
        }

        billboards_element.classList.add('show', 'delay');
        document.body.classList.add(this.currScene);

        if (callback) callback();
      } else {
        this.showOverlays().then(() => {
          console.log('SHOWING FROM MENU');
          super.show();
          app.mainMenu.showPlayReel();

          if (callback) callback();
        });
      }

      return;
    }

    const fromModal = document.body.dataset.modal;
    if (fromModal && fromModal !== 'false') {
      this.showFromModal(fromModal, callback);
      document.body.dataset.modal = false;
      return;
    }

    this.glScene.mountain.updatePaths();
    this.updateSubscription = this.glScene.events.subscribe('update', ({ deltaTime }) => this.update(deltaTime));
    this.onSceneUpdate = this.glScene.mountain.events.subscribe('UIPosition', (data) => updateBillboardLabel.call(this, data));

    this.glScene.show('mountain');

    if (app.debug) {
      const square = document.createElement('div');
      square.id = 'testsquare';

      this.initDatGui();
    }

    window.setTimeout(() => {
      // if (app.clicked) {
        sfx.playFx('sx_Site_Load');
      // }
    }, 2000);

    mountain.intro().then(() => {
      document.querySelector('#icon-oblio').classList.add('showme');

      this.showOverlays().then(() => {
        super.show();

        app.mainMenu.showPlayReel();
        if (callback) callback();
      });
    });
  }

  hide(callback, dir = 1) {
    if (this.showtimeout) window.clearTimeout(this.showtimeout);

    [].slice.call(this.elements.sectionWrapper.querySelectorAll('.billboards.show')).forEach((el) => {
      el.classList.remove('show');
    });

    // console.log(`hide ${this.name}`);
    const hide_classes = dir < 0 ? ['hide'] : ['hide', 'up'];
    this.elements.sectionWrapper.classList.add(...hide_classes);
    document.querySelector('#mainHeader .close_button').classList.remove('show');

    if (!document.body.classList.contains('menuOpen')) {
      document.body.classList.remove('what_weve_done');
      document.body.classList.remove('what_we_do');
    }
    if (this.glScene.mountain.currPath) this.glScene.mountain.currPath.hide();
    this.hideOverlays(dir);

    if (callback && app.wipe) callback();
  }

  shutdown(callback) {
    this.onSceneUpdate.remove();
    super.shutdown(callback);
    this.updateSubscription.remove();
    this.glScene.mountain.pause();
  }

  // destroy() {
  //   console.log('HOME DESTROY', this.name);
  //   super.destroy();
  // }

  update(deltaTime) {
    if (this.interaction.dragging) {
      this.dragState.velocityX = (this.interaction.dragState.x - this.dragState.prevX) * (deltaTime / 16);
      this.dragState.prevX = this.interaction.dragState.x;

      this.dragState.velocityY = (this.interaction.dragState.y - this.dragState.prevY) * (deltaTime / 16);
      this.dragState.prevY = this.interaction.dragState.y;
    }

    this.offsetX += this.dragState.velocityX;

    const opacity = (1 - Math.abs(this.offsetX / (app.winWidth * 0.25))) * (1 - Math.abs(this.offsetY / (app.winHeight * 0.25)));
    // console.log('testing', opacity, this.dragState.transitioning);
    if (opacity < 0 && !this.dragState.transitioning) {
      if (Math.abs(this.offsetX) > Math.abs(this.offsetY)) {
        if (this.offsetX > 0) {
          console.log('SHOWING WHAT WE DO');
          this.showWhatWeDo();
        } else {
          console.log('SHOWING WHAT WE DONE');
          this.showWhatWeveDone();
        }
      } else {
        console.log('SHOWING ENTER SCENE');
        this.enterScene();
      }
      this.dragState.transitioning = true;
      this.interaction.dragging = false;
    }

    if (mountain.currState === 1) {
      if (!mountain.transitioning) mountain.maskOrigin.set(this.offsetX / app.winWidth + 0.5, this.maskY);

      mountain.motion.blurOffset = (1 - opacity) * 0.125;
      mountain.motion.blurOriginOffset.set(mountain.maskOrigin.x * 2 - 1, mountain.maskOrigin.y * 2 - 1, 0).sub(mountain.motion.blurOrigin);
      // mountain.motion.blurOffset = 0;
      // mountain.motion.blurOriginOffset.set(0, 0, 0);
      // console.log(mountain.motion.blurOriginOffset.x, mountain.motion.blurOriginOffset.y);
    } else {
      // console.log(mountain.motion.blurOriginOffset.x, mountain.motion.blurOriginOffset.y);
      // mountain.motion.blurOffset *= 0.75;
      // mountain.motion.blurOriginOffset.multiplyScalar(0.75);
    }

    // console.log('tester', mountain.currState, mountain.transitioning, this.glScene.postQuad.material.uniforms.blurAmt.value, this.glScene.postQuad.material.uniforms.blurOrigin.value);
    if (opacity > -1) {
      this.elements.options.style.transform = `translateX(${this.offsetX}px) translateY(${this.offsetY}px)`;
      this.elements.options.style.opacity = opacity;
    }

    // mountain.scroll(this.acc);
    // this.acc = 0;

    if (this.overlays.what_we_do) {
      for (let i = 0; i < this.overlays.what_we_do.length; i++) {
        const whatwedoscene = this.overlays.what_we_do[i];
        const whatwevedonescene = this.overlays.what_weve_done[i];

        whatwedoscene.scroll_state.position.x = this.offsetX;
        whatwevedonescene.scroll_state.position.x = this.offsetX;
        whatwedoscene.buffermesh.material.uniforms.opacity.value = opacity;
        whatwevedonescene.buffermesh.material.uniforms.opacity.value = opacity;
      }
    }
  }

  resize() {
    _heightHalf = app.winHeight * 0.5;
    _widthHalf = app.winWidth * 0.5;

    window.requestAnimationFrame(() => {
      if (app.winWidth / app.winHeight > 4 / 5) {
        this.maskY = 0.5;
      } else {
        const rect = document.querySelector('#logo_wrapper').getBoundingClientRect();
        this.maskY = 1 - (rect.y + rect.height * 0.5) / app.winHeight;
        this.glScene.mountain.maskY = this.maskY;
      }
    });

    if (this.elements && this.elements.sectionWrapper) this.elements.sectionWrapper.style.width = `${app.winWidth}px`;
    if (this.elements && this.elements.sectionWrapper) this.elements.sectionWrapper.style.height = `${app.winHeight}px`;

    if (this.camera && this.camera.element) this.camera.element.style.width = `${app.winWidth}px`;
    if (this.camera && this.camera.element) this.camera.element.style.height = `${app.winHeight}px`;
  }
}

export default {
  getInstance() {
    instance = instance || new Home();
    return instance;
  },
};
