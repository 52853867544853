import Navigation from '../utils/Navigation';
import findAncestor from '../utils/findAncestor';

const navigation = Navigation.getInstance();

let instance;

class Footer {
  init() {
    if (this.initialized) return;
    this.initialized = true;

    const wrapper = document.querySelector('#footer');
    this.elements = {
      wrapper
    };

    wrapper.addEventListener('click', (e) => {
      if (e.target.tagName === 'A' && e.target.getAttribute('target') === '_blank' || findAncestor(e.target, 'address')) return;

      e.preventDefault();

      /**
       * If a link was clicked, change the section
       */
      const url = e.target.tagName === 'A' ? e.target.getAttribute('href') : false;

      if (url) {
        navigation.changeSection(url);
      }
    });
  }
}

export default {
  getInstance() {
    instance = instance || new Footer();
    return instance;
  }
};
