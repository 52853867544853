/* eslint-disable no-param-reassign */
import player from './player';

function onVideoEnd(vid_obj) {
  vid_obj.wrapperElement.classList.remove('playing');
}

// eslint-disable-next-line class-methods-use-this
function onPlay(vid_obj) {
  const keys = Object.keys(this.playingVideos);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    this.playingVideos[key].pause();
  }

  vid_obj.wrapperElement.classList.add('playing');
  vid_obj.wrapperElement.classList.remove('loading');
  this.playingVideos[vid_obj.vid_id] = vid_obj.player;
}

function onPause(vid_obj) {
  delete this.playingVideos[vid_obj.vid_id];
}

class VideoManager {
  constructor(wrapper) {
    this.subscriptions = [];
    this.videos = [];
    this.playingVideos = [];
    this.wrapper = wrapper;
  }

  init() {
    this.prepareVideos();
    this.loadGIFs();
  }

  // eslint-disable-next-line class-methods-use-this
  initVideo(vid_obj) {
    console.log('SET UP VIDEO PLAYER', vid_obj.poster);
    return new Promise((resolve) => {
      const playerOptions = {
        autoplay: 1,
        videoSrc: vid_obj.vid_id,
        poster: vid_obj.poster,
        rel: 0,
        showinfo: 1,
        modestbranding: 1,
        pauseoverlay: 0
      };

      const mobile = window.innerWidth <= 414 || window.innerHeight <= 414;

      // var video = document.createElement('video');
      // vid_obj.wrapperElement.querySelector('.videoplayer').appendChild(video);

      player.create(vid_obj.playerElement, {
        type: 'youtube',
        videoSrc: playerOptions.videoSrc
      }).then((videoPlayer) => {
        vid_obj.player = videoPlayer;

        this.subscriptions.push(vid_obj.player.events.subscribe('ended', () => onVideoEnd.call(this, vid_obj)));
        this.subscriptions.push(vid_obj.player.events.subscribe('play', () => onPlay.call(this, vid_obj)));
        this.subscriptions.push(vid_obj.player.events.subscribe('pause', () => onPause.call(this, vid_obj)));
        if (mobile) vid_obj.player.events.publish('pause', { paused: true });
        resolve();
      });
    });
  }

  loadGIFs() {
    const gifs = this.wrapper.querySelectorAll('.gif');

    const onplaying = (e) => {
      e.target.removeEventListener('playing', onplaying);
      e.target.parentElement.classList.add('loaded');
    };

    for (let i = 0; i < gifs.length; i++) {
      const gif = gifs[i];
      gif.addEventListener('playing', onplaying);
      gif.play();
    }
  }

  prepareVideos() {
    // const videos = [];
    const vids = this.wrapper.querySelectorAll('.videoplayer');

    for (let i = vids.length - 1; i >= 0; i--) {
      const vid_obj = {
        vid_id: vids[i].dataset.vid_id,
        type: vids[i].getAttribute('data-type'),
        playerElement: vids[i],
        wrapperElement: vids[i].parentElement
      };

      this.initVideo(vid_obj).then(() => {
        console.log('PLAYER CREATED');
        const getTouchStartListener = (vo) => () => {
          vo.moved = false;
        };

        const getTouchMoveListener = (vo) => () => {
          vo.moved = true;
        };

        const getTouchEndListener = (vo) => () => {
          if (vo.moved === false && vo.player) {
            if (vo.player && !vo.wrapperElement.classList.contains('playing')) {
              vo.player.play();
            } else {
              // init vid
              vo.wrapperElement.classList.add('loading');

              this.initVideo(vo).then(() => {
                console.log('PLAYER CREATED TOUCHEND', vo.moved);
                vo.player.play();
              });
            }
          }
        };

        const getClickListener = (vo) => () => {
          if (vo.player) {
            vo.wrapperElement.classList.add('playing');
            vo.wrapperElement.classList.remove('loading');
            vo.player.play();
          } else {
            // // init vid
            // console.log('CLICK LISTENER!!');
            // vo.wrapperElement.classList.add('loading');

            // this.initVideo(vo).then(() => {
            //   console.log('PLAYER CREATED CLICK');
            // });
          }
        };

        vid_obj.clickListener = getClickListener(vid_obj);
        vid_obj.touchstartListener = getTouchStartListener(vid_obj);
        vid_obj.touchmoveListener = getTouchMoveListener(vid_obj);
        vid_obj.touchendListener = getTouchEndListener(vid_obj);

        vid_obj.wrapperElement.addEventListener('touchstart', vid_obj.touchstartListener);
        vid_obj.wrapperElement.addEventListener('touchmove', vid_obj.touchmoveListener);
        vid_obj.wrapperElement.addEventListener('touchend', vid_obj.touchendListener);
        vid_obj.wrapperElement.addEventListener('click', vid_obj.clickListener);
      });

      this.videos.push(vid_obj);
    }
  }

  destroy() {
    while (this.subscriptions.length > 0) this.subscriptions.pop().remove();

    const keys = Object.keys(this.playingVideos);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      this.playingVideos[key].pause();
    }

    while (this.videos.length > 0) {
      const vid_obj = this.videos.pop();

      vid_obj.wrapperElement.removeEventListener('touchstart', vid_obj.touchstartListener);
      vid_obj.wrapperElement.removeEventListener('touchmove', vid_obj.touchmoveListener);
      vid_obj.wrapperElement.removeEventListener('touchend', vid_obj.touchendListener);
      vid_obj.wrapperElement.removeEventListener('click', vid_obj.clickListener);
    }

    this.subscriptions = null;
    this.videos = null;
    this.wrapper = null;
  }
}

export default VideoManager;
