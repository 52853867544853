
import DynamicMatCap from './DynamicMatCap';

class GLMountainMatCap extends DynamicMatCap {
  lightsOut(duration) {
    var settings = {
      pointLights: [
        {
          color: 0xFFFFFF,
          intensity: 0,
          decay: 0,
          decayDistance: 0,
          distance: 7,
          lat: 0.2,
          long: 0.2
        },
        {
          color: 0xFFFFFF,
          intensity: 0,
          decay: 0,
          decayDistance: 0,
          distance: 7,
          lat: 0.3 - Math.PI,
          long: 0.25
        }
      ],
      metalness: 0.75,
      roughness: 0.5,
      innerColor: 0x000000,
      outerColor: 0x000000,
      azimuthColor: 0x000000,
      zenithColor: 0x000000,
      overlayAmt: 0
    }
    this.transition(settings, duration);
  }
  nightTime(duration) {
    var settings = {
      pointLights: [
        {
          color: 0x0000FF,
          intensity: 1,
          decay: 0,
          decayDistance: 0,
          distance: 7,
          lat: Math.PI,
          long: 0
        },
        {
          color: 0xa4b4d8,
          intensity: 0.25,
          decay: 0,
          decayDistance: 0,
          distance: 2,
          lat: 0.3,
          long: 0.25
        }
      ],
      metalness: 0.75,
      roughness: 0.5,
      innerColor: 0x000000,
      outerColor: 0x333333,
      azimuthColor: 0x333333,
      zenithColor: 0x1c1c1c,
      overlayAmt: 1
    }
    this.transition(settings, duration);
  }
  dayTime(duration) {
    var settings = {
      pointLights: [
        {
          color: 0xFFFFFF,
          intensity: 0.85,
          decay: 0,
          decayDistance: 0,
          distance: 7,
          lat: 0.2,
          long: 0.2
        },
        {
          color: 0xFFFFFF,
          intensity: 0.85,
          decay: 0,
          decayDistance: 0,
          distance: 7,
          lat: 0.3 - Math.PI,
          long: 0.25
        }
      ],
      metalness: 0,
      roughness: 0.75,
      innerColor: 0x000000,
      outerColor: 0x8f8f8f,
      azimuthColor: 0xFFFFFF,
      zenithColor: 0xe9e9e9,
      overlayAmt: 1
    }
    this.transition(settings, duration);
  }
}

export default GLMountainMatCap;