varying vec2 vUv;
uniform sampler2D map;
uniform vec3 plane;
uniform vec3 color;
varying vec3 pos;
varying float opacity;

// varying float w;

void main() {
    // vec3 color = vec3(1., 1., 1.);

    // vec3 diff = vec3(1., 1., 0.);
    vec3 p1 = vec3(0., 0., 1.);
    vec3 p2 = vec3(1., 10., 100.);
    // vec3 normal = normalize(cross(plane1, plane2));
    // vec3 normal = normalize(cross(dFdx(pos), dFdy(pos)));
    vec3 normal = normalize(cross(dFdx(pos), dFdy(pos)));

    vec3 orange = vec3(245. / 255., 179. / 255., 49. / 255.);
    // gl_FragColor = text_color;ds
    
    // float test = abs(dot(normal, plane)) / (length(normal) * length(plane));
    float test = abs(dot(normal, normalize(p1)));
    
    // gl_FragColor = mix(color, vec4(orange, 1.), test);
    // gl_FragColor = vec4(color, test);
    gl_FragColor = vec4(color, test);
    // gl_FragColor = vec4(orange, opacity);
    // gl_FragColor = vec4(orange, 1.);
}