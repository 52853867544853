varying vec2 vUv;

uniform sampler2D postTexture;
uniform vec2 u_resolution;
uniform vec2 blurOrigin;
uniform float blurAmt;

uniform float time;

const int num_iter = 12;
const float reci_num_iter_f = 1.0 / float(num_iter);

float random(vec3 scale,float seed){return fract(sin(dot(gl_FragCoord.xyz+seed,scale))*43758.5453+seed);}

float sat( float t )
{
    return clamp( t, 0.0, 1.0 );
}

float linterp( float t ) {
    return sat( 1.0 - abs( 2.0*t - 1.0 ) );
}

float remap( float t, float a, float b ) {
    return sat( (t - a) / (b - a) );
}

vec3 spectrum_offset( float t ) {
    vec3 ret;
    float lo = step(t,0.5);
    float hi = 1.0-lo;
    float w = linterp( remap( t, 1.0/3.0, 2.0/3.0 ) );
    // ret = vec3(lo,1.0,hi) * vec3(1.0-w, w, 1.0-w);
    // ret = vec3(1.0,lo,hi) * vec3(w, 1.0-w, 1.0-w);
    ret = vec3(hi,lo,1.0) * vec3(1.0-w, 1.0-w, w);

    return pow( ret, vec3(1.0/2.2) );
}

void main()
{
    vec2 screenRatio = vec2(u_resolution.x/u_resolution.y, 1.);

	vec3 sumcol = vec3(0.0);
	vec3 sumw = vec3(0.0);
	float total=0.0;
	// vec4 total=vec4(0.0);
	vec2 toCenter=blurOrigin-vUv*u_resolution;
	float offset=random(vec3(12.9898,78.233,151.7182),0.0);
    for ( int i=0; i<num_iter;++i ) {
        float t = float(i) * reci_num_iter_f;
		float percent=(float(i)+offset)/float(num_iter);
		vec3 w = mix(spectrum_offset( t ), vec3(1.), 0.5);
		sumw += w;
		sumcol += w*texture2D(postTexture,vUv+toCenter*percent*blurAmt/u_resolution).rgb;
	}
	gl_FragColor = vec4(sumcol/sumw, 1.0);

}
