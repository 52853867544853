import app from '../global';

/* eslint-disable no-param-reassign */
// eslint-disable-next-line class-methods-use-this
function wrapText(context, text, x, y, maxWidth, lineHeight, maxHeight, align) {
  const words = text.replace('&AMP;', '&').split(' '); // since i'm using 'innerHTML' instead of text content, ampersands are changing to &amp;
  let line = '';
  let width = 0;

  const lines = [];

  for (let n = 0; n < words.length; n++) {
    const testLine = `${line}${words[n]} `;
    const metrics = context.measureText(testLine.trim().replace('&NBSP;', ' '));
    const testWidth = metrics.width;
    console.log('WORD', testLine, words[n]);

    if (testWidth > maxWidth && n > 0 && y < maxHeight || words[n].indexOf('<BR>') > 0) {
      context.strokeText(line, x, y);

      if (words[n].indexOf('<BR>') > 0) {
        const linebreak = words[n].split('<BR>');
        linebreak.shift();
        let rest = linebreak.join('<BR>');
        words.splice(n + 1, 0, rest);
        console.log('SPLICED', words);
        const line = testLine.split('<BR>')[0];
        // console.log('testLine', testLine, testLine.split('<BR>')[0], words[n]);
        // const l = testLine.split('<BR>');
        let line_x = 0;
        if (align !== 'left') {
          const line_metrics = context.measureText(line.trim().replace('&NBSP;', ' '));
          const line_testWidth = line_metrics.width;
          line_x = maxWidth - line_testWidth;

          if (align === 'center') line_x *= 0.5;
        }

        lines.push({
          line,
          x: line_x,
          y
        });
        y += lineHeight;

        // width = Math.max(context.measureText(line.trim()).width, width);
        // line = `${words[n]} `;
        // y += lineHeight;
      } else if (words[n].indexOf('\u00AD') > 0) {
        const hyphenated = words[n].split('\u00AD');
        hyphenated[0] += '-';
        words.splice(n, 1, ...hyphenated);
        n--;
      } else if (words[n - 1].indexOf('\u200B') !== -1) {
        // check for &emsp14; (four-per-em space) which I'm using
        // for titles where there is a prefered space to break on
        // const spaced = words[n - 1].split('\u2005');
        // words.splice(n - 1, 1, ...spaced);
        n--;

        line = testLine.split('\u200B')[0];

        let line_x = 0;
        if (align !== 'left') {
          const line_metrics = context.measureText(line.trim().replace('&NBSP;', ' '));
          const line_testWidth = line_metrics.width;
          line_x = maxWidth - line_testWidth;

          if (align === 'center') line_x *= 0.5;
        }

        lines.push({
          line,
          x: line_x,
          y
        });
        width = Math.max(context.measureText(line.trim().replace('&NBSP;', ' ')).width, width);
        line = `${words[n]} `;
        y += lineHeight;
      } else {
        let line_x = 0;
        if (align !== 'left') {
          const line_metrics = context.measureText(line.trim().replace('&NBSP;', ' '));
          const line_testWidth = line_metrics.width;
          line_x = maxWidth - line_testWidth;

          if (align === 'center') line_x *= 0.5;
        }

        lines.push({
          line,
          x: line_x,
          y
        });
        width = Math.max(context.measureText(line.trim().replace('&NBSP;', ' ')).width, width);
        line = `${words[n]} `;
        y += lineHeight;
      }
    } else if (testWidth > maxWidth && testLine.indexOf('\u00AD') > 0) {
      const hyphenated = words[n].split('\u00AD');
      hyphenated[0] += '-';
      words.splice(n, 1, ...hyphenated);
      n--;
    } else {
      line = testLine;
      width = Math.max(context.measureText(line.trim().replace('&NBSP;', ' ')).width, width);
    }
  }

  width = Math.max(context.measureText(line.trim().replace('&NBSP;', ' ')).width, width);

  let line_x = 0;
  if (align !== 'left') {
    const line_metrics = context.measureText(line.trim().replace('&NBSP;', ' '));
    const line_testWidth = line_metrics.width;
    line_x = maxWidth - line_testWidth;

    if (align === 'center') line_x *= 0.5;
  }

  console.log('line', line);
  lines.push({
    line: line.replace('&NBSP;', ' '),
    x: line_x,
    y
  });

  const final_width = align === 'left' ? width : maxWidth;
  return {
    lines,
    width: final_width,
    height: y
  };
}

function getCircleHeader(element) {
  if (element) {
    return element.getBoundingClientRect();
  }
}

function getTextHeader(h1, canvas, strokeWidth, align = 'left') {
  if (h1) {
    // if (h1.children.length > 0) return getLogoHeader(h1, canvas, color);

    const w = h1.offsetWidth;
    const h = h1.offsetHeight;
    canvas.width = w * 2;
    canvas.height = w * 2 * (h / w);

    const ctx = canvas.getContext('2d');

    const fontSize = window.getComputedStyle(h1).fontSize.split('px')[0];
    ctx.font = `900 ${fontSize * 2}px "museo-sans"`;

    const text = ctx.measureText('Hello world'); // TextMetrics object

    const maxWidth = w * 2;
    const maxHeight = h * 2;
    const lineHeight = 0.95 * (fontSize * 2);
    // console.log('LINE HEIGHT', maxHeight / lineHeight);
    // maxHeight = Math.ceil(maxHeight / lineHeight) * lineHeight;

    const lines = wrapText(ctx, h1.innerHTML.toUpperCase(), 0, text.actualBoundingBoxAscent + (lineHeight - (text.actualBoundingBoxAscent + text.actualBoundingBoxDescent)) * 0.5, maxWidth, lineHeight, maxHeight, align);
    const newwidth = lines.width;
    // ctx.canvas.width = newwidth;

    canvas.width = newwidth;

    ctx.fillStyle = '#000000';
    ctx.rect(0, 0, canvas.width, canvas.height);
    ctx.fill();
    ctx.lineWidth = strokeWidth;
    ctx.strokeStyle = '#FFFFFF';
    ctx.font = `900 ${fontSize * 2}px "museo-sans"`;

    for (let i = 0; i < lines.lines.length; i++) {
      const line = lines.lines[i];
      ctx.strokeText(line.line, line.x, line.y);
    }

    // canvas.style.position = 'absolute';

    const rect = h1.getBoundingClientRect();
    console.log(rect);

    // canvas.style.top = `${rect.top}px`;
    // canvas.style.left = `${rect.left}px`;

    rect.height = rect.width * (canvas.height / newwidth);

    return rect;
  }

  return false;
}

function getLogoHeader(h1, canvas, color) {
  if (h1) {
    const rect = h1.getBoundingClientRect();
    const w = rect.height;
    const h = rect.height;

    canvas.width = w * 2;
    canvas.height = w * 2 * (h / w);

    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#000000';
    ctx.rect(0, 0, canvas.width, canvas.height);
    ctx.fill();

    ctx.lineWidth = 6;
    ctx.strokeStyle = '#FFFFFF';

    canvas.style.position = 'absolute';

    canvas.style.top = `${rect.top}px`;
    canvas.style.left = `${rect.left}px`;

    canvas.style.opacity = 0.5;

    [].slice.call(document.body.querySelectorAll('#icon-oblio circle')).forEach((circ) => {
      const r = Number(circ.getAttribute('r'));
      ctx.beginPath();
      ctx.ellipse(canvas.width * 0.5, canvas.width * 0.5, (r / 100) * canvas.width, (r / 100) * canvas.width, Math.PI / 4, 0, 2 * Math.PI);
      ctx.stroke();
    });

    rect.width = rect.height;

    return rect;
  }

  return false;
}

function loadFonts(h1) {
  return new Promise((resolve, reject) => {
    if (h1) {
      const fontSize = window.getComputedStyle(h1).fontSize.split('px')[0];
      const f = `900 ${fontSize * 2}px "museo-sans"`;

      document.fonts.load(f).then(resolve);
    } else {
      resolve();
    }
  });
}

export default {
  getTextHeader,
  getLogoHeader,
  getCircleHeader,
  loadFonts
};
