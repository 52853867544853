import loadScript from '../../../utils/loadScript';

/* eslint-disable linebreak-style */
let scriptLoaded = false;

const player_proto = {
  play: function play() {
    this.isPaused = false;
    this.player.play().then(() => {
      console.log('then');
      // this.isPaused = false;
    }).catch((err) => {
      console.log(err);
    });
  },
  pause: function pause(resume) {
    if (!resume) this.isPaused = true;

    this.player.pause().then(() => {
      // this.isPaused = true;
    });
  },
  paused: function paused() {
    return this.isPaused;
  },
  setVolume: function setVolume(v) {
    this.volume = v;
    this.player.setVolume(v);
  },
  getVolume: function getVolume() {
    return this.volume;
  },
  getCurrentTime: function getCurrentTime() {
    this.player.getCurrentTime().then((ct) => {
      this.currentTime = ct;
    });
    return this.currentTime || 0;
  },
  setCurrentTime: function setCurrentTime(ct) {
    this.currentTime = ct * this.duration;
    this.player.setCurrentTime(this.currentTime);
  },
  getDuration: function getDuration() {
    this.player.getDuration().then((d) => {
      if (this.duration !== d) {
        this.duration = d;
        this.onStateChange('durationchange');
      }
    });
    return this.duration || 0;
  },
  fullscreen: function fullscreen() {
    const iframe = document.getElementById(this.video_el.id);

    const fullscreenFn = iframe.requestFullscreen
      || iframe.webkitRequestFullscreen
      || iframe.mozRequestFullscreen
      || iframe.msRequestFullscreen
      || function fsFn() {
        console.warning('This browser does not support fullscreen');
      };

    fullscreenFn.call(iframe);
  },
  onStateChange: function onStateChange(eventName, e) {
    switch (eventName) {
      case 'play':
        this.events.publish('play', { playing: true });
        break;
      case 'pause':
        this.events.publish('pause', { paused: true });
        break;
      case 'ended':
        this.events.publish('ended', { ended: true });
        break;
      case 'timeupdate':
        this.events.publish('timeupdate', { currentTime: this.getCurrentTime(), duration: this.getDuration() });
        break;
      case 'durationchange':
        this.events.publish('durationchange', { duration: this.getDuration() });
        break;
      case 'volumechange':
        this.events.publish('volumechange', { volume: this.getVolume() });
        break;
      default:
        console.log(eventName);
    }
  },
  getRatio: function getRatio() {
    return this.ratio;
  },
  destroy: function destroy() {
    // this.stop();
    this.player.destroy();
  },
  getPlayerState: function getPlayerState() {
    // TODO return player state (paused playing ended)
    /*
       -1 – unstarted
      0 – ended
      1 – playing
      2 – paused
      3 – buffering
      5 – video cued
    */
    return new Promise((resolve, reject) => {
      let state;
      let ready = false;

      this.player.getPaused().then(paused => {
        ready = state !== 'undefined';
        let playing_state = ready ? state : 1;
        state = paused ? 2 : playing_state;
        if (ready) resolve(state);
      });

      this.player.getEnded().then(ended => {
        ready = state !== 'undefined';
        let playing_state = ready ? state : 1;
        state = ended ? 0 : playing_state;
        if (ready) resolve(state);
      });
    });
  }
}

function onError(e) {
  console.log('YOUTUBE ERROR', e);
}

// function loadScript(url, callback) {
//   var script = document.createElement( "script" )
//   script.type = "text/javascript";
//   if(script.readyState) {  //IE
//     script.onreadystatechange = function() {
//       if ( script.readyState === "loaded" || script.readyState === "complete" ) {
//         script.onreadystatechange = null;
//         callback();
//       }
//     };
//   } else {
//     script.onload = () => {
//       callback();
//     };
//   }

//   script.src = url;
//   document.getElementsByTagName( "head" )[0].appendChild( script );
// }

function loadOEmbed(vID, options) {
  return new Promise((resolve, reject) => {
    const url = `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${vID}`;

    const xhr = new XMLHttpRequest();
    xhr.addEventListener('load', (e) => {
      const json = JSON.parse(e.target.responseText);
      const background = 1;

      resolve(json.html.replace(/(src="(?:[^\\"]+|\\.)*(?="))/, `$1?background=${background}&loop=0&autoplay=0`));
    });
    xhr.open('GET', url);
    xhr.send();
  });
}

function addEvents() {
  const events = [
    'play',
    'pause',
    'ended',
    'timeupdate',
    'durationchange',
    // 'progress',
    // 'seeked',
    // 'texttrackchange',
    // 'cuechange',
    // 'cuepoint',
    'volumechange',
    // 'error',
    // 'loaded'
  ];

  for (let i = 0; i < events.length; i++) {
    const eventName = events[i];
    this.player.on(eventName, (e) => this.onStateChange(eventName, e));
  }
}

function initPlayer() {
  return new Promise((resolve, reject) => {
    scriptLoaded = true;

    const options = {
      id: this.options.videoSrc
      // controls: this.options.controls
      // portrait: this.options.portrait,
      // title: this.options.title,
      // byline: this.options.byline,
      // background: 1,
      // loop: 0
    };

    this.player = new Vimeo.Player(this.video_el, options);
    this.player.ready().then(() => {
      console.log('HELLO ready');
      addEvents.call(this);
      this.setVolume(1);
      if (this.options.autoplay) this.play();
      resolve(this);
    });
  });
}

function load() {
  return new Promise((resolve, reject) => {
    this.isPaused = false;
    const vID = this.options.videoSrc;

    loadOEmbed(vID, this.options).then((html) => {
      const div = document.createElement('div');
      div.innerHTML = html;
      const iframe = div.querySelector('iframe');

      [].slice.call(this.video_el.attributes).forEach((item) => {
        iframe.setAttribute(item.name, item.value);
      });

      this.video_el.replaceWith(iframe);
      this.video_el = iframe;

      if (scriptLoaded) {
        initPlayer.call(this).then(() => resolve(this));
      } else {
        loadScript('https://player.vimeo.com/api/player.js')
          .then(() => initPlayer.call(this))
          .then(() => resolve(this));
      }
    });
  });
}

export default {
  proto: player_proto,
  load
};
