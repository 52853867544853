varying vec2 vUv;
uniform vec3 azimuthA;
uniform vec3 zenithA;
uniform vec3 azimuthB;
uniform vec3 zenithB;

uniform vec2 maskOrigin;
uniform vec2 maskVec;
uniform vec2 resolution;


float isMasked() {
	float feather = 0.001;
	vec2 screenUV = gl_FragCoord.xy/resolution.xy;
	vec2 pxVec = screenUV-maskOrigin;
	float dist = length(dot(pxVec, normalize(maskVec))*normalize(maskVec));
	float dir = (step(0., dot(normalize(pxVec), normalize(maskVec)))*2.)-1.;
	return smoothstep(-feather, feather, dist*dir);
}

void main() {

	float maskVal = isMasked();
	gl_FragColor = vec4(mix(mix(azimuthA, azimuthB, maskVal), mix(zenithA, zenithB, maskVal), smoothstep(0.55, 0.65, vUv.y)), 1.);

}
