varying vec2 vUv;
uniform sampler2D map;
uniform vec3 plane;
varying vec3 pos;
uniform vec3 highlight_color;
uniform vec3 shadow_color;
uniform float u_imageRatio;
uniform float u_circle;
uniform float u_transparent;
uniform float opacity;

// varying float w;

void main() {
    float x_scaled = vUv.x / u_imageRatio;
    x_scaled += (u_imageRatio - 1.) * 0.25;

    vec4 map_color = texture2D( map, vec2(x_scaled, vUv.y) );

    vec3 normal = normalize(cross(dFdx(pos), dFdy(pos)));

    vec3 color = mix(shadow_color, highlight_color, map_color.r);

    // check the normal of the plane, if 0., it's facing perfectly forward
    float test = abs(dot(normal, plane)) / (length(normal) * length(plane));

    float circle_mask = step(0.5, distance(vUv, vec2(0.5, 0.5)));
    float mask = (1. - mix(0., circle_mask, u_circle));
    // float alpha = mix((1. - map_color.r) * 0.5 + 0.5, map_color.r, u_transparent) + test * 0.25;
    float alpha = (mix(1., map_color.r, u_transparent) + test * 0.25) * opacity;
    // float alpha = map_color.r + test * 0.25;

    gl_FragColor = vec4(color, alpha * mask);
    // gl_FragColor = vec4(1., 0., 0., 0.5);
}