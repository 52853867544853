import Section from './Section';
import SectionLoader from '../utils/SectionLoader';
import GLHeader from '../widgets/webgl/GLHeader';
import GLScene from '../widgets/webgl/GLScene';
import Interaction from '../utils/Interaction';
// import getHeader from '../widgets/getheader';
import app from '../global';

const myName = 'Error';
const sectionLoader = SectionLoader.getInstance();

let instance;

class Error extends Section {
  constructor() {
    super(myName);
    this.background_color = app.colors.white;
    this.logo_color = app.colors.orange;
  }

  // init(callback) {
  //   super.init();

  //   document.querySelector('.send_us_an_email').addEventListener('click', (e) => {
  //     e.preventDefault();

  //     window.location.href = 'mailto:info@oblio.io';
  //   });

  //   if (callback) callback();
  // }

  startup(callback) {
    this.glScene = GLScene.getInstance();

    this.headerScene = new GLHeader({
      name: 'error',
      element: this.elements.sectionWrapper.querySelector('h1'),
      color: app.colors.orange,
      background_color: this.background_color
    });

    this.headerScene.events.subscribe('initialized', () => {
      console.log('SECTION SCENE INITIALIZED');
    });

    // this.glScene.initScene(this.headerScene, () => {
    //   if (callback) callback();
    // });
    this.glScene.initOverlay(this.headerScene, () => {
      if (callback) callback();
    });
  }
//
  show(callback) {
    const subscription = this.headerScene.events.subscribe('isIn', () => {
      console.log('SECTION SCENE IS IN');
      subscription.remove();

      this.showtimeout = window.setTimeout(() => {
        this.elements.sectionWrapper.classList.remove('changesection');
        this.elements.sectionWrapper.classList.remove('hide');
        this.elements.sectionWrapper.classList.remove('up');
        this.elements.sectionWrapper.classList.add('show');
        app.mainMenu.showLogo(this.logo_color);
  
        // window.setTimeout(() => {
        //   const footer = document.querySelector('#footer');
        //   footer.classList.add('show');
        // }, 500);
  
        if (document.body.classList.contains('modal')) document.querySelector('#mainHeader .close_button').classList.add('show');
      }, 250);
  
      // super.show();
      if (callback) callback();
    });

    // this.glScene.show(this.headerScene);
    this.glScene.showOverlays([this.headerScene.name]);
  }

  hide(callback, dir) {
    super.hide(callback, dir);
    document.body.classList.remove('modal');
  }

  destroy() {
    super.destroy();
    // if (this.headerScene) {
    //   this.headerScene.destroy();
    //   this.headerScene = null;
    // }
  }

  // eslint-disable-next-line class-methods-use-this
  prepareLoad() {
    const files = [];

    if (files.length > 0) {
      sectionLoader.addFiles(myName, files);
    }
  }
}

export default {
  getInstance() {
    instance = instance || new Error();
    return instance;
  },
};
