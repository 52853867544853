varying vec2 vUv;
varying vec3 vPosition;

uniform sampler2D heightMap;
uniform float mountainPosition;
uniform float time;


void main() {
	// vPosition = vec3( position );
	vUv = uv;

	vec3 transformed = vec3( position );

	vec4 mPosition = vec4( transformed, 1.0 );
	mPosition = modelMatrix * mPosition;
	vPosition = vec3( mPosition );

	vec4 mvPosition = vec4( transformed, 1.0 );
	mvPosition = modelViewMatrix * mvPosition;
	// mvPosition.z += cnoise(vec3(normalPos*5., time))*2.5*pointLock*(step(0.75, vUv.x)); 

    // float mountainX = mvPosition

	gl_Position = projectionMatrix * mvPosition;
}
