import { Howl, Howler } from 'howler';
import siteFX from '../../../assets/audio/siteFX';
import app from '../global';

const audiosprites = {
  siteFX
};

let initialized = false;
let siteIndex = 0;
let currentSound = null;
let muted = false;
let paused = false;

const onclick = () => {
  app.clicked = true;
  document.documentElement.removeEventListener('click', onclick);
};
document.documentElement.addEventListener('click', onclick);

const siteFXPool = [];
const sounds = {};
const howls = {};
const numObjs = 5;

// Howler global settings
Howler.mobileAutoEnable = true;

const sfx = {
  init() {
  },
  loadFX(section) {
    if (initialized) return;
    initialized = true;
    const data = audiosprites[section];

    // iterate through sprites and add true to any that have _Loop in the title, so they loop when played
    Object.keys(data.sprite).forEach((key) => {
      if (key.match(/_Loop/)) {
        data.sprite[key].push(true);
      }
    });

    siteFXPool[1] = new Howl(data);
    siteFXPool[1].preload = true;
  },
  getDuration(id) {
    return new Promise((resolve) => {
      if (!sounds[id]) {
        this.loadSound(id)
          .then(() => this.getDuration(id))
          .then((duration) => {
            resolve(duration);
          });
      } else {
        resolve(sounds[id].duration());
      }
    });
  },
  loadSound(id, loop = true) {
    return new Promise((resolve) => {
      const urls = [
        `/assets/audio/${id}.ogg`,
        `/assets/audio/${id}.m4a`,
        `/assets/audio/${id}.mp3`,
        `/assets/audio/${id}.ac3`
      ];
      sounds[id] = new Howl({
        src: urls,
        preload: true,
        loop,
        onload: () => {
          console.log('ON LOAD?');
          resolve();
        }
      });
      sounds[id].id = id;
    });
  },
  getSound(id) {
    return sounds[id];
  },
  getHowl(section) {
    const fxInfo = audiosprites[section];
    howls[section] = howls[section] || new Howl(fxInfo);
    howls[section].preload = true;
    return howls[section];
  },
  playFx(id, section = 'siteFX', loop = false) {
    if (!initialized || !app.clicked) return;

    let volume = 1;

    const howl = this.getHowl(section);
    howl.volume(0.5);

    sounds[id] = section;
    return howl.play(id);
  },
  stopFx(id) {
    if (!initialized) return;

    howls[id].stop(id);
  },
  fadeInSound(id, volume = 1) {
    console.log(this);
    if (!sounds[id]) {
      if (currentSound) currentSound.stop();
      this.loadSound(id);
    }

    currentSound = sounds[id];
    sounds[id].fade(0, volume, 500);

    currentSound.play();
    return currentSound;
  },
  playSound(id, seek = 0) {
    if (!sounds[id]) {
      if (currentSound) currentSound.stop();
      this.loadSound(id);
    }
    currentSound = sounds[id];
    console.log(seek);
    currentSound.seek(seek);
    currentSound.play();
    return currentSound;
  },
  stopSound(id) {
    if (sounds[id]) {
      sounds[id].stop();
    } else if (currentSound) {
      currentSound.stop();
    }
  },
  mute(bool) {
    muted = bool;
    Howler.mute(muted || paused);
  },
  setVolume(id, volume, section = 'site') {
    let fxPool;
    let fxIndex;
    let fxInfo;

    switch (section) {
      case 'site':
        fxPool = siteFXPool;
        fxIndex = siteIndex % numObjs;
        fxInfo = siteFX;
        break;
      default:
        console.log('Wrong section, no FX!');
    }

    if (!fxPool[fxIndex]) {
      fxPool[fxIndex] = new Howl(fxInfo);
    }

    fxPool[fxIndex].volume(volume, id);
  },
  pause(bool) {
    paused = bool;
    this.mute(muted);
  }
};

window.sfx = sfx;

export default sfx;
