import {
  Scene,
  Mesh,
  OrthographicCamera,
  PlaneGeometry,
  MeshBasicMaterial,
  Vector2,
  Vector3,
  BufferGeometry,
  BufferAttribute,
  Triangle,
  ShaderMaterial,
  Color
} from 'three';

import {
  DoubleSide
} from 'three/src/constants';

import {
  gsap
} from 'gsap';

import {
  CustomEase
} from 'gsap/CustomEase';

import * as dat from 'dat.gui';

import pubsub from '../../utils/pubsub';
import { plainText as wipeVertShader } from './shaders/wipe-vertex.glsl';
// import wipeFragShader from './shaders/wipe-frag.glsl';
import { plainText as wipeFragShader } from './shaders/wipe-frag.glsl';
import app from '../../global';

gsap.registerPlugin(CustomEase);

let instance;

let t = 0;
let top_y = 0;
let bottom_y = 0;

const dat_gui_settings = {
  edge: 0,
  margin: 0.5,
  amplitude: 0.26
};

const colwidth = 75;
// const colwidth = 200;
const MAX_POINTS = 3000;
const max_winwidth = 1600;
const max_winheight = 1600;

class GLWipe {
  constructor() {
    // this.shown = true;
    this.initialized = false;

    this.scene = new Scene();
    this.events = pubsub.getInstance();

    this.colors = {
      white: new Color(app.colors.white),
      orange: new Color(app.colors.orange)
    };

    this.vertices = new Float32Array(MAX_POINTS * 3);
    this.midpoints = new Float32Array(MAX_POINTS * 3);
    this.rotation_strengths = new Float32Array(MAX_POINTS);
    this.randoms = new Float32Array(MAX_POINTS * 3);
    this.uvs = new Float32Array(MAX_POINTS * 2);
  }

  initDatGui() {
    const gui = new dat.GUI();
    const margin = this.buffermesh.material.uniforms.margin.value;
    gui.add(dat_gui_settings, 'margin', 0, 1, 0.0001).onChange(() => {
      this.rendering = true;
      this.buffermesh.material.uniforms.margin.value = dat_gui_settings.margin;
    });
    gui.add(dat_gui_settings, 'amplitude', 0, 1, 0.0001).onChange(() => {
      this.rendering = true;
      this.buffermesh.material.uniforms.amplitude.value = dat_gui_settings.amplitude;
    });
    gui.add(dat_gui_settings, 'edge', top_y, bottom_y, 0.0001).onChange(() => {
      this.rendering = true;
      this.buffermesh.material.uniforms.edge.value = dat_gui_settings.edge;// * (1 + dat_gui_settings.margin);
      console.log(top_y, bottom_y, this.buffermesh.material.uniforms.edge.value);
    });
  }

  getMaterial() {
    const mat = new ShaderMaterial({
      uniforms: {
        map: { value: this.canTex },
        plane: { value: new Vector3(1, 1, 0) },
        u_time: { value: 0 },
        u_resolution: { value: new Vector2(app.winHeight, app.winWidth) },
        strength: { value: 1 },
        offset: { value: 0 },
        edge: { value: -1 - dat_gui_settings.margin },
        margin: { value: dat_gui_settings.margin },
        amplitude: { value: dat_gui_settings.amplitude },
        invert: { value: 0 },
        color: { value: this.colors.orange }
      },
      depthTest: false,
      side: DoubleSide,
      transparent: true,
      vertexShader: wipeVertShader,
      fragmentShader: wipeFragShader
    });

    mat.extensions.derivatives = true;

    return mat;
  }

  updateGeo() {
    console.log('THE NUM QUADS UPDATE GEO');
    let w = Math.min(max_winwidth, app.winWidth);
    const h = Math.min(max_winheight, w * (app.winHeight / app.winWidth));
    w = h * (app.winWidth / app.winHeight);

    this.numcols = Math.ceil(w / colwidth);
    this.numrows = Math.ceil(h / colwidth);

    const faces = [];
    const midpoints = [];
    const uvs = [];
    const rotation_strengths = [];
    const randoms = [];

    const quads = [];
    for (let row = 0; row < this.numrows; row++) {
      for (let col = 0; col < this.numcols; col++) {
        const up = row === 0 ? false : quads[(row - 1) * (this.numcols) + col];
        const left = col === 0 ? false : quads[row * (this.numcols) + (col - 1)];

        const tl = {
          x: 0,
          y: 0
        };

        const tr = {
          x: (col === (this.numcols - 1)) ? col + 1 : Math.max(0, (col + 1) + (Math.random() * 0.5 - 0.25)),
          y: 0
        };

        const bl = {
          x: 0,
          y: (row === (this.numrows - 1)) ? row + 1 : Math.max(0, (row + 1) + (Math.random() * 0.5 - 0.25))
        };

        const br = {
          x: (col === (this.numcols - 1)) ? col + 1 : Math.max(0, (col + 1) + (Math.random() * 0.5 - 0.25)),
          y: (row === (this.numrows - 1)) ? row + 1 : Math.max(0, (row + 1) + (Math.random() * 0.5 - 0.25))
        };

        if (up) {
          tl.x = up.bl.x;
          tl.y = up.bl.y;

          tr.x = up.br.x;
          tr.y = up.br.y;
        }

        if (left) {
          tl.x = left.tr.x;
          tl.y = left.tr.y;

          bl.x = left.br.x;
          bl.y = left.br.y;
        }

        quads.push({
          tl,
          tr,
          bl,
          br
        });
      }
    }

    for (let i = 0; i < quads.length; i++) {
      const quad = quads[i];

      const flip = Math.round(Math.random());

      let x;
      let y;
      const z = 0;
      let u;
      let v;

      const {
        tl,
        tr,
        bl,
        br
      } = quad;

      const tri1_points = flip ? [tl, tr, bl] : [tl, tr, br];
      const tri = new Triangle();
      for (let j = 0; j < tri1_points.length; j++) {
        const pt = tri1_points[j];
        x = pt.x / this.numcols - 0.5;
        y = pt.y / this.numrows - 0.5;
        u = pt.x / this.numcols;
        v = pt.y / this.numrows;

        faces.push(x, y, z);
        uvs.push(u, v);

        switch (j) {
          case 0:
            tri.a.set(x, y, z);
            break;
          case 1:
            tri.b.set(x, y, z);
            break;
          case 2:
            tri.c.set(x, y, z);
            break;
          default:
            console.log('there should be only 3');
        }
      }

      const mp1 = tri.getMidpoint(new Vector3());

      midpoints.push(mp1.x, mp1.y, mp1.z, mp1.x, mp1.y, mp1.z, mp1.x, mp1.y, mp1.z);
      let rs = Math.random() * 2 - 1;
      rotation_strengths.push(rs, rs, rs);

      let r1 = Math.random();
      let r2 = Math.random();
      let r3 = Math.random();
      randoms.push(r1, r2, r3, r1, r2, r3, r1, r2, r3);

      const tri2_points = flip ? [tr, br, bl] : [tl, br, bl];
      for (let j = 0; j < tri2_points.length; j++) {
        const pt = tri2_points[j];
        x = pt.x / this.numcols - 0.5;
        y = pt.y / this.numrows - 0.5;
        u = pt.x / this.numcols;
        v = pt.y / this.numrows;

        faces.push(x, y, z);
        uvs.push(u, v);

        switch (j) {
          case 0:
            tri.a.set(x, y, z);
            break;
          case 1:
            tri.b.set(x, y, z);
            break;
          case 2:
            tri.c.set(x, y, z);
            break;
          default:
            console.log('there should be only 3');
        }
      }

      const mp2 = tri.getMidpoint(new Vector3());

      midpoints.push(mp2.x, mp2.y, mp2.z, mp2.x, mp2.y, mp2.z, mp2.x, mp2.y, mp2.z);
      rs = Math.random() * 2 - 1;
      rotation_strengths.push(rs, rs, rs);

      r1 = Math.random();
      r2 = Math.random();
      r3 = Math.random();
      randoms.push(r1, r2, r3, r1, r2, r3, r1, r2, r3);
    }

    this.vertices.set(faces, 0);
    this.midpoints.set(midpoints, 0);
    this.rotation_strengths.set(rotation_strengths, 0);
    this.randoms.set(randoms, 0);
    this.uvs.set(uvs, 0);

    console.log('top bottom', midpoints[1], midpoints[midpoints.length - 2]);
    top_y = -0.5;
    bottom_y = 0.5;

    this.geometry.setDrawRange(0, faces.length / 3);

    this.buffermesh.scale.set(app.winWidth, app.winHeight);

    this.geometry.attributes.position.needsUpdate = true;
    this.geometry.attributes.midpoint.needsUpdate = true;
    this.geometry.attributes.rotation_strength.needsUpdate = true;
    this.geometry.attributes.randoms.needsUpdate = true;
    this.geometry.attributes.uv.needsUpdate = true;
  }

  getGeo() {
    this.geometry = new BufferGeometry();
    this.geometry.setDrawRange(0, 0);

    this.geometry.setAttribute('position', new BufferAttribute(this.vertices, 3));
    this.geometry.setAttribute('midpoint', new BufferAttribute(this.midpoints, 3));
    this.geometry.setAttribute('rotation_strength', new BufferAttribute(this.rotation_strengths, 1));
    this.geometry.setAttribute('randoms', new BufferAttribute(this.randoms, 3));
    this.geometry.setAttribute('uv', new BufferAttribute(this.uvs, 2));

    return this.geometry;
  }

  init(callback) {
    this.initialized = true;

    const width = app.winWidth;
    const height = app.winHeight;
    this.camera = new OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 1000);

    if (callback) callback();

    // const testGeo = new PlaneGeometry(1, 1);
    // const testMat = new MeshBasicMaterial({ color: 0xFF0000 });
    // this.testMesh = new Mesh(testGeo, testMat);
    // this.testMesh.scale.set(100, 100);
    // this.testMesh.position.z = -50;
    // window.testmesh = this.testMesh;

    this.buffermesh = new Mesh(this.getGeo(), this.getMaterial());
    this.buffermesh.position.z = -50;
    this.scene.add(this.buffermesh);

    this.updateGeo();

    if (app.debug) this.initDatGui();
  }

  render(renderer, currTime, elapsed) {
    this.buffermesh.material.uniforms.u_time.value = t;
    renderer.render(this.scene, this.camera);
    t += 0.005;
  }

  hide(dir) {
    return new Promise((resolve) => {
      const duration = 1.875;
      const margin = this.buffermesh.material.uniforms.margin.value;
      this.shown = false;

      let inv = 1;
      let from = top_y;
      let to = bottom_y;

      switch (dir) {
        case 'down':
          inv = 0;
          to = top_y;
          from = bottom_y;
          break;
        case 'up':
          inv = 1;
          from = top_y;
          to = bottom_y;
          break;
        default:
      }

      this.buffermesh.material.uniforms.invert.value = inv;

      const onComplete = () => {
        this.rendering = false;
        this.transitioning = false;
        resolve();
      };

      const ease = 'power2.out';

      if (this.transitioning) {
        gsap.killTweensOf(this.buffermesh.material.uniforms.edge);
        gsap.to(this.buffermesh.material.uniforms.edge, { duration, value: to, ease, onComplete });
      } else {
        this.transitioning = true;
        gsap.fromTo(this.buffermesh.material.uniforms.edge, { value: from }, { duration, value: to, ease, onComplete });
      }
    });
  }

  show(dir) {
    return new Promise((resolve) => {
      if (this.shown) return resolve();

      this.shown = true;
      this.rendering = true;

      const duration = 1;

      let inv = 0;
      let from = top_y;
      let to = bottom_y;

      switch (dir) {
        case 'down':
          inv = 1;
          to = top_y;
          from = bottom_y;
          break;
        case 'up':
          inv = 0;
          from = top_y;
          to = bottom_y;
          break;
        default:
      }

      this.buffermesh.material.uniforms.invert.value = inv;

      const onComplete = () => {
        console.log('ON COMPLETE');
        this.transitioning = false;
        resolve();
      };

      this.custom_ease = this.custom_ease || CustomEase.create('custom', 'M0,0 C0.668,0.262 0.924,0.862 1,1');

      if (this.transitioning) {
        gsap.killTweensOf(this.buffermesh.material.uniforms.edge);
        gsap.to(this.buffermesh.material.uniforms.edge, { duration, value: to, ease: this.custom_ease, onComplete });
      } else {
        this.transitioning = true;
        gsap.fromTo(this.buffermesh.material.uniforms.edge, { value: from }, { duration, value: to, ease: this.custom_ease, onComplete });
      }
    });
  }

  resize() {
    this.buffermesh.material.uniforms.u_resolution.value.set(app.winWidth, app.winHeight);

    const camFactor = 2;
    this.camera.left = -app.winWidth / camFactor;
    this.camera.right = app.winWidth / camFactor;
    this.camera.top = app.winHeight / camFactor;
    this.camera.bottom = -app.winHeight / camFactor;
    this.camera.updateProjectionMatrix();

    this.updateGeo();
  }
}

export default {
  getInstance() {
    instance = instance || new GLWipe();
    return instance;
  },
};
