import {
  Scene,
  MeshBasicMaterial,
  Mesh,
  PlaneGeometry,
  PerspectiveCamera,
  Color
} from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import {
  DoubleSide
} from 'three/src/constants';

import {
  gsap
} from 'gsap/all';

import getNamedObjects from '../../utils/getNamedObjects';

import GLScene from './GLScene';
import GLCamera from './GLCamera';
import pubsub from '../../utils/pubsub';
import app from '../../global';

let instance;

class GLTester {
  constructor() {
    this.initialized = false;
    this.scene = new Scene();
    // this.scene.background = new Color(0xFFFFFF);
    this.events = pubsub.getInstance();
  }

  init(callback) {
    this.initialized = true;

    const mat = new MeshBasicMaterial({ color: 0xedb735, side: DoubleSide });

    // this.roomFloorMat = new ShaderMaterial( {
    //   uniforms: {
    //     map: {value:this.sceneTexture},
    //     ringMaskValue: {value:0},
    //     ringMaskMapMin: {value:0},
    //     CenterRingValue: {value: 0},
    //     CenterRingMapMin: {value: 0}
    //   },
    //   vertexShader: basicVertShader,
    //   fragmentShader: roomFloorFragShader
    // });

    const plane = new PlaneGeometry(1, 1);
    const mesh = new Mesh(plane, mat);
    mesh.position.set(5, 0, 0);

    this.scene.add(mesh);
    this.mesh = mesh;

    this.glCamera = GLCamera.getInstance();
    // this.glCamera.destPositionManual.set(0, 0, 100);
    // this.glCamera.focalPt.copy(mesh.position);

    window.cam = this.glCamera;
    window.mesh = this.mesh;

    if (callback) callback();
  }

  render(renderer, camera, currTime, deltaTime) {
    renderer.render(this.scene, camera);

    this.mesh.rotation.x += 0.01;
    this.mesh.rotation.y += 0.01;
    this.mesh.rotation.z += 0.01;
  }

  hide() {}

  show() {}

  resize() {}
}

export default {
  getInstance() {
    instance = instance || new GLTester();
    return instance;
  },
};