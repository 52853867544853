uniform float u_time;
uniform vec2 u_resolution;
uniform float offset;
uniform float strength;
uniform float edge;
uniform float margin;
uniform float amplitude;
uniform float invert;


varying vec2 vUv;
varying vec3 vColor;
varying vec3 vPosition;
varying vec3 pos;
varying float opacity;
varying vec3 normalInterp;
varying float zPos;

#ifdef USE_UV2
	attribute vec2 uv2;
	varying vec2 vUv2;
#endif

// attribute float weight;
attribute vec3 midpoint;
attribute float rotation_strength;
attribute vec3 randoms;
// varying float w;

// Simplex 2D noise
//
vec3 permute(vec3 x) { return mod(((x*34.0)+1.0)*x, 289.0); }

#define PI 3.1415926538

float snoise(vec2 v){
  const vec4 C = vec4(0.211324865405187, 0.366025403784439, -0.577350269189626, 0.024390243902439);
  vec2 i  = floor(v + dot(v, C.yy) );
  vec2 x0 = v -   i + dot(i, C.xx);
  vec2 i1;
  i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
  vec4 x12 = x0.xyxy + C.xxzz;
  x12.xy -= i1;
  i = mod(i, 289.0);
  vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
  + i.x + vec3(0.0, i1.x, 1.0 ));
  vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy),
    dot(x12.zw,x12.zw)), 0.0);
  m = m*m ;
  m = m*m ;
  vec3 x = 2.0 * fract(p * C.www) - 1.0;
  vec3 h = abs(x) - 0.5;
  vec3 ox = floor(x + 0.5);
  vec3 a0 = x - ox;
  m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
  vec3 g;
  g.x  = a0.x  * x0.x  + h.x  * x0.y;
  g.yz = a0.yz * x12.xz + h.yz * x12.yw;
  return 130.0 * dot(m, g);
}

mat4 rotationMatrix(vec3 axis, float angle) {
    axis = normalize(axis);
    float s = sin(angle);
    float c = cos(angle);
    float oc = 1.0 - c;
    
    return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                0.0,                                0.0,                                0.0,                                1.0);
}

vec3 rotate3d(vec3 v, vec3 axis, float angle) {
  float a = radians(angle);
  vec3 translated = v;

	mat4 m = rotationMatrix(axis, a);
	return (m * vec4(translated, 1.0)).xyz;
}

vec2 rotate(vec2 point, float degree, vec2 pivot) {
  vec2 translated = point - pivot;

  float a = -radians(degree);
	float s = sin(a);
	float c = cos(a);

	mat2 m = mat2(c, -s, s, c);

	return m * translated + pivot;
}

vec4 scale(vec3 point, vec3 s, vec3 pivot) {
  mat4 scalemat = mat4 (s.x, 0, 0, 0,
                        0, s.y, 0, 0,
                        0, 0, s.z, 0,
                        0, 0, 0, 1);

  return vec4((point - pivot).xyz, 1.) * scalemat + vec4(pivot.xyz, 1.);
}

void main() {
  // position.y += sin(u_time);

	vUv = uv;
	vColor = vec3( 1.0 );
	#ifdef USE_UV2
		vUv2 = uv2;
	#endif
	#ifdef USE_COLOR
		vColor.xyz *= color.xyz;
	#endif

  vec3 scaled_pos = position;
  float sin_edge = sin(scaled_pos.x * 2.) * amplitude + edge * (1. + margin * 2. + amplitude * 2.);
  float edge_strength = smoothstep(sin_edge - margin, sin_edge + margin, midpoint.y);
  float stren = -invert + edge_strength;

  float angle = (-invert + edge_strength) * 720.;

  vec3 p = (0.5 + position) * vec3(u_resolution, 1.);
  vec3 m = (0.5 + midpoint) * vec3(u_resolution, 1.);

  // vec3 scaled = rotate3d(scale(p - m, vec3(1. - randoms.y) * stren, vec3(0.)).xyz, vec3(1., 1., 0.), angle * (PI / 180.), vec3(0.)).xyz + m;
  // scaled /= vec3(u_resolution, 1.);
  // scaled -= vec3(0.5);

  vec3 matscaled = scale(p - m, vec3( (1. - abs(stren) * (randoms.y * 2.)) * (1. - abs(stren))), vec3(0.)).xyz;
  vec3 scaled = matscaled + m; // scale(p - m, vec3(1. - randoms.y) * stren, vec3(0.)).xyz + m;
  scaled = rotate3d(scaled - m, vec3(randoms.x - 0.5, randoms.y - 0.5, randoms.y - 0.5), angle * (randoms.x - 0.5)).xyz + m;
  // scaled = rotate3d(scale(p - m, vec3(randoms.y) * (1. - stren), vec3(0.)).xyz, vec3(1., 1., 0.), angle * (randoms.x - 0.5) * 2. * (PI / 180.), vec3(0.)).xyz + m;
  scaled /= vec3(u_resolution, 1000.);
  scaled -= vec3(0.5);
  // scaled.y += randoms.y * stren;

  // float offset_randomized = stren * (0.5 + randoms.x * 0.5) * 0.15; 
  // vec3 offset_pos = vec3(scaled.x, scaled.y + offset_randomized * stren, 0.);

  // // vec2 rot = rotate(vec2(scaled.y, scaled.z), angle, midpoint.yz);
  // vec3 rotz = rotate3d((scaled + vec3(0.5, 0.5, 0.)) * vec3(u_resolution.x, u_resolution.y, 0.), vec3(0., 0., 1.), angle * (randoms.x - 0.5) * 2. * (PI / 180.), midpoint + (midpoint + vec3(0.5, 0.5, 0.)) * vec3(u_resolution.x, u_resolution.y, 0.));
  // vec3 rot = rotate3d(rotz, vec3(0.1 + randoms.x * stren, randoms.y * stren, 0.), angle * (PI / 180.), midpoint + (midpoint + vec3(0.5, 0.5, 0.)) * vec3(u_resolution.x, u_resolution.y, 0.));
  // rot /= vec3(u_resolution.xy, 1000.);
  // rot -= vec3(0.5, 0.5, 0.);
  // // rot = rotate(rot.xy, angle, midpoint.xy);

  // // float offset_randomized = stren * (0.5 + randoms.x * 0.5) * 0.15; 
  // // vec3 offset_pos = vec3(scaled.x, rot.x + offset_randomized * stren, rot.y);
  // // offset_pos = vec3(scaled.x, scaled.y, scaled.z);

  float num2 = 0.15 * snoise(scaled.xy * vec2(0.75, 0.75) + (u_time + 30. * randoms.x));
  float num1 = 0.15 * snoise(scaled.xy * vec2(0.75, 0.75) + (u_time + 10. * randoms.z));
  // // num1 = 0.;
  // // num2 = 0.;
	// // vec3 transformed = vec3( position.x + num1, position.y + num2, position.z + num1 );

  vec3 transformed = vec3( scaled.x + num1 * stren, scaled.y + num2 * stren, scaled.z + num1 * stren );
  // vec3 transformed = vec3( offset_pos.x, offset_pos.y, offset_pos.z );
  // vec3 transformed = scaled;

  // vec3 transformed = vec3( position.x, position.y, position.z );
	// vec4 mvPosition = vec4( transformed, 1.0 );
	vec4 mvPosition = modelViewMatrix * vec4( transformed, 1.0 );

  // transform vertex into eyespace
  pos = transformed;// vec3(mvPosition) / mvPosition.w;
  opacity = 1. - abs(angle / 90.);

	gl_Position = projectionMatrix * mvPosition;

  // w = weight;
}
