import {
	Euler,
	EventDispatcher,
	Vector3,
	Object3D
} from 'three';

var FlyControls = function () {
	const camera = new Object3D();
	this.object = camera;
	const domElement = document.body;

	this.domElement = domElement;
	this.isLocked = false;

	// Set to constrain the pitch of the camera
	// Range is 0 to Math.PI radians
	this.minPolarAngle = 0; // radians
	this.maxPolarAngle = Math.PI; // radians

	//
	// internals
	//

	var scope = this;

	var changeEvent = { type: 'change' };
	var lockEvent = { type: 'lock' };
	var unlockEvent = { type: 'unlock' };

	var euler = new Euler( 0, 0, 0, 'YXZ' );

	var PI_2 = Math.PI / 2;

	var vec = new Vector3();

	this.moveState = { up: 0, down: 0, left: 0, right: 0, forward: 0, back: 0, pitchUp: 0, pitchDown: 0, yawLeft: 0, yawRight: 0, rollLeft: 0, rollRight: 0 };
	this.movementSpeed = 1;
	this.movementSpeedMultiplier = 1;

	this.mousemove = function( x,y,dX,dY ) {

		var movementX = dX;
		var movementY = dY;
		
		euler.setFromQuaternion( camera.quaternion );

		euler.y -= movementX * 0.002;
		euler.x -= movementY * 0.002;

		euler.x = Math.max( PI_2 - scope.maxPolarAngle, Math.min( PI_2 - scope.minPolarAngle, euler.x ) );

		camera.quaternion.setFromEuler( euler );

		scope.dispatchEvent( changeEvent );

	}

	this.mousedown = function(){
		// this.lock();
	}
	this.mouseup = function(){
		// this.unlock();
	}

	function onPointerlockChange() {

		if ( scope.domElement.ownerDocument.pointerLockElement === scope.domElement ) {

			scope.dispatchEvent( lockEvent );

			scope.isLocked = true;

		} else {

			scope.dispatchEvent( unlockEvent );

			scope.isLocked = false;

		}

	}

	function onPointerlockError() {

		console.error( 'THREE.PointerLockControls: Unable to use Pointer Lock API' );

	}

	this.connect = function () {

		window.addEventListener( 'keydown', _keydown, false );
		window.addEventListener( 'keyup', _keyup, false );
		window.addEventListener( 'wheel', _wheel, false );
		// scope.domElement.ownerDocument.addEventListener( 'mousemove', this.mousemove, false );
		scope.domElement.ownerDocument.addEventListener( 'pointerlockchange', onPointerlockChange, false );
		scope.domElement.ownerDocument.addEventListener( 'pointerlockerror', onPointerlockError, false );

	};

	this.disconnect = function () {

		window.removeEventListener( 'keydown', _keydown, false );
		window.removeEventListener( 'keyup', _keyup, false );
		window.removeEventListener( 'wheel', _wheel, false );
		// scope.domElement.ownerDocument.removeEventListener( 'mousemove', this.mousemove, false );
		scope.domElement.ownerDocument.removeEventListener( 'pointerlockchange', onPointerlockChange, false );
		scope.domElement.ownerDocument.removeEventListener( 'pointerlockerror', onPointerlockError, false );

	};

	this.dispose = function () {

		this.disconnect();

	};

	this.getObject = function () { // retaining this method for backward compatibility

		return camera;

	};

	this.getDirection = function () {

		var direction = new Vector3( 0, 0, - 1 );

		return function ( v ) {

			return v.copy( direction ).applyQuaternion( camera.quaternion );

		};

	}();

	this.moveUp = function ( distance ) {
		camera.position.addScaledVector( camera.up, distance );
	}

	this.moveForward = function ( distance ) {

		/*
		// move forward parallel to the xz-plane
		// assumes camera.up is y-up
		vec.setFromMatrixColumn( camera.matrix, 0 );
		vec.crossVectors( camera.up, vec );
		*/

		// instead this will fly to where you're facing, more similar to UE4
		vec.set(-camera.matrix.elements[8], -camera.matrix.elements[9], -camera.matrix.elements[10])

		camera.position.addScaledVector( vec, distance );
	};

	this.moveRight = function ( distance ) {

		vec.setFromMatrixColumn( camera.matrix, 0 );

		camera.position.addScaledVector( vec, distance );

	};

	const _wheel = function ( event ) {
		console.log(event.deltaY)
		this.movementSpeed = Math.max(1, this.movementSpeed-(event.deltaY/100));
	}.bind(this);

	const _keydown = function ( event ) {

		if ( event.altKey ) {

			return;

		}
		
		switch ( event.keyCode ) {

			// case 16: /* shift */ this.movementSpeedMultiplier = .1; break;

			case 87: /*W*/ this.moveState.forward = 1; break;
			case 83: /*S*/ this.moveState.back = 1; break;

			case 65: /*A*/ this.moveState.left = 1; break;
			case 68: /*D*/ this.moveState.right = 1; break;

			case 81: /*Q*/ this.moveState.down = 1; break;
			case 69: /*E*/ this.moveState.up = 1; break;

		}

		// this.updateMovementVector();
		// this.updateRotationVector();

	}.bind(this);

	const _keyup = function ( event ) {

		switch ( event.keyCode ) {

			// case 16: /* shift */ this.movementSpeedMultiplier = 1; break;

			case 87: /*W*/ this.moveState.forward = 0; break;
			case 83: /*S*/ this.moveState.back = 0; break;

			case 65: /*A*/ this.moveState.left = 0; break;
			case 68: /*D*/ this.moveState.right = 0; break;

			case 81: /*Q*/ this.moveState.down = 0; break;
			case 69: /*E*/ this.moveState.up = 0; break;

		}

	}.bind(this);

	this.update = function(currTime, deltaTime){
		camera.updateMatrix();
		this.moveForward((this.moveState.forward-this.moveState.back)*(deltaTime/1000)*this.movementSpeed);
		this.moveRight((this.moveState.right-this.moveState.left)*(deltaTime/1000)*this.movementSpeed);
		this.moveUp((this.moveState.up-this.moveState.down)*(deltaTime/1000)*this.movementSpeed);
	}
	
	this.lock = function () {

		this.domElement.requestPointerLock();

	};

	this.unlock = function () {

		scope.domElement.ownerDocument.exitPointerLock();

	};

	// this.connect();

};

FlyControls.prototype = Object.create( EventDispatcher.prototype );
FlyControls.prototype.constructor = FlyControls;

export { FlyControls as default };
