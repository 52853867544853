const player_proto = {
  play(time) {
    if (typeof time !== 'undefined') {
      this.video_el.currentTime = 0;
    }
    this.video_el.play();
    this.isPaused = false;
  },
  pause(resume) {
    if (!resume) this.isPaused = true;
    this.video_el.pause();
  },
  paused() {
    return this.isPaused;
  },
  setCurrentTime(time) {
    this.video_el.currentTime = time;
  },
  loadVideoById(src) {
    return new Promise((resolve) => {
      const onLoadedMetadata = () => {
        resolve();
        this.video_el.removeEventListener('loadedmetadata', onLoadedMetadata);
      };

      this.video_el.addEventListener('loadedmetadata', onLoadedMetadata);
      this.video_el.src = `${src}.mp4`;
    });
  },
  onStateChange(eventName) {
    return () => {
      switch (eventName) {
        case 'play':
          this.events.publish('play', { playing: true });
          break;
        case 'pause':
          this.events.publish('pause', { paused: true });
          break;
        case 'ended':
          this.events.publish('ended', { ended: true });
          break;
        case 'timeupdate':
          this.events.publish('timeupdate', { currentTime: this.getCurrentTime(), duration: this.getDuration() });
          break;
        case 'durationchange':
          this.events.publish('durationchange', { duration: this.getDuration() });
          break;
        case 'volumechange':
          this.events.publish('volumechange', { volume: this.getVolume() });
          break;
        default:
          console.log(eventName);
      }
    };
  },
  destroy() {
    // this.stop();
    // this.player.destroy();
  }
};

function resize() {}

function addEvents() {
  const events = [
    'play',
    'pause',
    'ended'
  ];

  events.forEach((eventName) => {
    this.video_el.addEventListener(eventName, this.onStateChange(eventName).bind(this));
  });
}

function load() {
  console.log('LOAD HTML PLAYER, ', this.options.videoSrc);

  return new Promise((resolve) => {
    this.isPaused = false;
    this.loadVideoById(this.options.videoSrc).then(() => {
      addEvents.call(this);
      resolve(this);
    });
  });
}

export default {
  proto: player_proto,
  resize,
  load
};
