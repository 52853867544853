varying vec2 vUv;
varying vec3 vPosition;
uniform float mountainPosition;
uniform sampler2D heightMap;

uniform sampler2D mapA;
uniform vec3 azimuthA;
uniform sampler2D mapB;
uniform vec3 azimuthB;

// varying vec3 vViewPosition;
varying vec3 vNormal;

uniform vec3 diffuse;
uniform vec3 emissive;
uniform vec3 specular;
uniform float shininess;
uniform float opacity;

uniform vec2 maskOrigin;
uniform vec2 maskVec;
uniform vec2 resolution;

float specularStrength = 1.;

#include <common>
#include <normalmap_pars_fragment>
#include <bsdfs>
#include <lights_pars_begin>
#include <lights_phong_pars_fragment>

float isMasked() {
	float feather = 0.001;
	vec2 screenUV = gl_FragCoord.xy/resolution.xy;
	vec2 pxVec = screenUV-maskOrigin;
	float dist = length(dot(pxVec, normalize(maskVec))*normalize(maskVec));
	float dir = (step(0., dot(normalize(pxVec), normalize(maskVec)))*2.)-1.;
	return smoothstep(-feather, feather, dist*dir);
}


void main() {
	
	#include <normal_fragment_begin>
	#include <normal_fragment_maps>

	vec4 diffuseColor = vec4( diffuse, opacity );
	ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
	vec3 totalEmissiveRadiance = emissive;

	#include <lights_phong_fragment>
	#include <lights_fragment_begin>
	#include <lights_fragment_maps>
	#include <lights_fragment_end>

	vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;

	vec3 viewDir = normalize( vViewPosition );
	vec3 x = normalize( vec3( viewDir.z, 0.0, - viewDir.x ) );
	vec3 y = cross( viewDir, x );
	vec2 uv = vec2( dot( x, normal ), dot( y, normal ) ) * 0.495 + 0.5; // 0.495 to remove artifacts caused by undersized matcap disks

	// gl_FragColor = vec4( mix(0.25, 1.5, outgoingLight.r)*texture2D( map, uv ).rgb, 1. );
	// gl_FragColor = vec4( outgoingLight, 1. );
	// gl_FragColor = vec4( texture2D( map, uv ).rgb, 1. );

	float maskVal = isMasked();
	gl_FragColor = vec4( mix(mix(texture2D( mapA, uv ).rgb,texture2D( mapB, uv ).rgb,maskVal)*mix(0.5, 1.0, smoothstep(0., 1., vPosition.y)), mix(azimuthA, azimuthB, maskVal), smoothstep(0., 30., length(vViewPosition*vec3(2.,1.,1.)))), 1. );
	
	// gl_FragColor.rgb = mix(gl_FragColor.rgb, 1.-gl_FragColor.rgb, isMasked());

}
