import Section from './Section';
import SectionLoader from '../utils/SectionLoader';
import Navigation from '../utils/Navigation';
import VideoManager from '../widgets/VideoPlayer/VideoManager';
import GLHeader from '../widgets/webgl/GLHeader';
import GLScene from '../widgets/webgl/GLScene';
import app from '../global';

const myName = 'Capabilities';
const sectionLoader = SectionLoader.getInstance();

let instance;

class Capabilities extends Section {
  constructor() {
    super(myName);
    this.background_color = app.colors.black;
    this.logo_color = app.colors.orange;
  }

  // eslint-disable-next-line class-methods-use-this
  prepareLoad() {
    const files = [];

    if (files.length > 0) {
      sectionLoader.addFiles(myName, files);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  wrapText(context, text, x, y, maxWidth, lineHeight) {
    const words = text.split(' ');
    let line = '';
    let width = 0;

    for (let n = 0; n < words.length; n++) {
      const testLine = `${line + words[n]} `;
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        context.strokeText(line, x, y);
        width = Math.max(context.measureText(line.trim()).width, width);
        line = `${words[n]} `;
        y += lineHeight;
      } else {
        line = testLine;
        width = Math.max(context.measureText(line.trim()).width, width);
      }
    }

    width = Math.max(context.measureText(line.trim()).width, width);
    context.strokeText(line, x, y);

    return width;
  }

  getHeader() {
    const h1 = this.elements.sectionWrapper.querySelector('h1');
    const canvas = document.createElement('canvas');
    if (h1) {
      const w = h1.offsetWidth;
      const h = h1.offsetHeight;
      canvas.width = w * 2;
      canvas.height = w * 2 * (h / w);

      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#1f1f1f';
      ctx.rect(0, 0, canvas.width, canvas.height);
      ctx.fill();
      // ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.lineWidth = 3;
      ctx.strokeStyle = '#edb735';

      const fontSize = window.getComputedStyle(h1).fontSize.split('px')[0];
      ctx.font = `900 ${fontSize * 2}px "museo-sans"`;

      const text = ctx.measureText('Hello world'); // TextMetrics object

      const maxWidth = w * 2;
      const lineHeight = 0.95 * (fontSize * 2);
      // console.log('lets see', getLines(ctx, h1.textContent.toUpperCase(), 4000));
      // const lines = getLines(ctx, h1.textContent.toUpperCase(), maxWidth);
      // let lineY = text.actualBoundingBoxAscent + (lineHeight - (text.actualBoundingBoxAscent + text.actualBoundingBoxDescent)) * 0.5;
      // for (let i = 0; i < lines.length; i++) {
      //   const line = lines[i];
      //   ctx.strokeText(line, 0, lineY);
      //   lineY += lineHeight;
      // }

      const newwidth = Math.min(w * 2, this.wrapText(ctx, h1.textContent.toUpperCase(), 0, text.actualBoundingBoxAscent + (lineHeight - (text.actualBoundingBoxAscent + text.actualBoundingBoxDescent)) * 0.5, maxWidth, lineHeight));

      // document.body.appendChild(canvas);
      canvas.style.position = 'absolute';

      const rect = h1.getBoundingClientRect();

      canvas.style.top = `${rect.top}px`;
      canvas.style.left = `${rect.left}px`;

      const newcanvas = document.createElement('canvas');
      const newctx = newcanvas.getContext('2d');
      newcanvas.width = newwidth;
      newcanvas.height = canvas.height;

      newctx.drawImage(canvas, 0, 0);//, newwidth, canvas.height);
      // newctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
      // newctx.fillRect(0, 0, newwidth, canvas.height);
      rect.width = rect.height * (newwidth / canvas.height);
      // rect.height = rect.width * (canvas.height / newwidth);

      // newcanvas.style.position = 'absolute';
      // newcanvas.style.top = '0px';
      // newcanvas.style.left = '0px';
      // newcanvas.style.width = '400px';
      // document.body.appendChild(newcanvas);

      return {
        element: h1,
        rect,
        canvas: newcanvas,
        scrollwrapper: this.elements.sectionWrapper
      };
    }

    return false;
  }

  /**
   * Overriding loadHTML function for Capabilities section
   * because there is no Capabilities landing page, so if the user
   * goes to /capabilities/, it will load /capabilities/experiences/
   */
  loadHTML(url) {
    this.added = false;
    /*
    * if <html> tag has the class 'first', this is the first page
    * the user visited, so we don't need to load the html
    */
    if (document.documentElement.classList.contains('first')) {
      if (url === '/capabilities/') {
        const el = document.querySelector('#capabilities');
        document.querySelector('main').removeChild(el);
      } else {
        this.getHTML = new Promise((resolve, reject) => {
          document.documentElement.classList.remove('first');
          const section_html = document.querySelector(`#${this.name.toLowerCase()}`);
          resolve(section_html);
        });

        return;
      }
    }

    this.getHTML = new Promise((resolve, reject) => {
      const parser = new DOMParser();
      if (url === '/capabilities/') url = '/capabilities/websites/';

      fetch(url).then((resp) => resp.text()).then((html) => {
        const section_html = parser.parseFromString(html, 'text/html').querySelector('section');
        resolve(section_html);
      });
    });
  }

  startup(callback) {
    this.videoManager = new VideoManager(this.elements.sectionWrapper);
    this.videoManager.init();

    this.glScene = GLScene.getInstance();

    this.headerScene = new GLHeader({
      name: 'capabilities',
      element: this.elements.sectionWrapper.querySelector('h1'),
      color: app.colors.orange,
      background_color: this.background_color
    });

    this.headerScene.events.subscribe('initialized', () => {
      console.log('SECTION SCENE INITIALIZED');
    });

    // this.glScene.initScene(this.headerScene, () => {
    //   if (callback) callback();
    // });
    this.glScene.initOverlay(this.headerScene, () => {
      if (callback) callback();
    });
  }
//
  getTitle() {
    return `${this.elements.sectionWrapper.querySelector('h1').textContent}`;
  }

  show(callback) {
    if (document.body.dataset.modal === 'what_we_do') {
      document.body.classList.add('modal');
    }
    // if (window.location.pathname.split('/capabilities/').filter((str) => str.length > 0).length > 0 && window.history.state && window.history.state.previousURL) {
    //   console.log('PREV URL!!', window.location.pathname, window.history.state.previousURL);
    //   document.body.classList.add('modal');
    // }

    const subscription = this.headerScene.events.subscribe('isIn', (e) => {
      console.log('SECTION SCENE IS IN!!!!');
      subscription.remove();
      super.show();
      if (callback) callback();
    });
//
    // this.glScene.show(this.headerScene);
    this.glScene.showOverlays([this.headerScene.name]);
  }

  hide(callbackm, dir) {
    super.hide(callbackm, dir);
    document.body.classList.remove('modal');
  }
}

export default {
  getInstance() {
    instance = instance || new Capabilities();
    return instance;
  },
};
