/* eslint-disable linebreak-style */
import player_html from './players/player_html';
import player_youtube from './players/player_youtube';
import player_vimeo from './players/player_vimeo';
import events from '../../utils/pubsub';

function getPlayer(type) {
  switch (type) {
    case 'html':
      return player_html;
    case 'youtube':
      return player_youtube;
    case 'vimeo':
      return player_vimeo;
    default:
      return false;
  }
}

export default {
  create: (vid, options) => {
    const type = options.type || 'youtube';
    const player_obj = getPlayer(type);

    const instance = Object.assign(Object.create(player_obj.proto), {
      video_el: vid,
      options,
      events: Object.create(events.getInstance())
    });

    return player_obj.load.call(instance);
  }
}