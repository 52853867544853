/* eslint-disable */
let scriptLoaded = false;

const player_proto = {
  play: function () {
    this.isPaused = false;
    this.player.playVideo();
  },
  pause: function (resume) {
    if (!resume) this.isPaused = true;
    this.player.pauseVideo();
  },
  paused: function () {
    return this.isPaused;
  },
  onStateChange: function (state) {
    console.log('state change', state, 'buff', YT.PlayerState.BUFFERING, 'cue', YT.PlayerState.CUED);

    switch (state.data) {
      case YT.PlayerState.PLAYING:
        this.events.publish('play', {playing: true});
        break;
      case YT.PlayerState.PAUSED:
        // use the settimeout to avoid calling 'pause' event on seek
        setTimeout(() => {
          if ( this.player.getPlayerState() === YT.PlayerState.PAUSED ) {
            this.events.publish('pause', {paused: true});
          }
        }, 1000);
        break;
      case YT.PlayerState.ENDED:
        console.log('STATE', state, state.target);
        this.events.publish('ended', {ended: true});

        // Reset video to poster
        this.destroy();
        load.call(this);
        break;
      case YT.PlayerState.BUFFERING:
        this.events.publish('buffering');
        break;
      case YT.PlayerState.CUED:
        this.events.publish('cued');
        break;
      default:
        // console.log('YOUTUBE STATE', state);
    }
  },
  loadVideoById: function (yt_id) {
    this.player.loadVideoById({ videoId: yt_id });
  },
  destroy: function () {
    this.player.stopVideo();
    this.player.destroy();
  }
};

function onError (e) {
  console.log('YOUTUBE ERROR', e);
}

function load () {
  return new Promise ((resolve, reject) => {
    // this.video_el.id = 'yt_player';
    const playerID = this.video_el.id;

    const initPlayer = () => {
      console.log('initplayer!');
      scriptLoaded = true;
      var youtubehost ='https://www.youtube.com';

      this.isPaused = false;
      this.player = new YT.Player(playerID, {
        videoId: this.options.videoSrc,
        host: youtubehost,
        playerVars: { 
          'autoplay': this.options.autoplay,
          'enablejsapi': this.options.enablejsapi,
          'color': this.options.color, // this is red or white -- turning it to white disables modestbranding:1
          'showinfo': this.options.showinfo, // this.showinfo,
          'controls': this.options.controls,
          'wmode': 'transparent', // fixes z-index problem in ie8
          'rel': 0, // this.options.rel, // this used to hide the related videos at the end, now it just makes it show related videos from your channel, instead of a bunch of random videos,
          'modestbranding': this.options.modestbranding
        },
        events: {
          'onReady': function () {
            resolve(this);
          }.bind(this),
          'onStateChange': this.onStateChange.bind(this),
          'onError': onError.bind(this)
        }
      });
    };

    // check if YT API already exists
    if (typeof YT === 'undefined') {
      // if no YT API, first check if script has already been appended
      const script = document.getElementById('ytScript');
      if (!script) {
        // if script has not been appended, append it and add
        // onYouTubeIframeAPIReady function and youtube function queue
        var ytScript = document.createElement('script');
        ytScript.id = 'ytScript';
        ytScript.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(ytScript);
        
        window.youtubeVideosToInit = [];
        window.onYouTubeIframeAPIReady = () => {
          for (let i = 0; i < window.youtubeVideosToInit.length; i++) {
            const initFn = window.youtubeVideosToInit[i];
            initFn();
          }
        }
      }

      // add initPlayer to queue
      window.youtubeVideosToInit.push(initPlayer);
    } else {
      initPlayer();
    }

  });
}

export default {
  proto: player_proto,
  load: load
};