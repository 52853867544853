import {
  WebGLRenderer,
  Scene,
  Color,
  WebGLRenderTarget,
  ShaderMaterial,
  Mesh,
  PlaneBufferGeometry,
  OrthographicCamera,
  UnsignedByteType,
  TextureLoader,
  LoadingManager,
  LinearFilter,
  RGBFormat,
  ClampToEdgeWrapping,
  WebGLMultisampleRenderTarget,
  RepeatWrapping,
  Vector2,
  MeshBasicMaterial,
  PlaneGeometry
} from 'three';

import {
  gsap
} from 'gsap/all';

import * as dat from 'dat.gui';

import app from '../../global';

import pubsub from '../../utils/pubsub';
import Interaction from '../../utils/Interaction';

import GLCamera from './GLCamera';
import GLMountain from './GLMountain';
import GLWipe from './GLWipe';
import { plainText as vertShader } from './shaders/post-vertex.glsl';
import { plainText as fragShader } from './shaders/post-fragment.glsl';
import mobileCheck from '../../utils/mobileCheck';
import GLTester from './GLTester';
import GLHeader from './GLHeader';

let instance;
let currentSection = false;
// let currentOverlay = false;
let overlays = {};
let currentOverlays = [];
let previousOverlays = [];

let SCREEN_WIDTH = window.innerWidth;
let SCREEN_HEIGHT = window.innerHeight;
let aspect = SCREEN_WIDTH / SCREEN_HEIGHT;

let initialized = false;
let prevTime;
const startTime = Date.now();
let useRetina = false;

let bufferSize = new Vector2();
const settings = {
  blurAmount: 0,
  minFOV: 45,
  maxFOV: 60
};

function GLScene() {}

window.theoverlays = overlays;
function init() {
  this.tests = [];

  const loadManager = new LoadingManager();

  this.overlays = overlays;

  initialized = true;

  this.renderer = new WebGLRenderer({
    antialias: true,
    canvas: document.getElementById('glCanvas')
  });

  if (mobileCheck.isMobile && mobileCheck.iPhone) useRetina = true;
  // console.log(this.renderer.capabilities.isWebGL2, mobileCheck.isMobile, mobileCheck.iPhone);

  if (useRetina) {
    this.renderer.setPixelRatio(window.devicePixelRatio);
  } else {
    this.renderer.setPixelRatio(1);
  }


  this.sceneCamera = GLCamera.getInstance();
  this.renderer.autoClear = false;
  this.envMap = null;
  window.glScene = this;

  // Create post processing render target

  const size = new Vector2();
  this.renderer.getDrawingBufferSize(size);

  /**
   * This is a little wonky, safari version 15.4 and lower on IOS is supposed to
   * support webgl2 but multisampling is buggy for this site, so I'm detecting
   * the safari version using the userAgent string (mobileCheck.oldIOSSafari),
   * then detecting whether touch is supported because the bug seems to only affect
   * iPads and iPhones, so we can still use multisampling in desktop Safari
   */
  let touch = false;
  try {
    document.createEvent('TouchEvent');
    touch = true;
  } catch (e) {
    touch = false;
  }
  const oldSafari = mobileCheck.oldIOSSafari && touch;

  this.postTarget = new WebGLRenderTarget(app.winWidth, app.winHeight);
  if (this.renderer.capabilities.isWebGL2 && !oldSafari) {
    this.postTarget.samples = 2;
  }

  // --------------- Start Post processing Scene ---------------
  this.postScene = new Scene();
  this.postScene.background = new Color(0x000000);
  this.postCamera = new OrthographicCamera(-1, 1, 1, -1, 1, 10);
  this.postCamera.position.z = 5;

  this.postMaterial = new ShaderMaterial({
    uniforms: {
      postTexture: { value: this.postTarget.texture },
      u_resolution: { value: new Vector2(1, 1) },
      blurOrigin: {value: new Vector2(300, 300)},
      blurAmt: {value: 0.},
      time: { value: 0 }
    },
    vertexShader: vertShader,
    fragmentShader: fragShader
  });

  this.postQuad = new Mesh(new PlaneBufferGeometry(2, 2, 1, 1), this.postMaterial);
  this.postScene.add(this.postQuad);

  // --------------- End Post processing Scene ---------------

  this.mountain = GLMountain.getInstance();
  this.mountain.events.subscribe('initialized', (e) => {
    console.log('MOUNTAINT SCENE INITIALIZED');
  });

  this.tester = GLTester.getInstance();
  this.tester.events.subscribe('initialized', (e) => {
    console.log('TEST SCENE INITIALIZED');
  });

  this.wipe = GLWipe.getInstance();
  this.wipe.init((e) => {
    console.log('WIPE INITIALIZED');
  });

  const currTime = Date.now()-startTime;
  const deltaTime = Math.min(100, currTime - prevTime);
  prevTime = currTime;

  // keep it's scope bound to this
  this.frameUpdate = frameUpdate.bind(this);

  this.events = pubsub.getInstance();
  this.mousePos = [0,0];

  let lastMouseWheelTime = 0;
  let lastMouseWheelDelta = 0;

  this.interactions = new Interaction({
    element: document.body,
    onDown: (x, y) => {
      // console.log('ON DOWN!');
      this.mouseDown = true;
      this.mousePos[0] = x;
      this.mousePos[1] = y;
      this.mountain.onDown(x, y);
    },
    onUp: (x, y, dragInfo) => {
      // console.log('ON UP!');
      this.mouseDown = false;
      this.mountain.onUp(x, y, dragInfo);
    },
    onDrag: (x, y, dX, dY) => {
      this.mousePos[0] = x;
      this.mousePos[1] = y;
      this.mountain.onDrag(x, y, dX, dY);
    },
    onMove: (x, y) => {
      this.mousePos[0] = x;
      this.mousePos[1] = y;
    },
    onKeyDown: (keyCode, arrows) => {
      // console.log('onKeyDown', keyCode, arrows);
      if(keyCode == '37') this.mountain.prevProject();
      if(keyCode == '39') this.mountain.nextProject();
    },
    onMouseWheel: (e) => {
      const now = Date.now();
      const dt = Math.min(16, now - lastMouseWheelTime);
      lastMouseWheelTime = now;
      const newDelta = Math.abs(e.deltaY);
      if (newDelta > lastMouseWheelDelta) this.acc += Math.sign(e.deltaY) * (dt / 16);
      lastMouseWheelDelta = newDelta;
    }
  });
  this.enableInteractions();

  this.resize();

  initTests.call(this);
}

function initOverlay(sceneObj, callback) {
  if (sceneObj.initialized) {
    if (callback) callback();
  } else {
    sceneObj.init(callback, this);
    overlays[sceneObj.name] = sceneObj;
  }
}

function initScene(id, callback) {
  let sceneObj = null;

  if (typeof id === 'string') {
    switch (id.toLowerCase()) {
      case 'mountain':
        sceneObj = this.mountain;
        break;
      case 'tester':
        sceneObj = this.tester;
        break;
      // case 'exterior':
      //   sceneObj = this.exterior;
      //   break;
      default:
        return null;
    }
  } else {
    sceneObj = id;
  }

  if (sceneObj.initialized) {
    if (callback) callback();
  } else {
    sceneObj.init(callback, this);
  }
}

function initTests() {
  const numtests = 4;
  const width = app.winWidth;
  const height = app.winHeight;

  for (let i = 0; i < numtests; i++) {
    const testMat = new MeshBasicMaterial({color: new Color(Math.random(), Math.random(), Math.random()), depthTest: false, depthWrite: false});
    const testgeo = new PlaneGeometry(1, 1);
    const testmesh = new Mesh(testgeo, testMat);
    testmesh.scale.set(100, 100);
    testmesh.position.z = -90;
    testmesh.position.x = Math.random() * 100 - 50;
    testmesh.position.y = Math.random() * 100 - 50;
    const scene = new Scene();
    scene.add(testmesh);

    const camera = new OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 2000);
    camera.position.z = 1000;

    this.tests.push({
      scene,
      camera
    });
  }
}

function frameUpdate(i) {
  const currTime = Date.now()-startTime;
  const deltaTime = Math.min(100, currTime - prevTime);
  // console.log(deltaTime);
  prevTime = currTime;

  this.sceneCamera.update(currTime, deltaTime);
  if (this[currentSection] && this[currentSection].update) this[currentSection].update(currTime, deltaTime);

  this.renderer.clear();
  // this.renderer.setRenderTarget(null);
  this.renderer.setRenderTarget(this.postTarget);
  this.renderer.clear();
  this.renderer.clearStencil();

  if (this[currentSection]) this[currentSection].render(this.renderer, this.sceneCamera.camera, currTime, deltaTime);

  const keys = Object.keys(overlays);
  // for (let j = keys.length - 1; j >= 0; j--) {
  for (let j = 0; j < keys.length; j++) {
    const overlay = overlays[keys[j]];
    if (!overlay.renderme) overlay.buffermesh.visible = false;
    if (overlay.renderme === true) overlay.update(this.renderer, this.sceneCamera.camera, currTime, deltaTime);
  }
  GLHeader.render(this.renderer, this.sceneCamera.camera, currTime, deltaTime);

  // for (let k = 0; k < this.tests.length; k++) {
  //   const test = this.tests[k];
  //   this.renderer.render(test.scene, test.camera);
  // }

  // console.log(overlays.what_we_do_circle);
  // if (overlays.what_we_do_circle) overlays.what_we_do_circle.render(this.renderer, this.sceneCamera.camera, currTime, deltaTime);
  // if (overlays.what_we_do_header) overlays.what_we_do_header.render(this.renderer, this.sceneCamera.camera, currTime, deltaTime);
  // for (let j = 0; j < currentOverlays.length; j++) {
  //   overlays[currentOverlays[j]].render(this.renderer, this.sceneCamera.camera, currTime, deltaTime);
  // }

  if (this.wipe.rendering) this.wipe.render(this.renderer, currTime, deltaTime);
  // this.renderer.clearDepth();
  // this.tester.render(this.renderer, this.sceneCamera.camera, currTime, deltaTime);
  this.renderer.setRenderTarget(null);

  this.postMaterial.uniforms.time.value = (currTime / 1000) % 1000;
  this.renderer.render(this.postScene, this.postCamera);

  window.requestAnimationFrame(this.frameUpdate);

  this.events.publish('update', { deltaTime });

  // for (let k = 0; k < this.tests.length; k++) {
  //   const test = this.tests[k];
  //   this.renderer.render(test.scene, test.camera);
  // }
  if (this.mountain) {
    this.mountain.scroll(-this.acc);
    this.acc = 0;
  }
}

function destroyOverlays(overlay_names) {
  for (let i = 0; i < overlay_names.length; i++) {
    const overlay_name = overlay_names[i];
    const overlay = overlays[overlay_name];
    overlay.destroy();
    delete overlays[overlay_name];
    const index = currentOverlays.indexOf(overlay_name);
    if (index >= 0) {
      currentOverlays.splice(index, 1);
    }
    console.log(overlay_names, currentOverlays);
  }
}

function hideOverlays(dir, instant) {
  return new Promise((resolve) => {
    const promises = [];
    for (let i = 0; i < currentOverlays.length; i++) {
      const overlay = overlays[currentOverlays[i]];
      overlay.renderme = true;

      promises.push(overlay.hide(dir, instant));
    }

    Promise.all(promises).then(() => {
      resolve();
    });
  });
}

function showOverlays(overlay_names) {
  return new Promise((resolve) => {
    const startUpdate = currentSection === false;

    if (overlay_names) {
      // this.hideOverlays();
      currentOverlays = overlay_names;
    }

    const promises = [];
    for (let i = 0; i < currentOverlays.length; i++) {
      const overlay = overlays[currentOverlays[i]];
      overlay.renderme = true;

      promises.push(overlay.show());
    }

    this.resize();
    window.requestAnimationFrame(() => {
      this.resize();
    });

    if (startUpdate && !this.renderStarted) {
      console.log('STARTING IT UP');
      this.renderStarted = true;
      this.frameUpdate();
    }

    Promise.all(promises).then(() => {
      resolve();
    });
  });
}

// function showOverlay(id) {
//   const startUpdate = currentOverlay === false;

//   if (typeof id === 'string') {
//     currentOverlay = id;//this[id];
//   } else {
//     currentOverlay = id.name;
//     this[currentOverlay] = id;
//   }

//   this.resize();

//   this[currentOverlay].show();

//   // if (startUpdate && !this.renderStarted) {
//   //   console.log('STARTING IT UP');
//   //   this.renderStarted = true;
//   //   this.frameUpdate();
//   // }
// }

// GLScene is always running and rendering
// this is called to tell the scene what it should be rendering
function show(id, parameters) {
  const startUpdate = currentSection === false;

  if (typeof id === 'string') {
    currentSection = id;//this[id];
  } else {
    currentSection = id.name;
    this[currentSection] = id;
  }

  this.resize();

  this[currentSection].show();

  if (startUpdate && !this.renderStarted) {
    console.log('STARTING IT UP', currentSection);
    this.renderStarted = true;
    this.frameUpdate();
  }
}

function resize() {
  if (!initialized) return;

  SCREEN_WIDTH = app.winWidth; // window.innerWidth;
  SCREEN_HEIGHT = app.winHeight; // window.innerHeight;
  aspect = SCREEN_WIDTH / SCREEN_HEIGHT;

  console.log(SCREEN_HEIGHT, document.documentElement.scrollTop);

  this.postMaterial.uniforms.u_resolution.value.set(SCREEN_WIDTH, SCREEN_HEIGHT);

  this.renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
  this.sceneCamera.resize(SCREEN_WIDTH, SCREEN_HEIGHT);
  this.wipe.resize(SCREEN_WIDTH, SCREEN_HEIGHT);

  if (useRetina) {
    this.renderer.getDrawingBufferSize(bufferSize);
    this.postTarget.setSize(bufferSize.x, bufferSize.y);
    if (this[currentSection] && this[currentSection].resize){
      this[currentSection].resize(bufferSize.x, bufferSize.y);
    }
  } else {
    this.postTarget.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
    if (this[currentSection] && this[currentSection].resize){
      this[currentSection].resize(SCREEN_WIDTH, SCREEN_HEIGHT);
    }
  }

  for (let i = 0; i < currentOverlays.length; i++) {
    const overlay = currentOverlays[i];
    overlays[overlay].resize(SCREEN_WIDTH, SCREEN_HEIGHT);
  }
  // const keys = Object.keys(overlays);
  // for (let j = 0; j < keys.length; j++) {
  //   const overlay = keys[j];
  //   overlays[overlay].resize(SCREEN_WIDTH, SCREEN_HEIGHT);
  //   // overlays[keys[j]].render(this.renderer, this.sceneCamera.camera, currTime, deltaTime);
  // }

  for (let i = 0; i < this.tests.length; i++) {
    const test = this.tests[i];
    const { camera } = test;
    const camFactor = 2;
    camera.left = -app.winWidth / camFactor;
    camera.right = app.winWidth / camFactor;
    camera.top = app.winHeight / camFactor;
    camera.bottom = -app.winHeight / camFactor;
    camera.updateProjectionMatrix();
  }

  // if (this[currentOverlay] && this[currentOverlay].resize)this[currentOverlay].resize(SCREEN_WIDTH, SCREEN_HEIGHT);
}

function enableInteractions() {
  if (!this.interactions.hasListeners) this.interactions.addListeners();
}

function disableInteractions() {
  console.log('DISABLE', this.interactions.hasListeners);
  if (this.interactions.hasListeners) this.interactions.removeListeners();
}

GLScene.prototype.init = init;
GLScene.prototype.initScene = initScene;
GLScene.prototype.initOverlay = initOverlay;
GLScene.prototype.show = show;
GLScene.prototype.showOverlays = showOverlays;
GLScene.prototype.hideOverlays = hideOverlays;
GLScene.prototype.destroyOverlays = destroyOverlays;
GLScene.prototype.resize = resize;

GLScene.prototype.enableInteractions = enableInteractions;
GLScene.prototype.disableInteractions = disableInteractions;

export default {
  getInstance() {
    instance = instance || new GLScene();
    return instance;
  },
};
