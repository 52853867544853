varying vec2 vUv;
varying vec3 vColor;
varying vec3 vPosition;

varying vec3 vViewPosition;
varying vec3 vNormal;

void main() {
	
	vec3 objectNormal = vec3( normal );
	vec3 transformedNormal = objectNormal;
	transformedNormal = normalMatrix * transformedNormal;
	vNormal = normalize( transformedNormal );

	vPosition = vec3( modelMatrix * vec4( position, 1.0 ));
	
	#include <begin_vertex>
	#include <project_vertex>

	vViewPosition = - mvPosition.xyz;

	// vPosition = vec3( position );
	vUv = uv;
	vColor = vec3( 1.0 );

	// vec3 transformed = vec3( position );
	// vec4 mvPosition = vec4( transformed, 1.0 );
	// mvPosition = modelViewMatrix * mvPosition;
	// gl_Position = projectionMatrix * mvPosition;
}
